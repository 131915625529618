/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(~swiper/css/swiper.css);
@import url(~animate.css/animate.min.css);
@import url(~lightgallery.js/dist/css/lightgallery.css);
@import url(../css/icons.min.css);
@import url("https://fonts.googleapis.com/css?family=Cormorant+Garamond:300,400,500,600,700|Poppins:300,400,500,600,700,800,900|Abril+Fatface&display=swap");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*=============================================
=            Helper CSS            =
=============================================*/
.row-0 {
  margin-left: 0px;
  margin-right: 0px; }
  .row-0 > [class*="col"] {
    padding-left: 0px;
    padding-right: 0px; }

.row-1 {
  margin-left: -1px;
  margin-right: -1px; }
  .row-1 > [class*="col"] {
    padding-left: 1px;
    padding-right: 1px; }

.row-2 {
  margin-left: -2px;
  margin-right: -2px; }
  .row-2 > [class*="col"] {
    padding-left: 2px;
    padding-right: 2px; }

.row-3 {
  margin-left: -3px;
  margin-right: -3px; }
  .row-3 > [class*="col"] {
    padding-left: 3px;
    padding-right: 3px; }

.row-4 {
  margin-left: -4px;
  margin-right: -4px; }
  .row-4 > [class*="col"] {
    padding-left: 4px;
    padding-right: 4px; }

.row-5 {
  margin-left: -5px;
  margin-right: -5px; }
  .row-5 > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px; }

.row-6 {
  margin-left: -6px;
  margin-right: -6px; }
  .row-6 > [class*="col"] {
    padding-left: 6px;
    padding-right: 6px; }

.row-7 {
  margin-left: -7px;
  margin-right: -7px; }
  .row-7 > [class*="col"] {
    padding-left: 7px;
    padding-right: 7px; }

.row-8 {
  margin-left: -8px;
  margin-right: -8px; }
  .row-8 > [class*="col"] {
    padding-left: 8px;
    padding-right: 8px; }

.row-9 {
  margin-left: -9px;
  margin-right: -9px; }
  .row-9 > [class*="col"] {
    padding-left: 9px;
    padding-right: 9px; }

.row-10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row-10 > [class*="col"] {
    padding-left: 10px;
    padding-right: 10px; }

.row-11 {
  margin-left: -11px;
  margin-right: -11px; }
  .row-11 > [class*="col"] {
    padding-left: 11px;
    padding-right: 11px; }

.row-12 {
  margin-left: -12px;
  margin-right: -12px; }
  .row-12 > [class*="col"] {
    padding-left: 12px;
    padding-right: 12px; }

.row-13 {
  margin-left: -13px;
  margin-right: -13px; }
  .row-13 > [class*="col"] {
    padding-left: 13px;
    padding-right: 13px; }

.row-14 {
  margin-left: -14px;
  margin-right: -14px; }
  .row-14 > [class*="col"] {
    padding-left: 14px;
    padding-right: 14px; }

.row-15 {
  margin-left: -15px;
  margin-right: -15px; }
  .row-15 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px; }

.row-16 {
  margin-left: -16px;
  margin-right: -16px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-16 > [class*="col"] {
    padding-left: 16px;
    padding-right: 16px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-17 {
  margin-left: -17px;
  margin-right: -17px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-17 > [class*="col"] {
    padding-left: 17px;
    padding-right: 17px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-18 {
  margin-left: -18px;
  margin-right: -18px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-18 > [class*="col"] {
    padding-left: 18px;
    padding-right: 18px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-19 {
  margin-left: -19px;
  margin-right: -19px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-19 > [class*="col"] {
    padding-left: 19px;
    padding-right: 19px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-20 {
  margin-left: -20px;
  margin-right: -20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-20 > [class*="col"] {
    padding-left: 20px;
    padding-right: 20px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-21 {
  margin-left: -21px;
  margin-right: -21px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-21 > [class*="col"] {
    padding-left: 21px;
    padding-right: 21px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-22 {
  margin-left: -22px;
  margin-right: -22px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-22 > [class*="col"] {
    padding-left: 22px;
    padding-right: 22px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-23 {
  margin-left: -23px;
  margin-right: -23px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-23 > [class*="col"] {
    padding-left: 23px;
    padding-right: 23px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-24 {
  margin-left: -24px;
  margin-right: -24px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-24 > [class*="col"] {
    padding-left: 24px;
    padding-right: 24px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-25 {
  margin-left: -25px;
  margin-right: -25px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-25 > [class*="col"] {
    padding-left: 25px;
    padding-right: 25px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-26 {
  margin-left: -26px;
  margin-right: -26px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-26 > [class*="col"] {
    padding-left: 26px;
    padding-right: 26px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-27 {
  margin-left: -27px;
  margin-right: -27px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-27 > [class*="col"] {
    padding-left: 27px;
    padding-right: 27px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-28 {
  margin-left: -28px;
  margin-right: -28px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-28 > [class*="col"] {
    padding-left: 28px;
    padding-right: 28px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-29 {
  margin-left: -29px;
  margin-right: -29px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-29 > [class*="col"] {
    padding-left: 29px;
    padding-right: 29px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-30 {
  margin-left: -30px;
  margin-right: -30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-30 > [class*="col"] {
    padding-left: 30px;
    padding-right: 30px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-31 {
  margin-left: -31px;
  margin-right: -31px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-31 > [class*="col"] {
    padding-left: 31px;
    padding-right: 31px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-32 {
  margin-left: -32px;
  margin-right: -32px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-32 > [class*="col"] {
    padding-left: 32px;
    padding-right: 32px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-33 {
  margin-left: -33px;
  margin-right: -33px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-33 > [class*="col"] {
    padding-left: 33px;
    padding-right: 33px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-34 {
  margin-left: -34px;
  margin-right: -34px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-34 > [class*="col"] {
    padding-left: 34px;
    padding-right: 34px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-35 {
  margin-left: -35px;
  margin-right: -35px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-35 > [class*="col"] {
    padding-left: 35px;
    padding-right: 35px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-36 {
  margin-left: -36px;
  margin-right: -36px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-36 > [class*="col"] {
    padding-left: 36px;
    padding-right: 36px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-37 {
  margin-left: -37px;
  margin-right: -37px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-37 > [class*="col"] {
    padding-left: 37px;
    padding-right: 37px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-38 {
  margin-left: -38px;
  margin-right: -38px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-38 > [class*="col"] {
    padding-left: 38px;
    padding-right: 38px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-39 {
  margin-left: -39px;
  margin-right: -39px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-39 > [class*="col"] {
    padding-left: 39px;
    padding-right: 39px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-40 {
  margin-left: -40px;
  margin-right: -40px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-40 > [class*="col"] {
    padding-left: 40px;
    padding-right: 40px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-41 {
  margin-left: -41px;
  margin-right: -41px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-41 > [class*="col"] {
    padding-left: 41px;
    padding-right: 41px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-42 {
  margin-left: -42px;
  margin-right: -42px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-42 > [class*="col"] {
    padding-left: 42px;
    padding-right: 42px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-43 {
  margin-left: -43px;
  margin-right: -43px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-43 > [class*="col"] {
    padding-left: 43px;
    padding-right: 43px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-44 {
  margin-left: -44px;
  margin-right: -44px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-44 > [class*="col"] {
    padding-left: 44px;
    padding-right: 44px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-45 {
  margin-left: -45px;
  margin-right: -45px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-45 > [class*="col"] {
    padding-left: 45px;
    padding-right: 45px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-46 {
  margin-left: -46px;
  margin-right: -46px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-46 > [class*="col"] {
    padding-left: 46px;
    padding-right: 46px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-47 {
  margin-left: -47px;
  margin-right: -47px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-47 > [class*="col"] {
    padding-left: 47px;
    padding-right: 47px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-48 {
  margin-left: -48px;
  margin-right: -48px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-48 > [class*="col"] {
    padding-left: 48px;
    padding-right: 48px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-49 {
  margin-left: -49px;
  margin-right: -49px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-49 > [class*="col"] {
    padding-left: 49px;
    padding-right: 49px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-50 {
  margin-left: -50px;
  margin-right: -50px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-50 > [class*="col"] {
    padding-left: 50px;
    padding-right: 50px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-51 {
  margin-left: -51px;
  margin-right: -51px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-51 > [class*="col"] {
    padding-left: 51px;
    padding-right: 51px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-52 {
  margin-left: -52px;
  margin-right: -52px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-52 > [class*="col"] {
    padding-left: 52px;
    padding-right: 52px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-53 {
  margin-left: -53px;
  margin-right: -53px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-53 > [class*="col"] {
    padding-left: 53px;
    padding-right: 53px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-54 {
  margin-left: -54px;
  margin-right: -54px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-54 > [class*="col"] {
    padding-left: 54px;
    padding-right: 54px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-55 {
  margin-left: -55px;
  margin-right: -55px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-55 > [class*="col"] {
    padding-left: 55px;
    padding-right: 55px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-56 {
  margin-left: -56px;
  margin-right: -56px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-56 > [class*="col"] {
    padding-left: 56px;
    padding-right: 56px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-57 {
  margin-left: -57px;
  margin-right: -57px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-57 > [class*="col"] {
    padding-left: 57px;
    padding-right: 57px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-58 {
  margin-left: -58px;
  margin-right: -58px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-58 > [class*="col"] {
    padding-left: 58px;
    padding-right: 58px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-59 {
  margin-left: -59px;
  margin-right: -59px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-59 > [class*="col"] {
    padding-left: 59px;
    padding-right: 59px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-60 {
  margin-left: -60px;
  margin-right: -60px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-60 > [class*="col"] {
    padding-left: 60px;
    padding-right: 60px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-61 {
  margin-left: -61px;
  margin-right: -61px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-61 > [class*="col"] {
    padding-left: 61px;
    padding-right: 61px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-62 {
  margin-left: -62px;
  margin-right: -62px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-62 > [class*="col"] {
    padding-left: 62px;
    padding-right: 62px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-63 {
  margin-left: -63px;
  margin-right: -63px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-63 > [class*="col"] {
    padding-left: 63px;
    padding-right: 63px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-64 {
  margin-left: -64px;
  margin-right: -64px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-64 > [class*="col"] {
    padding-left: 64px;
    padding-right: 64px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-65 {
  margin-left: -65px;
  margin-right: -65px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-65 > [class*="col"] {
    padding-left: 65px;
    padding-right: 65px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-66 {
  margin-left: -66px;
  margin-right: -66px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-66 > [class*="col"] {
    padding-left: 66px;
    padding-right: 66px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-67 {
  margin-left: -67px;
  margin-right: -67px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-67 > [class*="col"] {
    padding-left: 67px;
    padding-right: 67px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-68 {
  margin-left: -68px;
  margin-right: -68px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-68 > [class*="col"] {
    padding-left: 68px;
    padding-right: 68px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-69 {
  margin-left: -69px;
  margin-right: -69px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-69 > [class*="col"] {
    padding-left: 69px;
    padding-right: 69px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-70 {
  margin-left: -70px;
  margin-right: -70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-70 > [class*="col"] {
    padding-left: 70px;
    padding-right: 70px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-71 {
  margin-left: -71px;
  margin-right: -71px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-71 > [class*="col"] {
    padding-left: 71px;
    padding-right: 71px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-72 {
  margin-left: -72px;
  margin-right: -72px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-72 > [class*="col"] {
    padding-left: 72px;
    padding-right: 72px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-73 {
  margin-left: -73px;
  margin-right: -73px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-73 > [class*="col"] {
    padding-left: 73px;
    padding-right: 73px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-74 {
  margin-left: -74px;
  margin-right: -74px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-74 > [class*="col"] {
    padding-left: 74px;
    padding-right: 74px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-75 {
  margin-left: -75px;
  margin-right: -75px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-75 > [class*="col"] {
    padding-left: 75px;
    padding-right: 75px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-76 {
  margin-left: -76px;
  margin-right: -76px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-76 > [class*="col"] {
    padding-left: 76px;
    padding-right: 76px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-77 {
  margin-left: -77px;
  margin-right: -77px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-77 > [class*="col"] {
    padding-left: 77px;
    padding-right: 77px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-78 {
  margin-left: -78px;
  margin-right: -78px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-78 > [class*="col"] {
    padding-left: 78px;
    padding-right: 78px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-79 {
  margin-left: -79px;
  margin-right: -79px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-79 > [class*="col"] {
    padding-left: 79px;
    padding-right: 79px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-80 {
  margin-left: -80px;
  margin-right: -80px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-80 > [class*="col"] {
    padding-left: 80px;
    padding-right: 80px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

/* no gutters */
.no-gutters {
  margin-left: 0;
  margin-right: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
    margin: 0; }

/*=====  End of Helper CSS  ======*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

body {
  background-color: #ffffff;
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #000; }

h1, h2, h3, h4, h5, h6 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-top: 0; }

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #333;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif; }

h1 {
  font-size: 36px;
  line-height: 42px; }

h2 {
  font-size: 30px;
  line-height: 36px; }

h3 {
  font-size: 24px;
  line-height: 30px; }

h4 {
  font-size: 18px;
  line-height: 24px; }

h5 {
  font-size: 14px;
  line-height: 18px; }

h6 {
  font-size: 12px;
  line-height: 14px; }

p:last-child {
  margin-bottom: 0; }

a,
button {
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000; }

a,
button,
img,
input,
span {
  transition: all 0.3s ease 0s; }

*:focus {
  outline: none !important; }

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none; }

a:hover {
  text-decoration: none; }

button,
input[type="submit"] {
  cursor: pointer; }

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0; }

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden; }

.hidden {
  display: none; }

.clear {
  clear: both; }

.section {
  float: left;
  width: 100%; }

.capitalize {
  text-transform: capitalize; }

.uppercase {
  text-transform: uppercase; }

.bg-img {
  background-position: center center;
  background-size: cover; }

.border-bottom-1 {
  border-bottom: 1px solid #d5d5d5; }

a:hover {
  color: #a749ff; }

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0; }

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0; }

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0; }

.mt-10 {
  margin-top: 10px; }

.mt-12 {
  margin-top: 12px; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-25 {
  margin-top: 25px; }

.mt-30 {
  margin-top: 30px; }

.mt-35 {
  margin-top: 35px; }

.mt-40 {
  margin-top: 40px; }

.mt-45 {
  margin-top: 45px; }

.mt-50 {
  margin-top: 50px; }

.mt-55 {
  margin-top: 55px; }

.mt-60 {
  margin-top: 60px; }

.mt-70 {
  margin-top: 70px; }

.mt-80 {
  margin-top: 80px; }
  @media only screen and (max-width: 767px) {
    .mt-80 {
      margin-top: 60px; } }

.mt-90 {
  margin-top: 90px; }

.mt-100 {
  margin-top: 100px; }

.mt-110 {
  margin-top: 110px; }

.mt-120 {
  margin-top: 120px; }

.mt-130 {
  margin-top: 130px; }

.mt-140 {
  margin-top: 140px; }

.mt-150 {
  margin-top: 150px; }

.mt-195 {
  margin-top: 195px; }

.mt-200 {
  margin-top: 200px; }

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0; }

.mr-10 {
  margin-right: 10px !important;
  margin: inherit; }

.mr-12 {
  margin-right: 12px; }

.mr-15 {
  margin-right: 15px; }

.mr-20 {
  margin-right: 20px; }

.mr-30 {
  margin-right: 30px; }

.mr-35 {
  margin-right: 35px; }

.mr-40 {
  margin-right: 40px; }

.mr-50 {
  margin-right: 50px; }

.mr-60 {
  margin-right: 60px; }

.mr-70 {
  margin-right: 70px; }

.mr-80 {
  margin-right: 80px; }

.mr-90 {
  margin-right: 90px; }

.mr-100 {
  margin-right: 100px; }

.mr-110 {
  margin-right: 110px; }

.mr-120 {
  margin-right: 120px; }

.mr-130 {
  margin-right: 130px; }

.mr-140 {
  margin-right: 140px; }

.mr-150 {
  margin-right: 150px; }

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0; }

.mb-10 {
  margin-bottom: 10px; }

.mb-12 {
  margin-bottom: 12px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-25 {
  margin-bottom: 25px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-35 {
  margin-bottom: 35px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-45 {
  margin-bottom: 45px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-55 {
  margin-bottom: 55px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-65 {
  margin-bottom: 65px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-75 {
  margin-bottom: 75px; }

.mb-80 {
  margin-bottom: 80px; }
  @media only screen and (max-width: 767px) {
    .mb-80 {
      margin-bottom: 60px; } }

.mb-90 {
  margin-bottom: 90px; }

.mb-95 {
  margin-bottom: 95px; }

.mb-100 {
  margin-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .mb-100 {
      margin-bottom: 50px; } }

.mb-110 {
  margin-bottom: 110px; }

.mb-120 {
  margin-bottom: 120px; }

.mb-130 {
  margin-bottom: 130px; }

.mb-140 {
  margin-bottom: 140px; }

.mb-150 {
  margin-bottom: 150px; }

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0; }

.ml-10 {
  margin-left: 10px; }

.ml-12 {
  margin-left: 12px; }

.ml-15 {
  margin-left: 15px; }

.ml-20 {
  margin-left: 20px; }

.ml-30 {
  margin-left: 30px; }

.ml-40 {
  margin-left: 40px; }

.ml-50 {
  margin-left: 50px; }

.ml-55 {
  margin-left: 55px; }

.ml-60 {
  margin-left: 60px; }

.ml-70 {
  margin-left: 70px; }

.ml-75 {
  margin-left: 75px; }

.ml-80 {
  margin-left: 80px; }

.ml-90 {
  margin-left: 90px; }

.ml-95 {
  margin-left: 95px; }

.ml-100 {
  margin-left: 100px; }

.ml-110 {
  margin-left: 110px; }

.ml-120 {
  margin-left: 120px; }

.ml-130 {
  margin-left: 130px; }

.ml-140 {
  margin-left: 140px; }

.ml-145 {
  margin-left: 145px; }

.ml-150 {
  margin-left: 150px; }

.ml-ntv5 {
  margin-left: -5px; }

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0; }

.pt-5 {
  padding-top: 5px; }

.pt-10 {
  padding-top: 10px; }

.pt-15 {
  padding-top: 15px; }

.pt-20 {
  padding-top: 20px; }

.pt-25 {
  padding-top: 25px; }

.pt-30 {
  padding-top: 30px; }

.pt-35 {
  padding-top: 35px; }

.pt-40 {
  padding-top: 40px; }

.pt-45 {
  padding-top: 45px; }

.pt-50 {
  padding-top: 50px; }

.pt-55 {
  padding-top: 55px; }

.pt-60 {
  padding-top: 60px; }

.pt-70 {
  padding-top: 70px; }
  @media only screen and (max-width: 767px) {
    .pt-70 {
      padding-top: 60px; } }

.pt-75 {
  padding-top: 75px; }

.pt-80 {
  padding-top: 80px; }
  @media only screen and (max-width: 767px) {
    .pt-80 {
      padding-top: 60px; } }

.pt-95 {
  padding-top: 95px; }
  @media only screen and (max-width: 767px) {
    .pt-95 {
      padding-top: 55px; } }

.pt-90 {
  padding-top: 90px; }
  @media only screen and (max-width: 767px) {
    .pt-90 {
      padding-top: 50px; } }

.pt-100 {
  padding-top: 100px; }
  @media only screen and (max-width: 767px) {
    .pt-100 {
      padding-top: 60px; } }

.pt-105 {
  padding-top: 105px; }

.pt-110 {
  padding-top: 110px; }
  @media only screen and (max-width: 767px) {
    .pt-110 {
      padding-top: 40px; } }

.pt-115 {
  padding-top: 115px; }
  @media only screen and (max-width: 767px) {
    .pt-115 {
      padding-top: 60px; } }

.pt-120 {
  padding-top: 120px; }
  @media only screen and (max-width: 767px) {
    .pt-120 {
      padding-top: 50px; } }

.pt-125 {
  padding-top: 125px; }
  @media only screen and (max-width: 767px) {
    .pt-125 {
      padding-top: 55px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pt-125 {
      padding-top: 75px; } }

.pt-128 {
  padding-top: 128px; }

.pt-130 {
  padding-top: 130px; }
  @media only screen and (max-width: 767px) {
    .pt-130 {
      padding-top: 60px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pt-130 {
      padding-top: 80px; } }

.pt-135 {
  padding-top: 135px; }

.pt-140 {
  padding-top: 140px; }

.pt-150 {
  padding-top: 150px; }

.pt-155 {
  padding-top: 155px; }

.pt-170 {
  padding-top: 170px; }

.pt-180 {
  padding-top: 180px; }
  @media only screen and (max-width: 767px) {
    .pt-180 {
      padding-top: 60px; } }

.pt-205 {
  padding-top: 205px; }

.pt-230 {
  padding-top: 230px; }

.pt-270 {
  padding-top: 270px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pt-270 {
      padding-top: 170px; } }
  @media only screen and (max-width: 767px) {
    .pt-270 {
      padding-top: 100px; } }

.pt-240 {
  padding-top: 240px; }

.pt-365 {
  padding-top: 365px; }

.pt-370 {
  padding-top: 370px; }

.pt-380 {
  padding-top: 380px; }

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0; }

.pr-10 {
  padding-right: 10px; }

.pr-15 {
  padding-right: 15px; }

.pr-20 {
  padding-right: 20px; }

.pr-30 {
  padding-right: 30px; }

.pr-40 {
  padding-right: 40px; }

.pr-50 {
  padding-right: 50px; }

.pr-60 {
  padding-right: 60px; }

.pr-70 {
  padding-right: 70px; }

.pr-80 {
  padding-right: 80px; }

.pr-90 {
  padding-right: 90px; }

.pr-100 {
  padding-right: 100px; }

.pr-110 {
  padding-right: 110px; }

.pr-120 {
  padding-right: 120px; }

.pr-130 {
  padding-right: 130px; }

.pr-140 {
  padding-right: 140px; }

.pr-155 {
  padding-right: 155px; }

.pr-270 {
  padding-right: 270px; }

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0; }

.pb-10 {
  padding-bottom: 10px; }

.pb-15 {
  padding-bottom: 15px; }

.pb-5 {
  padding-bottom: 5px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-25 {
  padding-bottom: 25px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-35 {
  padding-bottom: 35px; }

.pb-40 {
  padding-bottom: 40px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-55 {
  padding-bottom: 55px; }
  @media only screen and (max-width: 767px) {
    .pb-55 {
      padding-bottom: 15px; } }

.pb-60 {
  padding-bottom: 60px; }
  @media only screen and (max-width: 767px) {
    .pb-60 {
      padding-bottom: 20px; } }

.pb-65 {
  padding-bottom: 65px; }

.pb-70 {
  padding-bottom: 70px; }
  @media only screen and (max-width: 767px) {
    .pb-70 {
      padding-bottom: 30px; } }

.pb-80 {
  padding-bottom: 80px; }
  @media only screen and (max-width: 767px) {
    .pb-80 {
      padding-bottom: 40px; } }

.pb-85 {
  padding-bottom: 85px; }

.pb-90 {
  padding-bottom: 90px; }
  @media only screen and (max-width: 767px) {
    .pb-90 {
      padding-bottom: 50px; } }

.pb-95 {
  padding-bottom: 95px; }
  @media only screen and (max-width: 767px) {
    .pb-95 {
      padding-bottom: 55px; } }

.pb-100 {
  padding-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .pb-100 {
      padding-bottom: 30px; } }

.pb-105 {
  padding-bottom: 105px; }

.pb-110 {
  padding-bottom: 110px; }

.pb-115 {
  padding-bottom: 115px; }
  @media only screen and (max-width: 767px) {
    .pb-115 {
      padding-bottom: 60px; } }

.pb-120 {
  padding-bottom: 120px; }
  @media only screen and (max-width: 767px) {
    .pb-120 {
      padding-bottom: 50px; } }

.pb-125 {
  padding-bottom: 125px; }

.pb-130 {
  padding-bottom: 130px; }
  @media only screen and (max-width: 767px) {
    .pb-130 {
      padding-bottom: 60px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pb-130 {
      padding-bottom: 80px; } }

.pb-140 {
  padding-bottom: 140px; }
  @media only screen and (max-width: 767px) {
    .pb-140 {
      padding-bottom: 60px; } }

.pb-145 {
  padding-bottom: 145px; }

.pb-150 {
  padding-bottom: 150px; }

.pb-170 {
  padding-bottom: 170px; }

.pb-180 {
  padding-bottom: 180px; }

.pb-280 {
  padding-bottom: 280px; }

.pb-210 {
  padding-bottom: 210px; }

.pb-285 {
  padding-bottom: 285px; }

.pb-270 {
  padding-bottom: 270px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pb-270 {
      padding-bottom: 170px; } }
  @media only screen and (max-width: 767px) {
    .pb-270 {
      padding-bottom: 100px; } }

.pb-290 {
  padding-bottom: 290px; }

/*************************
        Padding left
*************************/
.pl-0 {
  padding-left: 0; }

.pl-10 {
  padding-left: 10px; }

.pl-15 {
  padding-left: 15px; }

.pl-20 {
  padding-left: 20px; }

.pl-30 {
  padding-left: 30px; }

.pl-40 {
  padding-left: 40px; }

.pl-50 {
  padding-left: 50px; }

.pl-60 {
  padding-left: 60px; }

.pl-70 {
  padding-left: 70px; }

.pl-80 {
  padding-left: 80px; }

.pl-90 {
  padding-left: 90px; }

.pl-100 {
  padding-left: 100px; }

.pl-110 {
  padding-left: 110px; }

.pl-120 {
  padding-left: 120px; }

.pl-130 {
  padding-left: 130px; }

.pl-140 {
  padding-left: 140px; }

.pl-150 {
  padding-left: 150px; }

.pl-155 {
  padding-left: 155px; }

.pl-190 {
  padding-left: 190px; }

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0; }

.ptb-10 {
  padding: 10px 0; }

.ptb-20 {
  padding: 20px 0; }

.ptb-30 {
  padding: 30px 0; }

.ptb-35 {
  padding: 35px 0; }

.ptb-32 {
  padding: 32px 0; }

.ptb-40 {
  padding: 40px 0; }

.ptb-50 {
  padding: 50px 0; }

.ptb-60 {
  padding: 60px 0; }

.ptb-70 {
  padding: 70px 0; }

.ptb-80 {
  padding: 80px 0; }

.ptb-90 {
  padding: 90px 0; }

.ptb-100 {
  padding: 100px 0; }

.ptb-110 {
  padding: 110px 0; }

.ptb-120 {
  padding: 120px 0; }

.ptb-130 {
  padding: 130px 0; }

.ptb-140 {
  padding: 140px 0; }

.ptb-150 {
  padding: 150px 0; }

.ptb-160 {
  padding: 160px 0; }

.ptb-170 {
  padding: 170px 0; }

.ptb-177 {
  padding: 177px 0; }

.ptb-180 {
  padding: 180px 0; }

.ptb-190 {
  padding: 190px 0; }

.ptb-200 {
  padding: 200px 0; }

.ptb-210 {
  padding: 210px 0; }

.ptb-220 {
  padding: 220px 0; }

.ptb-290 {
  padding: 290px 0; }

.ptb-310 {
  padding: 310px 0; }

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0; }

.mtb-10 {
  margin: 10px 0; }

.mtb-15 {
  margin: 15px 0; }

.mtb-20 {
  margin: 20px 0; }

.mtb-30 {
  margin: 30px 0; }

.mtb-40 {
  margin: 40px 0; }

.mtb-50 {
  margin: 50px 0; }

.mtb-60 {
  margin: 60px 0; }

.mtb-70 {
  margin: 70px 0; }

.mtb-80 {
  margin: 80px 0; }

.mtb-90 {
  margin: 90px 0; }

.mtb-100 {
  margin: 100px 0; }

.mtb-110 {
  margin: 110px 0; }

.mtb-120 {
  margin: 120px 0; }

.mtb-130 {
  margin: 130px 0; }

.mtb-140 {
  margin: 140px 0; }

.mtb-150 {
  margin: 150px 0; }

.mtb-290 {
  margin: 290px 0; }

.mb--10 {
  margin-bottom: -10px; }

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #333;
  width: 100%; }

select {
  width: 100%;
  background: transparent;
  border: 0 solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #333; }

option {
  background: #fff;
  border: 0 solid #626262;
  padding-left: 10px;
  font-size: 14px; }

input:focus {
  background: transparent; }

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px; }

textarea:focus {
  background: transparent;
  border: 2px solid #4fc1f0;
  outline: none; }

.input-group.divcenter.input-group .form-control {
  padding-left: 0; }

.custom-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .custom-row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .custom-row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .custom-row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media only screen and (max-width: 767px) {
    .custom-row {
      margin-right: -15px;
      margin-left: -15px; } }

.custom-row-2 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .custom-row-2 div[class^="col-"] {
    padding-left: 5px;
    padding-right: 5px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .custom-row-2 div[class^="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .custom-row-2 div[class^="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .custom-row-2 {
      margin-right: -5px;
      margin-left: -5px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .custom-row-2 {
      margin-right: -5px;
      margin-left: -5px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .custom-row-2 {
      margin-right: -15px;
      margin-left: -15px; } }
  @media only screen and (max-width: 767px) {
    .custom-row-2 {
      margin-right: -15px;
      margin-left: -15px; } }

.custom-row-3 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -13.6px;
  margin-left: -13.6px; }
  .custom-row-3 div[class^="col-"] {
    padding-left: 13.6px;
    padding-right: 13.6px; }
    @media only screen and (max-width: 767px) {
      .custom-row-3 div[class^="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .custom-row-3 {
      margin-right: -13.6px;
      margin-left: -13.6px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .custom-row-3 {
      margin-right: -13.6px;
      margin-left: -13.6px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .custom-row-3 {
      margin-right: -13.6px;
      margin-left: -13.6px; } }
  @media only screen and (max-width: 767px) {
    .custom-row-3 {
      margin-right: -15px;
      margin-left: -15px; } }

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .custom-col-5 {
      padding-right: 15px;
      padding-left: 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .custom-col-5 {
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 33.333%;
      max-width: 33.333%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .custom-col-5 {
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 50%;
      max-width: 50%; } }
  @media only screen and (max-width: 767px) {
    .custom-col-5 {
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 100%;
      max-width: 100%; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .custom-col-5 {
      flex: 0 0 50%;
      max-width: 50%; } }

.mobile-menu-area {
  display: none; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mobile-menu-area {
      display: block; } }
  @media only screen and (max-width: 767px) {
    .mobile-menu-area {
      display: block; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .mobile-menu-area {
      display: block; } }
  .mobile-menu-area.white-mobile-menu a.meanmenu-reveal {
    color: #fff;
    border: 1px solid #fff; }
    .mobile-menu-area.white-mobile-menu a.meanmenu-reveal span {
      background-color: #fff; }

.stick .mobile-menu-area.white-mobile-menu a.meanmenu-reveal {
  color: #333;
  border: 1px solid #333; }
  .stick .mobile-menu-area.white-mobile-menu a.meanmenu-reveal span {
    background-color: #333; }

.mean-container a.meanmenu-reveal {
  top: -30px; }

#mobile-menu-active {
  display: none; }

.mobile-menu .mean-nav ul.menu-overflow {
  height: 215px;
  margin-top: 10;
  overflow-y: auto; }

.mobile-menu.mean-container .mean-nav ul {
  margin: 14px 0 0; }

/*-- 
    Scroll Up 
-----------------------------------------*/
.scroll-top {
  position: fixed;
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  background-color: #37b540;
  color: #fff;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
  visibility: hidden;
  opacity: 0; }
  .scroll-top.show {
    visibility: visible;
    opacity: 1; }
  @media only screen and (max-width: 767px) {
    .scroll-top {
      width: 40px;
      height: 40px; } }
  .scroll-top i {
    display: block;
    line-height: 50px;
    font-size: 22px; }
    @media only screen and (max-width: 767px) {
      .scroll-top i {
        line-height: 40px;
        font-size: 16px; } }
  .scroll-top:hover i {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite; }

.height-100 {
  height: 100vh; }

/*--
    - Background Color
------------------------------------------*/
.bg-purple {
  background-color: #f0e0ff; }

.bg-purple-2 {
  background-color: #cea1f8; }

.bg-gray {
  background-color: #f6f6f8; }

.bg-gray-2 {
  background-color: #f6f6f6; }

.bg-gray-3 {
  background-color: #f7f7f7; }

.bg-gray-4 {
  background-color: #f1f1f1; }

.bg-gray-5 {
  background-color: #f9f9f9; }

.bg-gray-6 {
  background-color: #e9e6e9; }

.bg-black {
  background-color: #000000; }

.bg-glaucous {
  background-color: #daedff; }

.bg-aqua {
  background-color: #dbfffb; }

.bg-white {
  background-color: #fff; }

.default-overlay {
  position: relative; }
  .default-overlay:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    right: 0;
    bottom: 0; }

.btn-hover a,
.btn-hover button {
  position: relative;
  transition: all 0.5s ease-in-out 0s; }
  .btn-hover a:hover,
  .btn-hover button:hover {
    border: 1px solid darkturquoise;
    color: #fff; }
  .btn-hover a::before, .btn-hover a::after,
  .btn-hover button::before,
  .btn-hover button::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: -1; }
  .btn-hover a::after,
  .btn-hover button::after {
    width: 0;
    left: auto;
    right: 0;
    background: darkturquoise; }
  .btn-hover a:hover::after,
  .btn-hover button:hover::after {
    width: 100%;
    left: 0;
    right: auto; }

a.default-btn {
  font-size: 16px;
  color: #010101;
  display: inline-block;
  border: 1px solid #333;
  text-transform: uppercase;
  line-height: 1;
  padding: 19px 50px 21px; }

/* slider img bounce */
@-webkit-keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0); }
  50% {
    opacity: 1;
    transform: translateY(20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0); }
  50% {
    opacity: 1;
    transform: translateY(20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes snow {
  0% {
    background-position: 0 0, 0 0, 0 0; }
  100% {
    background-position: 500px 1000px, 400px 400px, 300px 300px; } }

.body-effect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0); }

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px; }
  .padding-10-row-col .row div[class^="col-"] {
    padding-left: 5px;
    padding-right: 5px; }

.padding-20-row-col .row {
  margin-right: -10px;
  margin-left: -10px; }
  .padding-20-row-col .row div[class^="col-"] {
    padding-left: 10px;
    padding-right: 10px; }

[class*="ToastContainer"] {
  overflow: hidden !important; }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

.item-empty-area__icon i {
  font-size: 140px;
  line-height: 1; }
  @media only screen and (max-width: 767px) {
    .item-empty-area__icon i {
      font-size: 100px; } }

.item-empty-area__text {
  font-size: 20px; }
  .item-empty-area__text a {
    margin-top: 30px;
    font-weight: 500;
    display: inline-block;
    background-color: #363f4d;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 30px;
    transition: 0.3s; }
    .item-empty-area__text a:hover {
      background-color: darkturquoise;
      color: #ffffff; }

.fullpage-slider-wrapper .Navigation .Navigation-Anchor {
  background-color: #333 !important; }

.bg-white {
  background-color: #ffffff; }

.rounden-btn {
  border-radius: 50px;
  overflow: hidden; }
  .rounden-btn:before, .rounden-btn:after {
    border-radius: 50px; }

/*--- flone preloader ---*/
.flone-preloader-wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 999999; }

.flone-preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px; }

.flone-preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #7460ef;
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite; }

.flone-preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s; }

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5; }
  100% {
    transform: scale(1, 1);
    opacity: 0; } }

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0; } }

/*--- end of flone preloader ---*/
/*----- 2. Header style ----*/
.header-area {
  position: relative; }

.header-padding-1 .container-fluid, .header-padding-1 .container-sm, .header-padding-1 .container-md, .header-padding-1 .container-lg, .header-padding-1 .container-xl {
  padding: 0 80px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .header-padding-1 .container-fluid, .header-padding-1 .container-sm, .header-padding-1 .container-md, .header-padding-1 .container-lg, .header-padding-1 .container-xl {
      padding: 0 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-padding-1 .container-fluid, .header-padding-1 .container-sm, .header-padding-1 .container-md, .header-padding-1 .container-lg, .header-padding-1 .container-xl {
      padding: 0 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-padding-1 .container-fluid, .header-padding-1 .container-sm, .header-padding-1 .container-md, .header-padding-1 .container-lg, .header-padding-1 .container-xl {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .header-padding-1 .container-fluid, .header-padding-1 .container-sm, .header-padding-1 .container-md, .header-padding-1 .container-lg, .header-padding-1 .container-xl {
      padding: 0 15px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-res-padding {
    padding: 10px 0 1px; } }

@media only screen and (max-width: 767px) {
  .header-res-padding {
    padding: 10px 0 1px; } }

.logo {
  text-align: center;
  margin-bottom: 10px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .logo {
      margin-top: 0px; } }
  @media only screen and (max-width: 767px) {
    .logo {
      margin-top: 0px; } }
  .logo.logo-hm5 {
    margin-top: 41px; }
    .logo.logo-hm5 a.sticky-block {
      display: none; }
      @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
        .logo.logo-hm5 a.sticky-block {
          display: block; } }
    .logo.logo-hm5 a.sticky-none {
      display: block; }
      @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
        .logo.logo-hm5 a.sticky-none {
          display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .logo.logo-hm5 {
        margin-top: 0px;
        text-align: left !important; } }
    @media only screen and (max-width: 767px) {
      .logo.logo-hm5 {
        margin-top: 0px;
        text-align: left !important; } }

.stick .logo-hm5 a.sticky-block {
  display: block; }

.stick .logo-hm5 a.sticky-none {
  display: none; }

.main-menu nav ul {
  display: block;
  text-align: center;
  margin-top: -25px;
  margin: -25px; }
  .main-menu nav ul li {
    display: inline-block;
    padding: 0 15px;
    position: relative; }
    .main-menu nav ul li ul.submenu {
      background: #fff none repeat scroll 0 0;
      box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
      display: block;
      left: 0;
      padding: 20px 0 22px;
      position: absolute;
      top: 100%;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      transition: all 0.5s ease 0s;
      visibility: hidden;
      width: 210px;
      z-index: 999; }
      .main-menu nav ul li ul.submenu li {
        display: block;
        padding: 0 20px;
        margin-left: 0; }
        .main-menu nav ul li ul.submenu li a {
          color: #333333;
          display: block;
          font-size: 13px;
          font-weight: 400;
          line-height: 32px;
          text-align: left;
          text-transform: capitalize;
          padding-top: 0; }
          .main-menu nav ul li ul.submenu li a:hover {
            color: black; }
          .main-menu nav ul li ul.submenu li a:visited {
            color: black; }
          .main-menu nav ul li ul.submenu li a::before {
            background: darkturquoise none repeat scroll 0 0;
            border-radius: 50%;
            content: "";
            height: 7px;
            left: 15px;
            opacity: 0;
            position: absolute;
            top: 13px;
            transition: all 0.3s ease-in-out 0s;
            width: 7px;
            z-index: 999; }
        .main-menu nav ul li ul.submenu li:hover a::before {
          opacity: 1; }
        .main-menu nav ul li ul.submenu li:hover a {
          padding-left: 12px; }
    .main-menu nav ul li ul.mega-menu {
      background-color: #fff;
      display: block;
      left: 0;
      padding: 40px;
      position: absolute;
      text-align: left;
      top: 100%;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      transition: all 0.5s ease 0s;
      visibility: hidden;
      width: 700px;
      z-index: 999;
      box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3); }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .main-menu nav ul li ul.mega-menu {
          left: -75px; } }
      .main-menu nav ul li ul.mega-menu > li {
        display: inline-block;
        float: left;
        margin-left: 0;
        padding: 0;
        width: 33.33%; }
        .main-menu nav ul li ul.mega-menu > li ul li.mega-menu-title {
          float: inherit;
          width: 100%; }
          .main-menu nav ul li ul.mega-menu > li ul li.mega-menu-title a {
            color: #242424;
            margin: 0 0 10px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500; }
            .main-menu nav ul li ul.mega-menu > li ul li.mega-menu-title a::before {
              display: none; }
          .main-menu nav ul li ul.mega-menu > li ul li.mega-menu-title:hover a {
            padding-left: 0px; }
        .main-menu nav ul li ul.mega-menu > li ul li {
          display: block;
          float: inherit;
          padding: 0px; }
          .main-menu nav ul li ul.mega-menu > li ul li a {
            color: #333333;
            display: block;
            font-size: 13px;
            font-weight: 400;
            line-height: 32px;
            text-align: left;
            text-transform: capitalize;
            padding-top: 0px; }
            .main-menu nav ul li ul.mega-menu > li ul li a:hover {
              color: darkturquoise; }
            .main-menu nav ul li ul.mega-menu > li ul li a::before {
              background: darkturquoise none repeat scroll 0 0;
              border-radius: 50%;
              content: "";
              height: 7px;
              left: 0px;
              opacity: 0;
              position: absolute;
              top: 13px;
              transition: all 0.3s ease-in-out 0s;
              width: 7px;
              z-index: 999; }
          .main-menu nav ul li ul.mega-menu > li ul li.mega-menu-img:hover a {
            padding-left: 0px; }
          .main-menu nav ul li ul.mega-menu > li ul li.mega-menu-img a::before {
            display: none; }
          .main-menu nav ul li ul.mega-menu > li ul li:hover a::before {
            opacity: 1; }
          .main-menu nav ul li ul.mega-menu > li ul li:hover a {
            padding-left: 12px; }
      .main-menu nav ul li ul.mega-menu.mega-menu-padding {
        padding: 40px 40px 36px 40px; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .main-menu nav ul li ul.mega-menu.mega-menu-padding {
            left: 0px; } }
    .main-menu nav ul li:hover > ul.submenu {
      transform: rotateX(0deg);
      visibility: visible; }
    .main-menu nav ul li:hover > ul.mega-menu {
      transform: rotateX(0deg);
      visibility: visible; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .main-menu nav ul li {
        padding: 0 7px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .main-menu nav ul li {
        padding: 0 10px; } }
    .main-menu nav ul li > a {
      font-size: 15px;
      color: #010101;
      letter-spacing: .8px;
      display: inline-block;
      line-height: 94px;
      font-family: 'bodoni'; }
      .main-menu nav ul li > a i {
        font-size: 15px;
        margin-left: 1px;
        position: relative;
        top: 0px; }
    .main-menu nav ul li:hover a {
      color: darkturquoise; }
    .main-menu nav ul li:visited a {
      color: blue; }

.main-menu.menu-white nav ul {
  text-align: left; }
  .main-menu.menu-white nav ul li {
    padding: 0 30px 0 0; }
    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      .main-menu.menu-white nav ul li {
        padding: 0 22px 0 0; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .main-menu.menu-white nav ul li {
        padding: 0 14px 0 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .main-menu.menu-white nav ul li {
        padding: 0 14px 0 0; } }
    .main-menu.menu-white nav ul li > a {
      color: #ffffff;
      line-height: 112px; }

.stick .main-menu.menu-white nav ul li a {
  color: #555252; }
  .stick .main-menu.menu-white nav ul li a:hover {
    color: darkturquoise; }

.stick .main-menu nav ul li a {
  line-height: 75px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .stick .logo,
  .stick .header-right-wrap {
    margin-top: 0px; } }

@media only screen and (max-width: 767px) {
  .stick .logo,
  .stick .header-right-wrap {
    margin-top: 0px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .stick .logo,
  .stick .header-right-wrap {
    margin-top: 0px; } }

.stick .header-right-wrap .same-style.cart-wrap .shopping-cart-content,
.stick .header-right-wrap .same-style .account-dropdown {
  top: 160%;
  margin-right: 20px; }
  @media only screen and (max-width: 767px) {
    .stick .header-right-wrap .same-style.cart-wrap .shopping-cart-content,
    .stick .header-right-wrap .same-style .account-dropdown {
      top: 100%;
      margin-top: 14px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .stick .header-right-wrap .same-style.cart-wrap .shopping-cart-content,
    .stick .header-right-wrap .same-style .account-dropdown {
      top: 100%;
      margin-top: 4px; } }

.btn-ingresar, a {
  font-size: 15px; }

.btn-ingresar, :hover {
  color: darkturquoise; }

.header-right-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  margin-right: 20px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-right-wrap {
      margin-top: 0; } }
  @media only screen and (max-width: 767px) {
    .header-right-wrap {
      margin-top: 2px; } }
  .header-right-wrap .same-style {
    margin-left: 14px;
    position: relative; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .header-right-wrap .same-style {
        margin-left: 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-right-wrap .same-style {
        margin-left: 15px; } }
    @media only screen and (max-width: 767px) {
      .header-right-wrap .same-style {
        margin-left: 20px; } }
    .header-right-wrap .same-style > a, .header-right-wrap .same-style button {
      color: #000;
      font-size: 23px; }
    .header-right-wrap .same-style > button {
      background: none;
      border: none; }
    .header-right-wrap .same-style:first-child {
      margin-left: 0px; }
    .header-right-wrap .same-style:hover > a, .header-right-wrap .same-style:hover > button {
      color: darkturquoise; }
    .header-right-wrap .same-style .account-dropdown {
      background: #fff none repeat scroll 0 0;
      box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
      right: 0;
      padding: 8px 12px 10px;
      position: absolute;
      text-align: left;
      top: 190%;
      width: 125px;
      z-index: 220;
      visibility: hidden;
      opacity: 0;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      transition: all 0.5s ease 0s; }
      .header-right-wrap .same-style .account-dropdown.active {
        visibility: visible;
        opacity: 1;
        transform: rotateX(0deg); }
      @media only screen and (max-width: 767px) {
        .header-right-wrap .same-style .account-dropdown {
          top: 100%;
          margin-top: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .header-right-wrap .same-style .account-dropdown {
          top: 100%;
          margin-top: 14px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .header-right-wrap .same-style .account-dropdown {
          left: -40px; } }
      @media only screen and (min-width: 1200px) and (max-width: 1365px) {
        .header-right-wrap .same-style .account-dropdown {
          left: -34px; } }
      .header-right-wrap .same-style .account-dropdown ul li a {
        display: block;
        font-size: 13px;
        line-height: 30px;
        padding: 0;
        text-transform: capitalize;
        color: #000; }
        .header-right-wrap .same-style .account-dropdown ul li a:hover {
          padding-left: 5px;
          color: darkturquoise; }
  .header-right-wrap .same-style.account-setting > a, .header-right-wrap .same-style.account-setting button {
    font-size: 12px; }
  .header-right-wrap .same-style.account-setting > button {
    background: none;
    border: none; }
  .header-right-wrap .same-style.cart-wrap, .header-right-wrap .same-style.header-wishlist, .header-right-wrap .same-style.header-compare {
    margin-left: 15px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .header-right-wrap .same-style.cart-wrap, .header-right-wrap .same-style.header-wishlist, .header-right-wrap .same-style.header-compare {
        margin-left: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-right-wrap .same-style.cart-wrap, .header-right-wrap .same-style.header-wishlist, .header-right-wrap .same-style.header-compare {
        margin-left: 20px; } }
    @media only screen and (max-width: 767px) {
      .header-right-wrap .same-style.cart-wrap, .header-right-wrap .same-style.header-wishlist, .header-right-wrap .same-style.header-compare {
        margin-left: 20px; } }
    .header-right-wrap .same-style.cart-wrap button, .header-right-wrap .same-style.cart-wrap > a, .header-right-wrap .same-style.header-wishlist button, .header-right-wrap .same-style.header-wishlist > a, .header-right-wrap .same-style.header-compare button, .header-right-wrap .same-style.header-compare > a {
      background: transparent;
      border: none;
      padding: 0;
      color: #000;
      font-size: 24px;
      position: relative;
      display: inline-block; }
      .header-right-wrap .same-style.cart-wrap button span.count-style, .header-right-wrap .same-style.cart-wrap > a span.count-style, .header-right-wrap .same-style.header-wishlist button span.count-style, .header-right-wrap .same-style.header-wishlist > a span.count-style, .header-right-wrap .same-style.header-compare button span.count-style, .header-right-wrap .same-style.header-compare > a span.count-style {
        position: absolute;
        top: -9px;
        right: -14px;
        background-color: #000;
        color: #fff;
        display: inline-block;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        line-height: 21px;
        font-size: 12px;
        text-align: center; }
        @media only screen and (min-width: 1200px) and (max-width: 1365px) {
          .header-right-wrap .same-style.cart-wrap button span.count-style, .header-right-wrap .same-style.cart-wrap > a span.count-style, .header-right-wrap .same-style.header-wishlist button span.count-style, .header-right-wrap .same-style.header-wishlist > a span.count-style, .header-right-wrap .same-style.header-compare button span.count-style, .header-right-wrap .same-style.header-compare > a span.count-style {
            right: -5px; } }
    .header-right-wrap .same-style.cart-wrap:hover > button, .header-right-wrap .same-style.header-wishlist:hover > button, .header-right-wrap .same-style.header-compare:hover > button {
      color: darkturquoise; }
    .header-right-wrap .same-style.cart-wrap .shopping-cart-content, .header-right-wrap .same-style.header-wishlist .shopping-cart-content, .header-right-wrap .same-style.header-compare .shopping-cart-content {
      background: #fff none repeat scroll 0 0;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
      padding: 10px 25px 10px;
      position: absolute;
      right: 0;
      top: 190%;
      width: 340px;
      z-index: 9999;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      transition: all 0.5s ease 0s; }
      .header-right-wrap .same-style.cart-wrap .shopping-cart-content.cart-visible, .header-right-wrap .same-style.cart-wrap .shopping-cart-content.active, .header-right-wrap .same-style.header-wishlist .shopping-cart-content.cart-visible, .header-right-wrap .same-style.header-wishlist .shopping-cart-content.active, .header-right-wrap .same-style.header-compare .shopping-cart-content.cart-visible, .header-right-wrap .same-style.header-compare .shopping-cart-content.active {
        opacity: 1;
        visibility: visible;
        transform: rotateX(0deg); }
      @media only screen and (max-width: 767px) {
        .header-right-wrap .same-style.cart-wrap .shopping-cart-content, .header-right-wrap .same-style.header-wishlist .shopping-cart-content, .header-right-wrap .same-style.header-compare .shopping-cart-content {
          width: 295px;
          right: -52px;
          padding: 31px 15px 10px;
          overflow-y: auto;
          height: 300px;
          top: 100%;
          margin-top: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .header-right-wrap .same-style.cart-wrap .shopping-cart-content, .header-right-wrap .same-style.header-wishlist .shopping-cart-content, .header-right-wrap .same-style.header-compare .shopping-cart-content {
          overflow-y: auto;
          height: 300px;
          margin-top: 18px;
          top: 100%;
          margin-top: 14px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .header-right-wrap .same-style.cart-wrap .shopping-cart-content, .header-right-wrap .same-style.header-wishlist .shopping-cart-content, .header-right-wrap .same-style.header-compare .shopping-cart-content {
          overflow-y: auto; } }
      .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul, .header-right-wrap .same-style.header-compare .shopping-cart-content ul {
        padding-top: 20px;
        max-height: 400px;
        overflow-y: auto;
        padding-right: 15px;
        margin-right: -15px; }
        .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li {
          border-bottom: 1px solid #ebebeb;
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 20px;
          padding-bottom: 15px;
          position: relative; }
          .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-img, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-img, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-img {
            flex: 0 0 82px;
            margin-right: 15px; }
          .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-title h4, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-title h4, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-title h4 {
            font-size: 13px;
            line-height: 16px;
            margin: 0;
            margin-right: 20px; }
            .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-title h4 a, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-title h4 a, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-title h4 a {
              color: #000;
              line-height: 1.2; }
              .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-title h4 a:hover, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-title h4 a:hover, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-title h4 a:hover {
                color: darkturquoise; }
          .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-title h6, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-title h6, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-title h6 {
            font-size: 13px;
            margin: 5px 0 8px; }
          .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-title span, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-title span, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-title span {
            font-size: 14px; }
          .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-delete, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-delete, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-delete {
            position: absolute;
            top: 0;
            right: 0; }
            .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-delete a, .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-delete button, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-delete a, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-delete button, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-delete a, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-delete button {
              font-size: 16px;
              color: #000; }
              .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-delete a:hover, .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-delete button:hover, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-delete a:hover, .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-delete button:hover, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-delete a:hover, .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-delete button:hover {
                color: #333; }
      .header-right-wrap .same-style.cart-wrap .shopping-cart-content .shopping-cart-total h4, .header-right-wrap .same-style.header-wishlist .shopping-cart-content .shopping-cart-total h4, .header-right-wrap .same-style.header-compare .shopping-cart-content .shopping-cart-total h4 {
        font-size: 14px;
        margin-top: 17px;
        margin-bottom: 17px; }
        .header-right-wrap .same-style.cart-wrap .shopping-cart-content .shopping-cart-total h4 span, .header-right-wrap .same-style.header-wishlist .shopping-cart-content .shopping-cart-total h4 span, .header-right-wrap .same-style.header-compare .shopping-cart-content .shopping-cart-total h4 span {
          float: right; }
      .header-right-wrap .same-style.cart-wrap .shopping-cart-content .shopping-cart-btn a, .header-right-wrap .same-style.header-wishlist .shopping-cart-content .shopping-cart-btn a, .header-right-wrap .same-style.header-compare .shopping-cart-content .shopping-cart-btn a {
        margin-bottom: 20px;
        padding: 16px 40px 17px;
        font-weight: 500;
        font-size: 14px;
        display: block; }
  .header-right-wrap.header-right-wrap-white {
    margin-top: 40px; }
    .header-right-wrap.header-right-wrap-white .same-style > a {
      color: #fff; }
    .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button, .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > a, .header-right-wrap.header-right-wrap-white .same-style.header-compare > button, .header-right-wrap.header-right-wrap-white .same-style.header-compare > a, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > button, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a, .header-right-wrap.header-right-wrap-white .same-style.account-setting > button, .header-right-wrap.header-right-wrap-white .same-style.account-setting > a, .header-right-wrap.header-right-wrap-white .same-style.header-search > button, .header-right-wrap.header-right-wrap-white .same-style.header-search > a {
      color: #fff; }
      .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-compare > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-compare > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.account-setting > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.account-setting > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-search > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-search > a span.count-style {
        background-color: #fff;
        color: #000; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-right-wrap.header-right-wrap-white {
        margin-top: 0; }
        .header-right-wrap.header-right-wrap-white .same-style > a {
          color: #000; }
        .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button, .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > a, .header-right-wrap.header-right-wrap-white .same-style.header-compare > button, .header-right-wrap.header-right-wrap-white .same-style.header-compare > a, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > button, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a, .header-right-wrap.header-right-wrap-white .same-style.account-setting > button, .header-right-wrap.header-right-wrap-white .same-style.account-setting > a, .header-right-wrap.header-right-wrap-white .same-style.header-search > button, .header-right-wrap.header-right-wrap-white .same-style.header-search > a {
          color: #000; }
          .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-compare > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-compare > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.account-setting > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.account-setting > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-search > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-search > a span.count-style {
            background-color: #000;
            color: #fff; } }
    @media only screen and (max-width: 767px) {
      .header-right-wrap.header-right-wrap-white {
        margin-top: 3px; }
        .header-right-wrap.header-right-wrap-white .same-style > a {
          color: #000; }
        .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button, .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > a, .header-right-wrap.header-right-wrap-white .same-style.header-compare > button, .header-right-wrap.header-right-wrap-white .same-style.header-compare > a, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > button, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a, .header-right-wrap.header-right-wrap-white .same-style.account-setting > button, .header-right-wrap.header-right-wrap-white .same-style.account-setting > a, .header-right-wrap.header-right-wrap-white .same-style.header-search > button, .header-right-wrap.header-right-wrap-white .same-style.header-search > a {
          color: #000; }
          .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-compare > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-compare > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.account-setting > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.account-setting > a span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-search > button span.count-style, .header-right-wrap.header-right-wrap-white .same-style.header-search > a span.count-style {
            background-color: #000;
            color: #fff; } }
  .header-right-wrap .same-style.header-search .search-content {
    background: #ffffff none repeat scroll 0 0;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    min-width: 300px;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 193%;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    transform: rotateX(90deg);
    transform-origin: center top 0;
    transition: all 0.5s ease 0s; }
    .header-right-wrap .same-style.header-search .search-content.active {
      visibility: visible;
      opacity: 1;
      transform: rotateX(0deg); }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-right-wrap .same-style.header-search .search-content {
        top: 154%; } }
    @media only screen and (max-width: 767px) {
      .header-right-wrap .same-style.header-search .search-content {
        right: -105px;
        top: 154%;
        min-width: 260px; } }
    .header-right-wrap .same-style.header-search .search-content form {
      position: relative; }
      .header-right-wrap .same-style.header-search .search-content form input {
        border: 1px solid #d3d3d3;
        outline: medium none;
        padding: 5px 72px 5px 15px;
        transition: all 0.4s ease 0s;
        width: 100%;
        color: #000;
        background: transparent; }
      .header-right-wrap .same-style.header-search .search-content form .button-search {
        background: darkturquoise;
        border-color: #ce9634;
        color: #ffffff;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        top: 0;
        border: none;
        height: 100%;
        padding: 5px 20px 2px;
        font-size: 20px;
        line-height: 1;
        transition: all 0.4s ease 0s; }
        .header-right-wrap .same-style.header-search .search-content form .button-search:hover {
          background-color: #000; }

.stick .same-style.header-search .search-content {
  top: 160%; }

.stick .header-right-wrap.header-right-wrap-white {
  margin-top: 22px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .stick .header-right-wrap.header-right-wrap-white {
      margin-top: 0; } }
  @media only screen and (max-width: 767px) {
    .stick .header-right-wrap.header-right-wrap-white {
      margin-top: 0; } }
  .stick .header-right-wrap.header-right-wrap-white .same-style > a {
    color: #000; }
    .stick .header-right-wrap.header-right-wrap-white .same-style > a:hover {
      color: darkturquoise; }
  .stick .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button {
    color: #000; }
    .stick .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button:hover {
      color: darkturquoise; }
    .stick .header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button span.count-style {
      background-color: #000;
      color: #fff; }

/* header 3 */
.header-padding-2 .container-fluid, .header-padding-2 .container-sm, .header-padding-2 .container-md, .header-padding-2 .container-lg, .header-padding-2 .container-xl {
  padding: 0 70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .header-padding-2 .container-fluid, .header-padding-2 .container-sm, .header-padding-2 .container-md, .header-padding-2 .container-lg, .header-padding-2 .container-xl {
      padding: 0 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-padding-2 .container-fluid, .header-padding-2 .container-sm, .header-padding-2 .container-md, .header-padding-2 .container-lg, .header-padding-2 .container-xl {
      padding: 0 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-padding-2 .container-fluid, .header-padding-2 .container-sm, .header-padding-2 .container-md, .header-padding-2 .container-lg, .header-padding-2 .container-xl {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .header-padding-2 .container-fluid, .header-padding-2 .container-sm, .header-padding-2 .container-md, .header-padding-2 .container-lg, .header-padding-2 .container-xl {
      padding: 0 15px; } }

.header-top-area {
  border-bottom: 1px solid #d5d4d4; }
  .header-top-area .header-top-wap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .header-top-area .header-top-wap {
        display: block;
        padding-bottom: 15px;
        margin-top: 8px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .header-top-area .header-top-wap {
        display: flex;
        margin-bottom: 0px;
        margin-top: 0px; } }
  .header-top-area.border-none {
    border: none; }

.header-offer p {
  color: #555252;
  letter-spacing: .8px;
  margin: 0; }
  .header-offer p span {
    color: #ff1c1c; }
  @media only screen and (max-width: 767px) {
    .header-offer p {
      text-align: center; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-offer p {
      text-align: right; } }

.language-currency-wrap {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .language-currency-wrap {
      justify-content: center; } }
  .language-currency-wrap .same-language-currency {
    position: relative;
    margin-right: 54px; }
    @media only screen and (max-width: 767px) {
      .language-currency-wrap .same-language-currency {
        margin-right: 15px; } }
    .language-currency-wrap .same-language-currency:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 1px;
      background-color: #d5d4d4;
      right: -27px;
      top: 47%;
      transform: translateY(-50%); }
      @media only screen and (max-width: 767px) {
        .language-currency-wrap .same-language-currency:before {
          right: -10px; } }
    .language-currency-wrap .same-language-currency:last-child:before {
      display: none; }
    .language-currency-wrap .same-language-currency:last-child {
      margin-right: 0; }
    .language-currency-wrap .same-language-currency span {
      letter-spacing: .8px;
      color: #555252;
      line-height: 54px;
      display: inline-block;
      margin-top: -3px;
      cursor: pointer; }
      @media only screen and (max-width: 767px) {
        .language-currency-wrap .same-language-currency span {
          line-height: 35px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .language-currency-wrap .same-language-currency span {
          line-height: 54px; } }
      .language-currency-wrap .same-language-currency span i {
        font-size: 16px;
        color: #555252;
        position: relative;
        top: 2px; }
    .language-currency-wrap .same-language-currency .lang-car-dropdown {
      background: #fff none repeat scroll 0 0;
      box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
      left: 0;
      padding: 8px 12px 10px;
      position: absolute;
      text-align: left;
      top: 100%;
      width: 125px;
      z-index: 220;
      visibility: hidden;
      opacity: 0;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      transition: all 0.5s ease 0s; }
      .language-currency-wrap .same-language-currency .lang-car-dropdown ul li a, .language-currency-wrap .same-language-currency .lang-car-dropdown ul li button {
        background: none;
        border: none;
        line-height: 27px;
        font-size: 13px; }
        .language-currency-wrap .same-language-currency .lang-car-dropdown ul li a:hover, .language-currency-wrap .same-language-currency .lang-car-dropdown ul li button:hover {
          color: darkturquoise; }
    .language-currency-wrap .same-language-currency p {
      letter-spacing: .8px;
      color: #555252;
      line-height: 1;
      margin-top: -1px; }
    .language-currency-wrap .same-language-currency:hover > a, .language-currency-wrap .same-language-currency:hover span {
      color: darkturquoise; }
    .language-currency-wrap .same-language-currency:hover .lang-car-dropdown {
      visibility: visible;
      opacity: 1;
      transform: rotateX(0deg); }
    .language-currency-wrap .same-language-currency.language-style span {
      text-transform: capitalize; }

/* home 4 */
.home-sidebar-left {
  height: 100%;
  position: fixed;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 375px;
  z-index: 7;
  padding: 72px 0 40px 0px; }
  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .home-sidebar-left {
      width: 300px;
      padding: 50px 0 30px 0px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .home-sidebar-left {
      width: 300px;
      padding: 50px 0 30px 0px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .home-sidebar-left {
      width: 280px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .home-sidebar-left {
      width: 250px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-sidebar-left {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .home-sidebar-left {
      display: none; } }
  .home-sidebar-left .logo {
    margin-top: 0;
    padding: 0px 0 0px 108px; }
    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      .home-sidebar-left .logo {
        padding: 0px 0 0px 50px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .home-sidebar-left .logo {
        padding: 0px 0 0px 30px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .home-sidebar-left .logo {
        padding: 0px 0 0px 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .home-sidebar-left .logo {
        padding: 0px 0 0px 30px; } }
  .home-sidebar-left .header-right-wrap {
    justify-content: flex-start;
    margin-top: 16px;
    margin-bottom: 67px;
    padding: 0px 0 0px 108px; }
    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      .home-sidebar-left .header-right-wrap {
        padding: 0px 0 0px 50px;
        margin-top: 26px;
        margin-bottom: 27px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .home-sidebar-left .header-right-wrap {
        padding: 0px 0 0px 30px;
        margin-top: 26px;
        margin-bottom: 27px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .home-sidebar-left .header-right-wrap {
        padding: 0px 0 0px 15px;
        margin-top: 30px;
        margin-bottom: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .home-sidebar-left .header-right-wrap {
        padding: 0px 0 0px 30px;
        margin-top: 30px;
        margin-bottom: 30px; } }
    .home-sidebar-left .header-right-wrap .same-style.cart-wrap .shopping-cart-content {
      right: auto;
      left: 0; }
      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
        .home-sidebar-left .header-right-wrap .same-style.cart-wrap .shopping-cart-content {
          overflow-y: auto;
          height: 400px; } }
      @media only screen and (min-width: 1200px) and (max-width: 1365px) {
        .home-sidebar-left .header-right-wrap .same-style.cart-wrap .shopping-cart-content {
          overflow-y: auto;
          height: 300px; } }
    .home-sidebar-left .header-right-wrap .same-style.header-search .search-content {
      left: 0;
      right: auto; }
  .home-sidebar-left .sidebar-copyright {
    padding: 120px 63px 50px 63px; }
    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      .home-sidebar-left .sidebar-copyright {
        padding: 40px 63px 50px 63px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .home-sidebar-left .sidebar-copyright {
        padding: 40px 63px 50px 63px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .home-sidebar-left .sidebar-copyright {
        padding: 40px 15px 50px 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .home-sidebar-left .sidebar-copyright {
        padding: 50px 15px 50px 30px; } }
    .home-sidebar-left .sidebar-copyright p {
      letter-spacing: .9px;
      color: #313131;
      margin: 0;
      text-align: center; }

.home-sidebar-right {
  border-left: 1px solid #efefef;
  margin-left: 375px; }
  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .home-sidebar-right {
      margin-left: 300px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .home-sidebar-right {
      margin-left: 280px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .home-sidebar-right {
      margin-left: 250px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-sidebar-right {
      margin-left: 0px; } }
  @media only screen and (max-width: 767px) {
    .home-sidebar-right {
      margin-left: 0px; } }

.sidebar-menu {
  padding: 0px 0 0px 108px; }
  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .sidebar-menu {
      padding: 0px 0 0px 50px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .sidebar-menu {
      padding: 0px 0 0px 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar-menu {
      padding: 0px 0 0px 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar-menu {
      padding: 0px 0 0px 30px; } }
  .sidebar-menu nav ul li {
    padding-bottom: 33px;
    position: relative; }
    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      .sidebar-menu nav ul li {
        padding-bottom: 20px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .sidebar-menu nav ul li {
        padding-bottom: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .sidebar-menu nav ul li {
        padding-bottom: 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .sidebar-menu nav ul li {
        padding-bottom: 12px; } }
    .sidebar-menu nav ul li:last-child {
      padding-bottom: 0; }
    .sidebar-menu nav ul li ul.submenu {
      background: #fff none repeat scroll 0 0;
      box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
      display: block;
      left: 100%;
      padding: 20px 0px 22px;
      position: absolute;
      top: 0;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      transition: all 0.5s ease 0s;
      visibility: hidden;
      width: 190px;
      z-index: 999; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .sidebar-menu nav ul li ul.submenu {
          overflow-y: auto;
          max-height: 250px; } }
      .sidebar-menu nav ul li ul.submenu li {
        display: block;
        padding: 0 20px;
        margin-left: 0px; }
        .sidebar-menu nav ul li ul.submenu li a {
          color: #333;
          display: block;
          font-size: 13px;
          font-weight: 400;
          line-height: 32px;
          text-align: left;
          text-transform: capitalize;
          padding-top: 0px; }
          .sidebar-menu nav ul li ul.submenu li a:hover {
            color: darkturquoise; }
          .sidebar-menu nav ul li ul.submenu li a::before {
            background: darkturquoise none repeat scroll 0 0;
            border-radius: 50%;
            content: "";
            height: 7px;
            left: 15px;
            opacity: 0;
            position: absolute;
            top: 13px;
            transition: all 0.3s ease-in-out 0s;
            width: 7px;
            z-index: 999; }
        .sidebar-menu nav ul li ul.submenu li:hover a::before {
          opacity: 1; }
        .sidebar-menu nav ul li ul.submenu li:hover a {
          padding-left: 12px; }
    .sidebar-menu nav ul li:hover > ul.submenu {
      transform: rotateX(0deg);
      visibility: visible; }
    .sidebar-menu nav ul li ul.mega-menu {
      background-color: #fff;
      display: block;
      left: 100%;
      padding: 40px 40px 0px 40px;
      position: absolute;
      text-align: left;
      top: 0;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      transition: all 0.5s ease 0s;
      visibility: hidden;
      width: 700px;
      z-index: 999;
      box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3); }
      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
        .sidebar-menu nav ul li ul.mega-menu {
          top: -50px; } }
      @media only screen and (min-width: 1200px) and (max-width: 1365px) {
        .sidebar-menu nav ul li ul.mega-menu {
          top: -50px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .sidebar-menu nav ul li ul.mega-menu {
          top: -80px; } }
      .sidebar-menu nav ul li ul.mega-menu > li {
        display: inline-block;
        float: left;
        margin-left: 0;
        padding: 0;
        width: 33.33%; }
        .sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-title {
          float: inherit;
          width: 100%; }
          .sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-title a {
            color: #242424;
            margin: 0 0 10px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500; }
            .sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-title a::before {
              display: none; }
          .sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-title:hover a {
            padding-left: 0px; }
        .sidebar-menu nav ul li ul.mega-menu > li ul li {
          display: block;
          float: inherit;
          padding: 0px; }
          .sidebar-menu nav ul li ul.mega-menu > li ul li a {
            color: #333;
            display: block;
            font-size: 13px;
            font-weight: 400;
            line-height: 32px;
            text-align: left;
            text-transform: capitalize;
            padding-top: 0px; }
            .sidebar-menu nav ul li ul.mega-menu > li ul li a:hover {
              color: darkturquoise; }
            .sidebar-menu nav ul li ul.mega-menu > li ul li a::before {
              background: darkturquoise none repeat scroll 0 0;
              border-radius: 50%;
              content: "";
              height: 7px;
              left: 0px;
              opacity: 0;
              position: absolute;
              top: 13px;
              transition: all 0.3s ease-in-out 0s;
              width: 7px;
              z-index: 999; }
          .sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-img:hover a {
            padding-left: 0px; }
          .sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-img a::before {
            display: none; }
          .sidebar-menu nav ul li ul.mega-menu > li ul li:hover a::before {
            opacity: 1; }
          .sidebar-menu nav ul li ul.mega-menu > li ul li:hover a {
            padding-left: 12px; }
      .sidebar-menu nav ul li ul.mega-menu.mega-menu-padding {
        padding: 40px 40px 36px 40px; }
    .sidebar-menu nav ul li:hover > ul.mega-menu {
      transform: rotateX(0deg);
      visibility: visible; }
    .sidebar-menu nav ul li a {
      color: #000000;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: .8px;
      text-transform: capitalize;
      display: block; }
      @media only screen and (min-width: 1200px) and (max-width: 1365px) {
        .sidebar-menu nav ul li a {
          font-size: 16px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .sidebar-menu nav ul li a {
          font-size: 16px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .sidebar-menu nav ul li a {
          font-size: 16px; } }
      .sidebar-menu nav ul li a span {
        float: right;
        padding-right: 20px; }
    .sidebar-menu nav ul li:hover a {
      color: darkturquoise; }

.header-hm4-none {
  display: none; }
  @media only screen and (max-width: 767px) {
    .header-hm4-none {
      display: block; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hm4-none {
      display: block; } }

/* home 5 */
.wrapper {
  margin: 20px 20px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .wrapper {
      margin-top: 0; } }
  @media only screen and (max-width: 767px) {
    .wrapper {
      margin: 0; } }

.transparent-bar {
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 999; }

.header-padding-3 .container-fluid, .header-padding-3 .container-sm, .header-padding-3 .container-md, .header-padding-3 .container-lg, .header-padding-3 .container-xl {
  padding: 0 80px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .header-padding-3 .container-fluid, .header-padding-3 .container-sm, .header-padding-3 .container-md, .header-padding-3 .container-lg, .header-padding-3 .container-xl {
      padding: 0 40px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-padding-3 .container-fluid, .header-padding-3 .container-sm, .header-padding-3 .container-md, .header-padding-3 .container-lg, .header-padding-3 .container-xl {
      padding: 0 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-padding-3 .container-fluid, .header-padding-3 .container-sm, .header-padding-3 .container-md, .header-padding-3 .container-lg, .header-padding-3 .container-xl {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .header-padding-3 .container-fluid, .header-padding-3 .container-sm, .header-padding-3 .container-md, .header-padding-3 .container-lg, .header-padding-3 .container-xl {
      padding: 0 15px; } }

/* home 7 */
.clickable-mainmenu {
  margin-top: 35px; }
  .clickable-mainmenu a, .clickable-mainmenu button {
    color: #fff;
    font-size: 30px;
    line-height: 1;
    display: inline-block;
    background: none;
    border: none; }
    .clickable-mainmenu a:hover, .clickable-mainmenu button:hover {
      color: darkturquoise; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .clickable-mainmenu {
      margin-top: -3px; } }
  @media only screen and (max-width: 767px) {
    .clickable-mainmenu {
      margin-top: -3px; } }
  .clickable-mainmenu .sidebar-menu {
    padding-left: 0; }

.clickable-mainmenu {
  background: white none repeat scroll 0 0;
  color: #353535;
  height: 100vh;
  padding: 130px 35px 40px;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(-110%);
  transition: transform 0.5s ease-in-out 0s;
  width: 300px;
  z-index: 9999;
  margin-top: 0; }
  .clickable-mainmenu.inside {
    transform: translateX(0);
    z-index: 9999; }
  @media only screen and (max-width: 767px) {
    .clickable-mainmenu {
      padding: 100px 35px 40px; } }
  .clickable-mainmenu .clickable-mainmenu-icon button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: #000;
    cursor: pointer;
    font-size: 50px;
    padding: 0;
    position: absolute;
    left: 20px;
    top: 20px;
    transition: all 0.3s ease 0s; }
    .clickable-mainmenu .clickable-mainmenu-icon button:hover {
      color: darkturquoise; }
  .clickable-mainmenu .side-logo {
    margin-bottom: 50px; }
    @media only screen and (max-width: 767px) {
      .clickable-mainmenu .side-logo {
        margin-bottom: 40px; } }
  .clickable-mainmenu .clickable-menu-style {
    background: transparent; }
    .clickable-mainmenu .clickable-menu-style ul li a {
      color: #000;
      font-size: 16px;
      text-transform: capitalize;
      padding: 0 0 20px; }
      .clickable-mainmenu .clickable-menu-style ul li a:hover {
        background: transparent;
        color: darkturquoise; }
  .clickable-mainmenu .side-social {
    margin-top: 50px; }
    .clickable-mainmenu .side-social ul li {
      display: inline-block;
      margin: 0 30px 0 0;
      position: relative; }
      .clickable-mainmenu .side-social ul li:last-child {
        margin-right: 0; }
      .clickable-mainmenu .side-social ul li:before {
        position: absolute;
        right: -20px;
        top: 50%;
        background: #999;
        height: 1px;
        width: 10px;
        content: "";
        transform: translateY(-50%); }
      .clickable-mainmenu .side-social ul li:last-child:before {
        display: none; }
      .clickable-mainmenu .side-social ul li a {
        font-size: 18px; }
        .clickable-mainmenu .side-social ul li a.facebook {
          color: #3b5999; }
          .clickable-mainmenu .side-social ul li a.facebook:hover {
            color: #333; }
        .clickable-mainmenu .side-social ul li a.dribbble {
          color: #ea4c89; }
          .clickable-mainmenu .side-social ul li a.dribbble:hover {
            color: #333; }
        .clickable-mainmenu .side-social ul li a.pinterest {
          color: #bd081c; }
          .clickable-mainmenu .side-social ul li a.pinterest:hover {
            color: #333; }
        .clickable-mainmenu .side-social ul li a.twitter {
          color: #55acee; }
          .clickable-mainmenu .side-social ul li a.twitter:hover {
            color: #333; }
        .clickable-mainmenu .side-social ul li a.linkedin {
          color: #0077B5; }
          .clickable-mainmenu .side-social ul li a.linkedin:hover {
            color: #333; }
  .clickable-mainmenu-active a, .clickable-mainmenu-active button {
    margin-top: 35px;
    color: #fff;
    background: none;
    border: none;
    font-size: 30px; }
    .clickable-mainmenu-active a:hover, .clickable-mainmenu-active button:hover {
      color: darkturquoise; }

.header-hm-7.stick .clickable-menu {
  margin-top: 19px; }
  .header-hm-7.stick .clickable-menu a, .header-hm-7.stick .clickable-menu button {
    color: #282828;
    background: none;
    border: none;
    font-size: 30px;
    margin-top: 0; }
    .header-hm-7.stick .clickable-menu a:hover, .header-hm-7.stick .clickable-menu button:hover {
      color: darkturquoise; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hm-7.stick .clickable-menu {
      margin-top: 0px; } }
  @media only screen and (max-width: 767px) {
    .header-hm-7.stick .clickable-menu {
      margin-top: 0px; } }

.header-hm-7.stick .logo {
  margin-top: 22px;
  margin-bottom: 18px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hm-7.stick .logo {
      margin-top: 2px;
      margin-bottom: 0px; } }
  @media only screen and (max-width: 767px) {
    .header-hm-7.stick .logo {
      margin-top: 2px;
      margin-bottom: 0px; } }

.header-hm-7.stick .header-right-wrap {
  margin-top: 12px;
  margin-bottom: 18px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hm-7.stick .header-right-wrap {
      margin-top: 2px;
      margin-bottom: 0px; } }
  @media only screen and (max-width: 767px) {
    .header-hm-7.stick .header-right-wrap {
      margin-top: 2px;
      margin-bottom: 0px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-hm-7 .header-right-wrap {
    margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .header-hm-7 .header-right-wrap {
    margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .header-hm-7 .header-right-wrap .same-style.cart-wrap .shopping-cart-content {
    right: 0px; } }

@media only screen and (max-width: 767px) {
  .header-hm-7 .header-right-wrap .same-style.header-search .search-content {
    right: -80px; } }

/* home 8 */
.header-hm8 .header-right-wrap {
  margin-top: 6px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hm8 .header-right-wrap {
      margin-right: 0; } }
  @media only screen and (max-width: 767px) {
    .header-hm8 .header-right-wrap {
      margin-right: 0; } }
  .header-hm8 .header-right-wrap .same-style.cart-wrap .shopping-cart-content {
    top: 125%;
    margin-top: 0; }
    @media only screen and (max-width: 767px) {
      .header-hm8 .header-right-wrap .same-style.cart-wrap .shopping-cart-content {
        right: -99px; } }
  .header-hm8 .header-right-wrap .same-style .account-dropdown {
    top: 125%;
    margin-top: 0;
    left: -32px; }
  .header-hm8 .header-right-wrap .same-style.header-search .search-content {
    top: 125%; }
    @media only screen and (max-width: 767px) {
      .header-hm8 .header-right-wrap .same-style.header-search .search-content {
        right: -150px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .header-hm8 .header-right-wrap .same-style.header-search .search-content {
        right: 0px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-hm8 .header-res-padding {
    padding: 20px 0 20px; }
  .header-hm8 .mean-container a.meanmenu-reveal {
    top: -26px; } }

@media only screen and (max-width: 767px) {
  .header-hm8 .header-res-padding {
    padding: 20px 0 20px; }
  .header-hm8 .mean-container a.meanmenu-reveal {
    top: -26px; } }

.header-hm8 .stick .logo {
  display: none; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hm8 .stick .logo {
      display: block; } }
  @media only screen and (max-width: 767px) {
    .header-hm8 .stick .logo {
      display: block; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-hm8 .logo {
    text-align: left !important; } }

@media only screen and (max-width: 767px) {
  .header-hm8 .logo {
    text-align: left !important; } }

.header-hm8 .main-menu {
  padding-bottom: 8px; }
  .header-hm8 .main-menu nav ul li > a {
    line-height: 60px; }

/* home 9 */
.header-hm9 .main-menu nav ul li a {
  line-height: 72px; }

.header-hm9 .header-top-area {
  padding: 22px 0;
  border-bottom: 1px solid #cecece; }
  .header-hm9 .header-top-area.header-top-border17 {
    border-bottom: 1px solid #9f9f9f; }

.header-hm9 .header-right-wrap {
  margin-top: 13px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hm9 .header-right-wrap {
      margin-right: 0;
      margin-top: 0; } }
  @media only screen and (max-width: 767px) {
    .header-hm9 .header-right-wrap {
      margin-right: 0;
      margin-top: 0; } }
  @media only screen and (max-width: 767px) {
    .header-hm9 .header-right-wrap .same-style.cart-wrap .shopping-cart-content {
      right: -97px; } }

.header-hm9 .logo-hm9 {
  margin-top: 12px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-hm9 .mean-container a.meanmenu-reveal {
    top: -26px; }
  .header-hm9 .header-res-padding {
    padding: 20px 0 20px; } }

@media only screen and (max-width: 767px) {
  .header-hm9 .mean-container a.meanmenu-reveal {
    top: -26px; }
  .header-hm9 .header-res-padding {
    padding: 20px 0 20px; } }

/* header hm10 */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-in-container .header-top-wap {
    display: block;
    margin-bottom: 15px;
    margin-top: 8px; }
    .header-in-container .header-top-wap .language-currency-wrap {
      justify-content: center; }
    .header-in-container .header-top-wap .header-offer p {
      text-align: center; } }

@media only screen and (max-width: 767px) {
  .header-hm9 .header-right-wrap .same-style.header-search .search-content {
    right: -142px; } }

.mobile-aside-button {
  padding: 0; }

/* offcanvas mobile menu */
.header-mobile-navigation {
  padding: 20px 0; }
  .header-mobile-navigation .mobile-navigation .header-cart-icon a span {
    left: 50%; }

.offcanvas-widget-area {
  margin-top: 35px; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .offcanvas-widget-area {
      margin-bottom: 30px; } }

.off-canvas-contact-widget {
  margin-bottom: 20px; }
  .off-canvas-contact-widget .header-contact-info {
    flex-basis: 33.33%; }
    .off-canvas-contact-widget .header-contact-info__list li {
      display: inline-block;
      margin-right: 25px; }
      .off-canvas-contact-widget .header-contact-info__list li i {
        font-size: 16px;
        margin-right: 5px; }
      .off-canvas-contact-widget .header-contact-info__list li a:hover {
        color: darkturquoise; }
      .off-canvas-contact-widget .header-contact-info__list li:last-child {
        margin-right: 0; }

.offcanvas-mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  z-index: 999999999999;
  transform: translateX(100%);
  padding-left: 60px;
  transition: 0.6s; }
  .offcanvas-mobile-menu.active {
    transform: translateX(0); }
  .offcanvas-mobile-menu.inactive {
    transform: translateX(calc(100% + 60px)); }

.offcanvas-menu-close {
  position: absolute;
  left: 0;
  top: 0;
  background: #343538;
  z-index: 9;
  width: 60px;
  height: 65px;
  color: #fff;
  line-height: 70px;
  text-align: center;
  font-size: 30px;
  border: none;
  padding: 0; }
  .offcanvas-menu-close:hover, .offcanvas-menu-close:focus {
    color: #ffffff; }
  .offcanvas-menu-close i {
    font-size: 40px;
    margin-top: 12px;
    line-height: 1;
    transition: 0.3s;
    transform: rotate(0); }
  .offcanvas-menu-close:hover i {
    transform: rotate(-90deg); }

.offcanvas-wrapper {
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff; }

.offcanvas-mobile-search-area {
  background-color: #e6e6e6;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  z-index: 9;
  margin-left: 60px; }
  .offcanvas-mobile-search-area input {
    width: 100%;
    font-size: 16px;
    display: block;
    padding: 9px 25px;
    color: #222;
    background: #e6e6e6;
    border: none; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .offcanvas-mobile-search-area input {
        font-size: 14px;
        padding: 5px 15px; } }
  .offcanvas-mobile-search-area button {
    background: none;
    border: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    padding: 0; }
    .offcanvas-mobile-search-area button i {
      font-size: 18px;
      line-height: 40px; }

.offcanvas-inner-content {
  padding: 90px 35px 0;
  height: 100%;
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .offcanvas-inner-content {
      padding: 70px 25px 0; } }

.offcanvas-navigation {
  margin-bottom: 50px; }
  .offcanvas-navigation > ul li.menu-item-has-children .sub-menu {
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s; }
  .offcanvas-navigation > ul li.menu-item-has-children.active > .sub-menu {
    height: 100%;
    visibility: visible;
    opacity: 1; }
  .offcanvas-navigation > ul li > a {
    color: #111;
    font-weight: 600;
    padding: 10px 0;
    display: block; }
    .offcanvas-navigation > ul li > a:hover {
      color: darkturquoise; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .offcanvas-navigation > ul li > a {
        font-size: 14px;
        line-height: 20px; } }
  .offcanvas-navigation ul.sub-menu {
    margin-left: 25px;
    transition: 0.3s; }
    .offcanvas-navigation ul.sub-menu > li > a {
      font-size: 13px;
      font-weight: 400;
      padding: 10px 0; }
      .offcanvas-navigation ul.sub-menu > li > a:hover {
        color: darkturquoise; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .offcanvas-navigation ul.sub-menu > li > a {
          font-size: 13px;
          line-height: 18px; } }
  .offcanvas-navigation ul li a {
    text-transform: uppercase; }
  .offcanvas-navigation ul li.menu-item-has-children {
    position: relative;
    display: block; }
    .offcanvas-navigation ul li.menu-item-has-children a {
      display: block; }
    .offcanvas-navigation ul li.menu-item-has-children.active > .menu-expand i:before {
      transform: rotate(0); }
    .offcanvas-navigation ul li.menu-item-has-children .menu-expand {
      position: absolute;
      right: auto;
      left: 95%;
      top: -5px;
      width: 30px;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
      text-align: center; }
      .offcanvas-navigation ul li.menu-item-has-children .menu-expand i {
        display: block;
        margin-top: 25px;
        border-bottom: 1px solid;
        position: relative;
        width: 10px;
        transition: all 250ms ease-out; }
        .offcanvas-navigation ul li.menu-item-has-children .menu-expand i:before {
          width: 100%;
          content: "";
          border-bottom: 1px solid;
          display: block;
          position: absolute;
          top: 0;
          transform: rotate(90deg); }

.off-canvas-widget-social a {
  margin: 0 10px;
  font-size: 14px; }
  .off-canvas-widget-social a:first-child {
    margin-left: 0; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .off-canvas-widget-social a {
      margin: 0 10px; } }
  .off-canvas-widget-social a:hover {
    color: darkturquoise; }

/* offcanvas settings */
.offcanvas-settings .offcanvas-navigation > ul > li > a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0; }

.offcanvas-settings .offcanvas-navigation > ul > li.menu-item-has-children .menu-expand {
  height: 30px;
  top: -15px;
  margin-top: 0; }

.offcanvas-settings .offcanvas-navigation ul.sub-menu > li > a {
  padding: 5px 0; }

.transparent-bar {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 999; }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px), only screen and (min-width: 576px) and (max-width: 767px) {
    .transparent-bar {
      position: relative; } }
  .transparent-bar .header-top {
    display: none !important; }

.mobile-menu-middle {
  margin-bottom: 50px; }
  .mobile-menu-middle .lang-curr-style {
    margin-bottom: 20px; }
    .mobile-menu-middle .lang-curr-style:last-child {
      margin-bottom: 0; }
    .mobile-menu-middle .lang-curr-style button {
      background: none;
      border: none; }
    .mobile-menu-middle .lang-curr-style .title {
      display: inline-block;
      font-weight: 500; }
    .mobile-menu-middle .lang-curr-style select {
      border: 1px solid #ddd;
      padding: 5px 0; }
    .mobile-menu-middle .lang-curr-style ul li button {
      font-size: 12px; }
      .mobile-menu-middle .lang-curr-style ul li button:hover, .mobile-menu-middle .lang-curr-style ul li button.active {
        color: darkturquoise; }

.panel-informativo {
  background-color: darkturquoise;
  height: 0ex;
  padding-top: 10px;
  padding-bottom: 20px; }
  .panel-informativo h5 {
    color: white;
    line-height: 10px; }

/*----- 3. Slider style  ------*/
.slider-height-1 {
  height: 600px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-height-1 {
      height: 650px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-1 {
      height: 520px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-1 {
      height: 450px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-1 {
      height: auto;
      padding: 50px 0 0; }
      .slider-height-1.slider-height-res15 {
        padding: 0 0 0; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-height-1 {
      padding: 38px 0 0; } }
  @media only screen and (max-width: 767px) {
    .slider-height-1.slider-height-res {
      height: 400px;
      position: relative; }
      .slider-height-1.slider-height-res:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: #fff;
        opacity: .5; } }

.single-slider {
  display: flex;
  align-items: flex-end; }
  .single-slider .slider-content {
    padding: 183px 0 0; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .single-slider .slider-content {
        padding: 80px 0 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-slider .slider-content {
        padding: 45px 0 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-slider .slider-content {
        padding: 0 0 0; } }
    @media only screen and (max-width: 767px) {
      .single-slider .slider-content {
        padding: 0 0 40px;
        text-align: center; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-slider .slider-content {
        padding: 0 0 0;
        text-align: left; } }
    .single-slider .slider-content h3 {
      font-size: 24px;
      font-weight: 500;
      margin: 0; }
    .single-slider .slider-content h1 {
      line-height: 97px;
      font-size: 72px;
      color: #010101;
      margin: 6px 0 37px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-slider .slider-content h1 {
          line-height: 65px;
          font-size: 52px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-slider .slider-content h1 {
          line-height: 52px;
          font-size: 40px;
          margin: 6px 0 20px; } }
      @media only screen and (max-width: 767px) {
        .single-slider .slider-content h1 {
          line-height: 48px;
          font-size: 33px;
          margin: 6px 0 20px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .single-slider .slider-content h1 {
          font-size: 30px;
          margin: 6px 0 10px; } }
    .single-slider .slider-content .slider-btn a {
      font-size: 16px;
      color: #010101;
      display: inline-block;
      border: 1px solid #333;
      text-transform: uppercase;
      line-height: 1;
      padding: 19px 50px 21px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-slider .slider-content .slider-btn a {
          padding: 15px 35px 17px; } }
      @media only screen and (max-width: 767px) {
        .single-slider .slider-content .slider-btn a {
          padding: 15px 35px 17px; } }
      .single-slider .slider-content .slider-btn a:hover {
        border: 1px solid darkturquoise;
        color: #fff; }
    .single-slider .slider-content.slider-content-10 {
      padding-top: 0; }
      @media only screen and (max-width: 767px) {
        .single-slider .slider-content.slider-content-10 {
          text-align: center;
          display: block;
          width: 100%; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .single-slider .slider-content.slider-content-10 {
          text-align: left; } }
  .single-slider .slider-single-img {
    margin: 0 -80px 0 -80px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .single-slider .slider-single-img {
        margin: 100px 0 0 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-slider .slider-single-img {
        margin: 0 0 0 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-slider .slider-single-img {
        margin: 0 0 0 0; } }
    @media only screen and (max-width: 767px) {
      .single-slider .slider-single-img {
        margin: 0 0 0 0; } }
  .single-slider.single-slider-10 {
    align-items: center; }

.swiper-slide .slider-content * {
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.swiper-slide-active .slider-animated-1 h3 {
  -webkit-animation-delay: 1.0s;
  animation-delay: 1.0s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.swiper-slide-active .slider-animated-1 h1 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.swiper-slide-active .slider-animated-1 h5 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.swiper-slide-active .slider-animated-1 p {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.swiper-slide-active .slider-animated-1 a {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.swiper-slide-active .slider-animated-1.slider-single-img img {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.swiper-slide-active .slider-animated-1.single-slider-img4 img {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.swiper-slide-active .slider-animated-1.slider-singleimg-hm9 img {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.swiper-slide-active .slider-animated-1.slider-singleimg-hm11 img {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.swiper-slide-active .slider-animated-2 h3 {
  -webkit-animation-delay: 1.0s;
  animation-delay: 1.0s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

.swiper-slide-active .slider-animated-2 h1 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

.swiper-slide-active .slider-animated-2 a {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

.swiper-slide-active .slider-animated-2.slider-singleimg-hm10 img {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

.nav-style-1 .ht-swiper-button-nav {
  width: 80px;
  height: 80px;
  padding: 0;
  color: #999;
  display: inline-block;
  font-size: 80px;
  left: 80px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  background: none;
  border: none; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .nav-style-1 .ht-swiper-button-nav {
      left: 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .nav-style-1 .ht-swiper-button-nav {
      left: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .nav-style-1 .ht-swiper-button-nav {
      left: 15px;
      font-size: 60px; } }
  @media only screen and (max-width: 767px) {
    .nav-style-1 .ht-swiper-button-nav {
      left: 15px;
      font-size: 50px; } }
  .nav-style-1 .ht-swiper-button-nav:hover {
    color: darkturquoise; }
  .nav-style-1 .ht-swiper-button-nav.swiper-button-next {
    left: auto;
    right: 80px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .nav-style-1 .ht-swiper-button-nav.swiper-button-next {
        right: 30px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .nav-style-1 .ht-swiper-button-nav.swiper-button-next {
        right: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .nav-style-1 .ht-swiper-button-nav.swiper-button-next {
        right: 15px; } }
    @media only screen and (max-width: 767px) {
      .nav-style-1 .ht-swiper-button-nav.swiper-button-next {
        right: 15px; } }

.nav-style-1.nav-testi-style .owl-nav div {
  font-size: 50px;
  left: -155px;
  color: #8d8c8c; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .nav-style-1.nav-testi-style .owl-nav div {
      left: -80px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .nav-style-1.nav-testi-style .owl-nav div {
      left: -80px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .nav-style-1.nav-testi-style .owl-nav div {
      left: -30px; } }
  @media only screen and (max-width: 767px) {
    .nav-style-1.nav-testi-style .owl-nav div {
      display: none; } }
  .nav-style-1.nav-testi-style .owl-nav div:hover {
    color: #3d6882; }
  .nav-style-1.nav-testi-style .owl-nav div.owl-next {
    right: -155px;
    left: auto; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .nav-style-1.nav-testi-style .owl-nav div.owl-next {
        right: -80px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .nav-style-1.nav-testi-style .owl-nav div.owl-next {
        right: -80px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .nav-style-1.nav-testi-style .owl-nav div.owl-next {
        right: -30px; } }

.nav-style-1.nav-testi-style.nav-testi-mrg .owl-nav div {
  left: -80px;
  color: #fff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .nav-style-1.nav-testi-style.nav-testi-mrg .owl-nav div {
      left: 0px; } }
  @media only screen and (max-width: 767px) {
    .nav-style-1.nav-testi-style.nav-testi-mrg .owl-nav div {
      display: none; } }
  .nav-style-1.nav-testi-style.nav-testi-mrg .owl-nav div.owl-next {
    right: -80px;
    left: auto; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .nav-style-1.nav-testi-style.nav-testi-mrg .owl-nav div.owl-next {
        right: 0px;
        left: auto; } }
    @media only screen and (max-width: 767px) {
      .nav-style-1.nav-testi-style.nav-testi-mrg .owl-nav div.owl-next {
        display: none; } }

.slider-area:hover .nav-style-1 .ht-swiper-button-nav {
  opacity: 1;
  visibility: visible; }

.slider-area.ml-10 {
  margin-left: 0; }

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-area.ml-70 {
    margin-left: 15px; }
  .slider-area.mr-70 {
    margin-right: 15px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area.ml-70 {
    margin-left: 30px; }
  .slider-area.mr-70 {
    margin-right: 30px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area.ml-70 {
    margin-left: 40px; }
  .slider-area.mr-70 {
    margin-right: 40px; } }

@media only screen and (max-width: 767px) {
  .slider-area.ml-70 {
    margin-left: 15px; }
  .slider-area.mr-70 {
    margin-right: 15px; } }

.slider-area .swiper-pagination-bullets {
  bottom: 26px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center; }
  .slider-area .swiper-pagination-bullets .swiper-pagination-bullet {
    background: #fff none repeat scroll 0 0;
    opacity: 1;
    border-radius: 100%;
    display: inline-block;
    height: 14px;
    margin: 0 8px;
    width: 14px;
    position: relative; }
    .slider-area .swiper-pagination-bullets .swiper-pagination-bullet:before {
      position: absolute;
      content: "";
      left: -3px;
      right: -3px;
      top: -3px;
      bottom: -3px;
      border: 1px solid transparent;
      border-radius: 100%; }
    .slider-area .swiper-pagination-bullets .swiper-pagination-bullet-active:before {
      border: 1px solid #fff; }

/* slider style-2 */
.slider-content-2 {
  margin-top: -6px; }
  @media only screen and (max-width: 767px) {
    .slider-content-2 {
      text-align: center; } }
  .slider-content-2 h3 {
    color: #000000;
    font-size: 24px;
    font-weight: 500;
    position: relative;
    margin-left: 135px;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .slider-content-2 h3 {
        margin-left: 0px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .slider-content-2 h3 {
        margin-left: 60px; } }
    .slider-content-2 h3::before {
      position: absolute;
      left: -135px;
      top: 14px;
      content: "";
      width: 120px;
      height: 2px;
      background-color: #000000; }
      @media only screen and (max-width: 767px) {
        .slider-content-2 h3::before {
          display: none; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .slider-content-2 h3::before {
          display: block;
          left: 80px;
          width: 60px; } }
    .slider-content-2 h3.no-style {
      margin-left: 0; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .slider-content-2 h3.no-style {
          margin-left: 0; } }
      .slider-content-2 h3.no-style:before {
        display: none; }
  .slider-content-2 h1 {
    font-size: 72px;
    line-height: 97px;
    color: #010101;
    margin: 6px 0 37px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .slider-content-2 h1 {
        font-size: 60px;
        line-height: 80px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider-content-2 h1 {
        font-size: 56px;
        line-height: 70px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider-content-2 h1 {
        font-size: 45px;
        line-height: 60px; } }
    @media only screen and (max-width: 767px) {
      .slider-content-2 h1 {
        font-size: 35px;
        line-height: 45px; } }
  .slider-content-2 .slider-btn a {
    font-size: 16px;
    color: #010101;
    display: inline-block;
    border: 1px solid #333;
    text-transform: uppercase;
    line-height: 1;
    padding: 19px 50px 21px;
    background-color: #eeeff1; }
    @media only screen and (max-width: 767px) {
      .slider-content-2 .slider-btn a {
        padding: 14px 30px 16px; } }
    .slider-content-2 .slider-btn a:hover {
      border: 1px solid darkturquoise;
      color: #fff; }
  .slider-content-2 .slider-btn--style2 a {
    background-color: transparent; }
  .slider-content-2.slider-content-fruits h3 {
    color: #6eab49; }
    .slider-content-2.slider-content-fruits h3::before {
      background-color: #6eab49; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .slider-content-2.slider-content-fruits h3::before {
          left: 45px; } }
  .slider-content-2.slider-content-fruits h1 {
    font-size: 71px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .slider-content-2.slider-content-fruits h1 {
        font-size: 55px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider-content-2.slider-content-fruits h1 {
        font-size: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider-content-2.slider-content-fruits h1 {
        font-size: 40px; } }
    @media only screen and (max-width: 767px) {
      .slider-content-2.slider-content-fruits h1 {
        font-size: 30px;
        margin: 6px 0 20px; } }
  .slider-content-2.slider-content-fruits .slider-btn a {
    font-size: 16px;
    color: #fff;
    border: 1px solid #6eab49;
    background-color: #6eab49; }
    .slider-content-2.slider-content-fruits .slider-btn a:hover {
      border: 1px solid darkturquoise;
      color: #fff; }
  .slider-content-2.slider-content-fruits--style2 h3 {
    color: #ab87cd; }
    .slider-content-2.slider-content-fruits--style2 h3:before {
      background-color: #ab87cd; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .slider-content-2.slider-content-fruits--style2 h3:before {
          left: 45px; } }
  .slider-content-2.slider-content-fruits--style2 .slider-btn a {
    border: 1px solid #ab87cd;
    background-color: #ab87cd; }
  .slider-content-2--style2 h1 {
    margin-bottom: 5px; }
  .slider-content-2--style2 p {
    font-size: 17px;
    font-weight: 400;
    color: #73848e;
    margin-bottom: 50px; }
  .slider-content-2--style2 .slider-btn a {
    background-color: transparent; }
  .slider-content-2--style2--white h1 {
    color: #ffffff; }
  .slider-content-2--style2--white h3 {
    color: #ffffff; }
  .slider-content-2--style2--white .slider-btn a {
    color: #ffffff;
    border-color: #ffffff; }
  .slider-content-2--white h1 {
    color: #ffffff; }
  .slider-content-2--white h3 {
    color: #ffffff; }
  .slider-content-2--white p {
    color: #ffffff; }
  .slider-content-2--white .slider-btn a {
    color: #ffffff;
    background-color: transparent;
    border-color: #ffffff; }

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-slider-2 {
    background-position: 50%; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-2 {
    background-position: 42%; } }

@media only screen and (max-width: 767px) {
  .single-slider-2 {
    background-position: 30%; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-slider-2 {
    background-position: 22%; } }

/* home 3 */
.slider-height-2 {
  height: 800px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-height-2 {
      height: 570px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-2 {
      height: 500px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-2 {
      height: 500px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-2 {
      height: 400px; } }
  .slider-height-2.slider-content-center {
    display: flex;
    align-items: center; }

.slider-content-3 h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  position: relative;
  display: inline-block; }
  .slider-content-3 h3::before {
    position: absolute;
    left: -110px;
    top: 16px;
    content: "";
    width: 80px;
    height: 2px;
    background-color: #000000; }
  .slider-content-3 h3::after {
    position: absolute;
    right: -110px;
    top: 16px;
    content: "";
    width: 80px;
    height: 2px;
    background-color: #000000; }

.slider-content-3 h1 {
  font-size: 72px;
  line-height: 56px;
  margin: 28px 0 23px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-3 h1 {
      font-size: 52px;
      line-height: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-3 h1 {
      font-size: 52px;
      line-height: 40px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-3 h1 {
      font-size: 35px;
      line-height: 30px;
      margin: 18px 0 13px; } }

.slider-content-3 p {
  font-size: 18px;
  color: #010101;
  margin: 0 0 44px; }

.slider-content-3 .slider-btn a {
  font-size: 16px;
  color: #010101;
  display: inline-block;
  border: 1px solid #333;
  text-transform: uppercase;
  line-height: 1;
  padding: 19px 50px 21px; }
  .slider-content-3 .slider-btn a:hover {
    border: 1px solid darkturquoise;
    color: #fff; }

.slider-content-3.slider-content-4 {
  margin-left: 112px; }
  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .slider-content-3.slider-content-4 {
      margin-left: 30px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-content-3.slider-content-4 {
      margin-left: 0px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-3.slider-content-4 {
      margin-left: 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-3.slider-content-4 {
      margin-left: 0px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-3.slider-content-4 {
      margin-left: 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-3.slider-content-4 h1 {
      font-size: 34px;
      line-height: 35px;
      margin: 15px 0 15px; } }
  .slider-content-3.slider-content-4 h3:before {
    display: none; }
  .slider-content-3.slider-content-4 h3:after {
    display: none; }

/* slider hm4 */
.slider-height-3 {
  height: 710px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-height-3 {
      height: 570px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-3 {
      height: 500px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-3 {
      height: 500px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-3 {
      height: 400px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-3.slider-height-res-hm4 {
      height: 680px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-height-3.slider-height-res-hm4 {
      height: 400px; } }

.single-slider-img4 {
  position: relative;
  margin: 0 -77px 0 50px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .single-slider-img4 {
      margin: 0 0px 0 0px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-slider-img4 {
      margin: 0 0px 0 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-slider-img4 {
      margin: 0 0px 0 0px; } }
  @media only screen and (max-width: 767px) {
    .single-slider-img4 {
      margin: 30px 0 0; } }
  .single-slider-img4 .single-price-wrap {
    position: absolute;
    top: 0;
    right: -24px;
    animation-iteration-count: infinite;
    animation-name: bounce-img;
    animation-duration: 2.9s;
    width: 130px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .single-slider-img4 .single-price-wrap {
        right: 0px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-slider-img4 .single-price-wrap {
        right: 0px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-slider-img4 .single-price-wrap {
        right: 0px; } }
    @media only screen and (max-width: 767px) {
      .single-slider-img4 .single-price-wrap {
        right: 0px; } }
    .single-slider-img4 .single-price-wrap img {
      width: 100%; }
    .single-slider-img4 .single-price-wrap .single-price {
      position: absolute;
      top: 0;
      left: 50%;
      margin-top: 26px;
      transform: translateX(-50%);
      text-align: center; }
      .single-slider-img4 .single-price-wrap .single-price span {
        display: block;
        line-height: 1;
        font-size: 24px;
        color: #915342;
        font-weight: 600; }
        .single-slider-img4 .single-price-wrap .single-price span.dollar {
          margin-top: 2px; }

/* slider 5 */
.slider-height-4 {
  height: 750px; }
  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .slider-height-4 {
      height: 600px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-height-4 {
      height: 600px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-4 {
      height: 550px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-4 {
      height: 530px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-4 {
      height: 500px; } }

.slider-content-5 {
  padding-top: 66px; }
  .slider-content-5 h3 {
    font-weight: 500;
    letter-spacing: .5px;
    font-size: 30px;
    margin: 0;
    color: #fff; }
  .slider-content-5 h1 {
    font-weight: 500;
    font-size: 72px;
    margin: 27px 0 22px;
    letter-spacing: .5px;
    color: #fff;
    line-height: 56px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider-content-5 h1 {
        font-size: 58px;
        margin: 17px 0 20px; } }
    @media only screen and (max-width: 767px) {
      .slider-content-5 h1 {
        font-size: 45px;
        margin: 15px 0 15px;
        line-height: 38px; } }
  .slider-content-5 p {
    font-size: 18px;
    color: #fff;
    margin: 0 0 45px; }

.slider-btn-5 a {
  font-size: 16px;
  color: #010101;
  background-color: #fff;
  display: inline-block;
  line-height: 1;
  padding: 17px 40px 18px;
  transition: all .3s ease-in-out 0s; }
  .slider-btn-5 a:hover {
    border: none;
    color: #fff; }

.nav-style-2.owl-carousel > .owl-nav div, .nav-style-2 .ht-swiper-button-nav {
  color: #000;
  display: inline-block;
  font-size: 60px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  width: 65px;
  height: 84px;
  line-height: 94px;
  padding: 0;
  background: none;
  border: none;
  background-color: #fff; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .nav-style-2.owl-carousel > .owl-nav div, .nav-style-2 .ht-swiper-button-nav {
      width: 45px;
      height: 64px;
      line-height: 74px;
      font-size: 45px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .nav-style-2.owl-carousel > .owl-nav div, .nav-style-2 .ht-swiper-button-nav {
      width: 45px;
      height: 64px;
      line-height: 74px;
      font-size: 45px; } }
  @media only screen and (max-width: 767px) {
    .nav-style-2.owl-carousel > .owl-nav div, .nav-style-2 .ht-swiper-button-nav {
      width: 35px;
      height: 50px;
      line-height: 54px;
      font-size: 30px; } }
  .nav-style-2.owl-carousel > .owl-nav div:hover, .nav-style-2 .ht-swiper-button-nav:hover {
    color: #fff;
    background-color: darkturquoise; }
  .nav-style-2.owl-carousel > .owl-nav div.owl-next, .nav-style-2 .ht-swiper-button-nav.owl-next {
    left: auto;
    right: 0px; }
  .nav-style-2.owl-carousel > .owl-nav div.swiper-button-prev, .nav-style-2 .ht-swiper-button-nav.swiper-button-prev {
    left: 0; }
  .nav-style-2.owl-carousel > .owl-nav div.swiper-button-next, .nav-style-2 .ht-swiper-button-nav.swiper-button-next {
    right: 0; }

.slider-area:hover .nav-style-2.owl-carousel > .owl-nav div, .slider-area:hover .nav-style-2 .ht-swiper-button-nav {
  opacity: 1;
  visibility: visible; }

.slider-area.ml-10 {
  margin-left: 0; }

/* slider 6 hm7 */
.slider-height-5 {
  height: 850px; }
  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .slider-height-5 {
      height: 600px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-height-5 {
      height: 600px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-5 {
      height: 550px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-5 {
      height: 530px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-5 {
      height: 500px; } }

.slider-content-6 h1 {
  font-weight: 500;
  font-size: 60px;
  letter-spacing: 8px;
  color: #fff;
  margin: 0px;
  line-height: 48px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-6 h1 {
      font-size: 45px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-6 h1 {
      font-size: 45px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-6 h1 {
      font-size: 30px;
      line-height: 38px; } }

.slider-content-6 p {
  margin: 32px 0 35px;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 1px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-6 p {
      margin: 22px 0 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-6 p {
      margin: 22px 0 30px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-6 p {
      margin: 18px 0 25px; } }

.nav-style-3.owl-carousel > .owl-nav, .nav-style-3 .ht-swiper-button-nav {
  position: absolute;
  right: 0;
  bottom: 0;
  left: auto;
  top: auto;
  color: #000;
  border: none;
  display: inline-block;
  font-size: 60px;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 65px;
  height: 84px;
  line-height: 94px;
  background-color: #fff; }
  .nav-style-3.owl-carousel > .owl-nav.swiper-button-prev, .nav-style-3 .ht-swiper-button-nav.swiper-button-prev {
    right: 50px; }
  @media only screen and (max-width: 767px) {
    .nav-style-3.owl-carousel > .owl-nav, .nav-style-3 .ht-swiper-button-nav {
      width: 50px;
      height: 60px;
      font-size: 45px;
      line-height: 67px; } }
  .nav-style-3.owl-carousel > .owl-nav:hover, .nav-style-3 .ht-swiper-button-nav:hover {
    background-color: darkturquoise;
    color: #fff; }

/* slider hm8 */
.slider-height-6 {
  height: 543px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-6 {
      height: 500px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-6 {
      height: 400px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-6 {
      height: 400px; } }

.slider-hm8 .slider-content-5 {
  padding-top: 0px; }

.slider-hm8 p {
  margin: 0 0 0px; }

/* slider hm9 */
.slider-height-7 {
  height: 950px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-7 {
      height: 800px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-7 {
      height: 600px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-7 {
      height: auto;
      padding: 130px 0 100px; } }

.slider-h9-mrg {
  margin-top: -35px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-h9-mrg {
      margin-top: 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-h9-mrg {
      margin-top: 30px; } }
  @media only screen and (max-width: 767px) {
    .slider-h9-mrg {
      margin-top: 0px; } }

@media only screen and (max-width: 767px) {
  .slider-content-7 {
    text-align: left;
    width: 180px;
    max-width: 100%;
    margin-left: 30px; } }

.slider-content-7 h3 {
  font-size: 16px;
  font-weight: 500;
  color: #313131;
  margin: 0;
  letter-spacing: .3px;
  line-height: 1; }

.slider-content-7 h1 {
  font-size: 48px;
  font-weight: 500;
  color: #313131;
  margin: 17px 0 37px;
  line-height: 65px;
  letter-spacing: 1px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-7 h1 {
      font-size: 42px;
      line-height: 60px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-7 h1 {
      font-size: 32px;
      margin: 6px 0 22px;
      line-height: 50px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-7 h1 {
      font-size: 22px;
      margin: 6px 0 22px;
      line-height: 38px; } }

.slider-content-7 .slider-btn-9 a {
  font-size: 16px;
  color: #010101;
  display: inline-block;
  border: 1px solid #333;
  text-transform: uppercase;
  line-height: 1;
  padding: 19px 50px 19px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-7 .slider-btn-9 a {
      padding: 14px 30px 14px;
      font-size: 15px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-7 .slider-btn-9 a {
      padding: 15px 30px 15px;
      font-size: 15px; } }
  .slider-content-7 .slider-btn-9 a:hover {
    color: #fff;
    border: 1px solid darkturquoise; }

.slider-content-7.slider-content-mrg-hm17 {
  margin-top: 127px;
  width: 590px;
  max-width: 100%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-7.slider-content-mrg-hm17 {
      width: 390px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-7.slider-content-mrg-hm17 {
      width: 290px;
      margin-top: 0; } }
  @media only screen and (max-width: 767px) {
    .slider-content-7.slider-content-mrg-hm17 {
      margin-top: 0; } }

@media only screen and (max-width: 767px) {
  .slider-singleimg-hm9 {
    margin-top: 30px; }
    .slider-singleimg-hm9.ml-100 {
      margin-left: 20px; }
    .slider-singleimg-hm9.mr-100 {
      margin-right: 20px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-singleimg-hm9 {
    margin-top: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-singleimg-hm9.ml-100 {
    margin-left: 40px; }
  .slider-singleimg-hm9.mr-100 {
    margin-right: 40px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-singleimg-hm9.ml-100 {
    margin-left: 70px; }
  .slider-singleimg-hm9.mr-100 {
    margin-right: 70px; } }

/* slider hm10 */
.slider-height-8 {
  height: 700px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-height-8 {
      height: 650px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-8 {
      height: 520px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-8 {
      height: 450px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-8 {
      height: auto;
      padding: 60px 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-singleimg-hm10.ml-40 {
    margin-left: 0; }
  .slider-singleimg-hm10.mr-40 {
    margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .slider-singleimg-hm10.ml-40 {
    margin-left: 0; }
  .slider-singleimg-hm10.mr-40 {
    margin-right: 0; } }

/* home 11 */
.slider-height-9 {
  height: 780px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-height-9 {
      height: 700px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-9 {
      height: 630px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-9 {
      height: 500px; } }
  @media only screen and (max-width: 767px) {
    .slider-height-9 {
      height: auto;
      padding: 130px 0 70px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-height-9 {
      padding: 130px 0 70px; } }

.slider-content-11 h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  letter-spacing: 1px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-11 h3 {
      font-size: 20px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-11 h3 {
      font-size: 20px; } }

.slider-content-11 h1 {
  font-size: 72px;
  margin: 0;
  line-height: 97px;
  color: #010101;
  margin: 15px 0 38px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-11 h1 {
      margin: 5px 0 27px;
      font-size: 52px;
      line-height: 65px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-11 h1 {
      margin: 3px 0 20px;
      font-size: 45px;
      line-height: 55px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-11 h1 {
      margin: 3px 0 20px;
      font-size: 40px;
      line-height: 50px; } }

.slider-content-11 .slider-btn-11 a {
  font-size: 16px;
  color: #010101;
  display: inline-block;
  border: 1px solid #333;
  text-transform: uppercase;
  line-height: 1;
  padding: 19px 50px 19px; }
  .slider-content-11 .slider-btn-11 a:hover {
    border: 1px solid #a749ff;
    color: #fff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-11 .slider-btn-11 a {
      padding: 15px 35px 15px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-11 .slider-btn-11 a {
      padding: 12px 30px 12px;
      font-size: 14px; } }

@media only screen and (max-width: 767px) {
  .slider-content-11 {
    text-align: center; } }

@media only screen and (max-width: 767px) {
  .slider-content-11.fullpage-content {
    text-align: center;
    width: 100%; }
    .slider-content-11.fullpage-content h3 {
      font-size: 16px;
      letter-spacing: 0; }
    .slider-content-11.fullpage-content h1 {
      font-size: 30px;
      line-height: 40px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-11.fullpage-content h3 {
    font-size: 17px;
    letter-spacing: 0; }
  .slider-content-11.fullpage-content h1 {
    font-size: 35px; } }

.slider-singleimg-hm11 {
  margin: 0 22px 0 28px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-singleimg-hm11 {
      margin: 0 50px 0 60px; } }
  @media only screen and (max-width: 767px) {
    .slider-singleimg-hm11 {
      margin: 30px 0px 0 0px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-singleimg-hm11 {
      margin: 0px 0px 0 0px; } }

.slider-h11-mrg {
  margin-bottom: -140px; }
  @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-h11-mrg {
      margin-bottom: 0; } }

/* home 12 */
#section2 .fp-tableCell, #section4 .fp-tableCell {
  display: flex;
  align-items: center; }

#section1 .fp-tableCell, #section3 .fp-tableCell {
  display: flex;
  align-items: flex-end; }

#section5 .fp-tableCell {
  height: auto !important; }

#section5 {
  height: auto !important; }

#fp-nav {
  top: 55%; }
  #fp-nav ul li {
    margin: 7px 7px 10px; }
    #fp-nav ul li a span {
      background: #000; }
  #fp-nav.right {
    right: 64px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      #fp-nav.right {
        right: 10px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      #fp-nav.right {
        right: 25px; } }

.fullpage-slider-mrg {
  margin-top: -34px; }
  @media only screen and (max-width: 767px) {
    .fullpage-slider-mrg {
      margin-top: 0;
      margin-bottom: 30px; } }

.fullpage-slider-mrg-2 {
  margin-bottom: -88px; }
  @media only screen and (max-width: 767px) {
    .fullpage-slider-mrg-2 {
      margin-bottom: 30px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .fullpage-slider-mrg-2 {
      margin-bottom: -50px; } }

.slider-section.active .slider-animated-1 h3 {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.slider-section.active .slider-animated-1 h1 {
  -webkit-animation-delay: 1.0s;
  animation-delay: 1.0s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.slider-section.active .slider-animated-1 a {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.slider-section.active .slider-animated-1 img {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@media only screen and (max-width: 767px) {
  .slider12-img-2 {
    margin-top: 30px;
    margin-bottom: 30px; } }

.slider12-img-2 img {
  width: 100%; }

.slider12-img-1 {
  margin: 0 4px 0 6px; }
  .slider12-img-1 img {
    width: 100%; }

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider12-img-1 {
    text-align: center; }
    .slider12-img-1 img {
      width: 350px; } }

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider12-img-1 {
    text-align: center; }
    .slider12-img-1 img {
      width: 350px; }
  .slider12-img-2 {
    text-align: center; }
    .slider12-img-2 img {
      width: 420px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider12-img-1 {
    text-align: center; }
    .slider12-img-1 img {
      width: 330px; } }

@media only screen and (max-width: 767px) {
  .fullpage-wrapper {
    padding-top: 130px; } }
  @media only screen and (max-width: 767px) and (min-width: 576px) and (max-width: 767px) {
    .fullpage-wrapper {
      padding-top: 0px; } }

@media only screen and (max-width: 767px) {
  .fullpage-slider-wrap-mrg {
    margin-bottom: 30px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .fullpage-slider-wrap-mrg {
    margin-bottom: 0px; } }

/* home 13 */
.slider-content-13 h5 {
  letter-spacing: 1px;
  font-size: 24px;
  font-weight: 500;
  margin: 0; }
  @media only screen and (max-width: 767px) {
    .slider-content-13 h5 {
      font-size: 22px; } }

.slider-content-13 h1 {
  font-size: 120px;
  font-weight: 600;
  line-height: 120px;
  margin: 34px 0 55px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-13 h1 {
      font-size: 90px;
      line-height: 90px;
      margin: 24px 0 35px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-13 h1 {
      font-size: 80px;
      line-height: 90px;
      margin: 20px 0 30px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-13 h1 {
      font-size: 40px;
      line-height: 50px;
      margin: 15px 0 20px; } }

.slider-content-13 .slider-btn a {
  font-size: 16px;
  color: #010101;
  display: inline-block;
  border: 1px solid #333;
  text-transform: uppercase;
  line-height: 1;
  padding: 19px 50px 19px; }
  .slider-content-13 .slider-btn a:hover {
    color: #fff;
    border: 1px solid #a749ff; }

@media only screen and (max-width: 767px) {
  .hm-13-slider {
    background-position: 72%; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hm-13-slider {
    background-position: 55%; } }

.slider-single-img-14 {
  padding-bottom: 72px;
  margin: 0 23px;
  position: relative;
  z-index: 9; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-single-img-14 {
      padding-bottom: 60px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-single-img-14 {
      padding-bottom: 44px; } }
  @media only screen and (max-width: 767px) {
    .slider-single-img-14 {
      padding-bottom: 0px;
      margin: 0 0px;
      text-align: center; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-single-img-14 {
      padding-bottom: 38px;
      text-align: inherit; } }
  .slider-single-img-14 img {
    width: 100%; }

.slider-height-14 {
  height: 850px;
  padding: 89px 0 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height-14 {
      height: 715px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height-14 {
      height: 525px;
      padding: 70px 0 0; } }
  @media only screen and (max-width: 767px) {
    .slider-height-14 {
      height: auto;
      padding: 120px 0 0; } }

.slider-content-14 {
  margin: -90px 0 0; }
  @media only screen and (max-width: 767px) {
    .slider-content-14 {
      margin: 0px 0 30px;
      text-align: center; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-content-14 {
      margin: 0px 0 30px;
      text-align: left; } }
  .slider-content-14 h3 {
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 0 150px;
    position: relative;
    letter-spacing: .8px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider-content-14 h3 {
        font-size: 20px; } }
    @media only screen and (max-width: 767px) {
      .slider-content-14 h3 {
        font-size: 20px;
        margin: 0 0 0 100px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .slider-content-14 h3 {
        margin: 0 0 0 60px; } }
    .slider-content-14 h3::before {
      position: absolute;
      left: -135px;
      top: 14px;
      content: "";
      width: 120px;
      height: 2px;
      background-color: #fff; }
      @media only screen and (max-width: 767px) {
        .slider-content-14 h3::before {
          left: -100px;
          width: 80px; } }
      @media only screen and (max-width: 767px) {
        .slider-content-14 h3::before {
          left: -60px;
          width: 50px; } }
  .slider-content-14 h1 {
    color: #fff;
    font-size: 72px;
    line-height: 97px;
    margin: 14px 0 38px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider-content-14 h1 {
        font-size: 55px;
        line-height: 75px;
        margin: 10px 0 25px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider-content-14 h1 {
        font-size: 42px;
        line-height: 60px;
        margin: 10px 0 25px; } }
    @media only screen and (max-width: 767px) {
      .slider-content-14 h1 {
        font-size: 33px;
        line-height: 50px;
        margin: 5px 0 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .slider-content-14 h1 {
        font-size: 30px;
        line-height: 40px; } }
  .slider-content-14 .slider-btn a {
    font-size: 16px;
    color: #010101;
    display: inline-block;
    text-transform: uppercase;
    line-height: 1;
    padding: 20px 50px 19px;
    border: none;
    background-color: #eeeff1;
    transition: all .4s ease-in-out 0s;
    z-index: 1; }
    @media only screen and (max-width: 767px) {
      .slider-content-14 .slider-btn a {
        padding: 16px 30px 15px;
        font-size: 15px; } }
    .slider-content-14 .slider-btn a:hover {
      color: #fff; }

.slider-content-15 h1 {
  color: #fff;
  font-size: 90px;
  font-family: "Abril Fatface", cursive;
  line-height: 115px;
  letter-spacing: 1px;
  margin: 0; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .slider-content-15 h1 {
      font-size: 75px;
      line-height: 100px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-15 h1 {
      font-size: 70px;
      line-height: 85px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-15 h1 {
      font-size: 60px;
      line-height: 85px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-15 h1 {
      font-size: 38px;
      line-height: 55px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-content-15 h1 {
      font-size: 42px;
      line-height: 65px; } }

.slider-content-15.slider15-mrg-nrg h1 {
  margin-top: -26px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-15.slider15-mrg-nrg h1 {
      margin-top: -18px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-15.slider15-mrg-nrg h1 {
      margin-top: -20px; } }

.slider-content-15 .dealy-style-2 {
  margin: 49px 0 43px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content-15 .dealy-style-2 {
      margin: 40px 0 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content-15 .dealy-style-2 {
      margin: 30px 0 35px; } }
  @media only screen and (max-width: 767px) {
    .slider-content-15 .dealy-style-2 {
      margin: 20px 0 5px; } }
  .slider-content-15 .dealy-style-2 span {
    display: inline-block;
    font-size: 48px;
    font-weight: 300;
    color: #fff;
    margin: 0 34px 0; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider-content-15 .dealy-style-2 span {
        margin: 0 25px 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider-content-15 .dealy-style-2 span {
        margin: 0 20px 0;
        font-size: 40px; } }
    @media only screen and (max-width: 767px) {
      .slider-content-15 .dealy-style-2 span {
        margin: 0 20px 20px;
        font-size: 30px; } }
    .slider-content-15 .dealy-style-2 span p {
      font-size: 18px;
      font-weight: 500;
      margin: 33px 0 0;
      color: #fff;
      text-transform: uppercase; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slider-content-15 .dealy-style-2 span p {
          margin: 20px 0 0; } }
      @media only screen and (max-width: 767px) {
        .slider-content-15 .dealy-style-2 span p {
          margin: 10px 0 0; } }

.slider-content-15 .slider-btn-12 a {
  display: inline-block;
  background-color: #e90042;
  border: 1px solid #e90042;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
  font-size: 16px;
  padding: 17px 47px 19px;
  z-index: 1; }
  @media only screen and (max-width: 767px) {
    .slider-content-15 .slider-btn-12 a {
      padding: 13px 30px 15px; } }
  .slider-content-15 .slider-btn-12 a:hover {
    border: 1px solid darkturquoise; }

.slider-height-18 {
  height: 460px; }
  @media only screen and (max-width: 767px) {
    .slider-height-18 {
      background-position: 85%; } }
  @media only screen and (max-width: 767px) {
    .slider-height-18.slider-overly-res {
      height: 400px;
      position: relative; }
      .slider-height-18.slider-overly-res:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: #fff;
        opacity: .5; } }

.slider-banner-area {
  margin-top: 163px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-banner-area {
      margin-top: 0; } }
  @media only screen and (max-width: 767px) {
    .slider-banner-area {
      margin-top: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-mrg-md-mb {
    margin-bottom: 30px; } }

@media only screen and (max-width: 767px) {
  .res-mrg-md-mb {
    margin-bottom: 30px; } }

.ht-swiper-button-nav:after {
  font-size: 0; }

.slider-active-3 .ht-swiper-button-nav {
  background: none;
  border: none;
  padding: 0;
  font-size: 40px;
  color: #333;
  visibility: hidden;
  opacity: 0; }

.slider-active-3:hover .ht-swiper-button-nav {
  visibility: visible;
  opacity: 1; }

/*-------- 4. Support style  -------*/
.support-wrap {
  display: flex; }
  .support-wrap .support-icon {
    flex: 0 0 50px;
    margin-right: 20px; }
    .support-wrap .support-icon img {
      width: 100%; }
  .support-wrap .support-content h5 {
    font-size: 18px;
    margin: 0 0 5px; }
  .support-wrap .support-content p {
    color: #666;
    margin: 0; }
  .support-wrap.support-2 {
    margin-left: 22px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .support-wrap.support-2 {
        margin-left: 0px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .support-wrap.support-2 {
        margin-left: 0px; } }
    @media only screen and (max-width: 767px) {
      .support-wrap.support-2 {
        margin-left: 0px; } }
    .support-wrap.support-2 .support-icon {
      flex: 0 0 41px; }
  .support-wrap.support-3 {
    margin-left: 9px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .support-wrap.support-3 {
        margin-left: 0px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .support-wrap.support-3 {
        margin-left: 0px; } }
    @media only screen and (max-width: 767px) {
      .support-wrap.support-3 {
        margin-left: 0px; } }
    .support-wrap.support-3 .support-icon {
      flex: 0 0 41px; }
  .support-wrap.support-4 {
    margin-left: 23px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .support-wrap.support-4 {
        margin-left: 0px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .support-wrap.support-4 {
        margin-left: 0px; } }
    @media only screen and (max-width: 767px) {
      .support-wrap.support-4 {
        margin-left: 0px; } }
    .support-wrap.support-4 .support-icon {
      flex: 0 0 35px; }
  .support-wrap:hover .support-icon img {
    animation: 500ms ease-in-out 0s normal none 1 running tada; }

/* support hm3 */
.support-wrap-2 .support-content-2 h5 {
  font-size: 18px;
  color: #494949;
  margin: 15px 0 15px;
  line-height: 24px; }

.support-wrap-2 .support-content-2 p {
  font-size: 14px;
  color: #6a6a6a;
  margin: 0 auto;
  width: 80%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .support-wrap-2 .support-content-2 p {
      width: 100%; } }

.support-wrap-2:hover .support-content-2 img {
  animation: 500ms ease-in-out 0s normal none 1 running tada; }

.support-wrap-2.support-shape {
  position: relative; }
  .support-wrap-2.support-shape:before {
    position: absolute;
    right: -14px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #d5d4d4;
    height: 61px;
    width: 1px;
    content: ""; }
    @media only screen and (max-width: 767px) {
      .support-wrap-2.support-shape:before {
        display: none; } }

.support-wrap-2.support-shape-2 {
  border: 4px solid #fff; }

.support-wrap-2.support-shape-3 {
  border: 4px solid #f6f6f6; }

.support-wrap-2.support-padding-2 {
  padding: 36px 10px 30px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .support-wrap-2.support-padding-2 {
      padding: 36px 5px 30px; } }
  @media only screen and (max-width: 767px) {
    .support-wrap-2.support-padding-2 {
      padding: 36px 6px 30px; } }

.support-bg-color-1 {
  background-color: #ccfbe9; }

.support-bg-color-2 {
  background-color: #f2fbcc; }

.support-bg-color-3 {
  background-color: #ddfbcc; }

.support-wrap-3 {
  position: relative;
  padding: 30px 10px 24px;
  overflow: hidden; }
  .support-wrap-3 .support-icon-2 {
    position: absolute;
    right: 0;
    bottom: 0; }
  .support-wrap-3 .support-content-3 p {
    color: #000000;
    margin: 4px 0 0;
    font-size: 16px;
    text-transform: uppercase; }
    @media only screen and (max-width: 767px) {
      .support-wrap-3 .support-content-3 p {
        font-size: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .support-wrap-3 .support-content-3 p {
        font-size: 14px; } }
  .support-wrap-3:hover .support-icon-2 img {
    animation: 500ms ease-in-out 0s normal none 1 running tada; }

@media only screen and (max-width: 767px) {
  .support-area.pt-80 {
    padding-top: 30px; } }

.feature-icon-two-wrap .col-md-4:last-child .support-wrap-2.support-shape:last-child:before {
  display: none; }

/*------- 5. Section title style  -------*/
@media only screen and (max-width: 767px) {
  .section-title.mb-50 {
    margin-bottom: 30px; } }

.section-title h2 {
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 0;
  position: relative;
  display: inline-block; }
  @media only screen and (max-width: 767px) {
    .section-title h2 {
      font-size: 24px;
      line-height: 30px; }
      .section-title h2.mb-50 {
        margin-bottom: 30px; } }
  .section-title h2::before {
    position: absolute;
    content: "";
    left: -100px;
    background-color: #000;
    width: 80px;
    height: 2px;
    top: 17px; }
    @media only screen and (max-width: 767px) {
      .section-title h2::before {
        left: -40px;
        width: 30px; } }
  .section-title h2::after {
    position: absolute;
    content: "";
    right: -100px;
    background-color: #000;
    width: 80px;
    height: 2px;
    top: 17px; }
    @media only screen and (max-width: 767px) {
      .section-title h2::after {
        right: -40px;
        width: 30px; } }

@media only screen and (max-width: 767px) {
  .section-title.mb-55 {
    margin-bottom: 30px; } }

.section-title p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px; }

.section-title.no-border h2 {
  font-size: 36px; }
  .section-title.no-border h2:before, .section-title.no-border h2:after {
    display: none; }

.section-title-2 h2 {
  font-size: 30px;
  color: #000000;
  font-weight: 600;
  margin: 0 0 15px;
  padding: 0 0 15px;
  position: relative;
  display: inline-block; }
  .section-title-2 h2:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    width: 80px;
    height: 2px;
    background-color: #000000; }

.section-title-2 p {
  color: #333;
  margin: 0; }

@media only screen and (max-width: 767px) {
  .section-title-2.mb-60 {
    margin-bottom: 30px; } }

.section-title-3 h4 {
  font-weight: 500;
  color: #000000;
  font-size: 24px; }

@media only screen and (max-width: 767px) {
  .section-title-3.mb-40 {
    margin-bottom: 20px; } }

.section-border {
  margin-right: 94px;
  overflow: hidden;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .section-border {
      margin-right: 0px; } }
  .section-border::before {
    background-color: #d2d2d2;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 11px;
    transition: all 0.4s ease 0s;
    width: 100%;
    z-index: 1; }
    @media only screen and (max-width: 767px) {
      .section-border::before {
        display: none; } }

.section-title-4 {
  position: relative;
  z-index: 999; }
  .section-title-4 h3 {
    font-weight: 600;
    color: #242424;
    font-size: 20px;
    letter-spacing: -0.25px;
    margin: 0;
    line-height: 1;
    padding-right: 45px; }
    @media only screen and (max-width: 767px) {
      .section-title-4 h3 {
        padding-right: 0px; } }

.section-title-5 h2 {
  padding-top: 60px;
  color: #010101;
  font-size: 30px;
  margin: 0;
  line-height: 38px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title-5 h2 {
      font-size: 40px; } }
  @media only screen and (max-width: 767px) {
    .section-title-5 h2 {
      font-size: 30px;
      line-height: 27px; } }

@media only screen and (max-width: 767px) {
  .section-title-5.mb-60 {
    margin-bottom: 30px; } }

/*------- 6. Product style  --------*/
.product-area {
  position: relative; }
  .product-area .row.three-column .col-xl-3 {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
    transition: 0.5s; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .product-area .row.three-column .col-xl-3 {
        flex: 1 0 33.3333%;
        max-width: 33.3333%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .product-area .row.three-column .col-xl-3 {
        flex: 1 0 50%;
        max-width: 50%; } }
    @media only screen and (max-width: 767px) {
      .product-area .row.three-column .col-xl-3 {
        flex: 1 0 100%;
        max-width: 100%; } }
  .product-area .row.five-column .col-xl-3 {
    flex: 1 0 20%;
    max-width: 20%;
    transition: 0.5s; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .product-area .row.five-column .col-xl-3 {
        flex: 1 0 33.3333%;
        max-width: 33.3333%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .product-area .row.five-column .col-xl-3 {
        flex: 1 0 50%;
        max-width: 50%; } }
    @media only screen and (max-width: 767px) {
      .product-area .row.five-column .col-xl-3 {
        flex: 1 0 100%;
        max-width: 100%; } }
  .product-area--style2 .swiper-slider-navigation-wrapper {
    width: 100%;
    text-align: center; }
  .product-area--style2 .ht-swiper-button-nav {
    position: absolute;
    top: 15px;
    right: 0;
    display: inline-block;
    width: 35px;
    height: 35px;
    font-size: 28px;
    padding: 0;
    line-height: 1;
    text-align: center;
    border-radius: 5px;
    color: #c6c6c6;
    border: 1px solid #d2d2d2;
    background-color: #f9f9f9;
    transition: all .3s ease 0s; }
    .product-area--style2 .ht-swiper-button-nav i {
      padding-top: 3px; }
    @media only screen and (max-width: 767px) {
      .product-area--style2 .ht-swiper-button-nav {
        margin-top: 15px; } }
    .product-area--style2 .ht-swiper-button-nav:hover {
      color: #fff;
      border: 1px solid darkturquoise;
      background-color: darkturquoise; }
    .product-area--style2 .ht-swiper-button-nav.swiper-button-prev {
      right: 40px;
      left: auto; }
      @media only screen and (max-width: 767px) {
        .product-area--style2 .ht-swiper-button-nav.swiper-button-prev {
          margin-right: 10px; } }
    @media only screen and (max-width: 767px) {
      .product-area--style2 .ht-swiper-button-nav {
        position: static;
        text-align: center; } }

.product-tab-list {
  justify-content: center; }
  @media only screen and (max-width: 767px) {
    .product-tab-list.pt-30, .product-tab-list.pt-55 {
      padding: 10px 0 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .product-tab-list.pt-35, .product-tab-list.pt-60 {
      padding: 20px 0 30px; } }
  @media only screen and (max-width: 767px) {
    .product-tab-list.pt-35, .product-tab-list.pt-60 {
      padding: 10px 0 10px; } }
  .product-tab-list a {
    margin: 0 11px; }
    .product-tab-list a.nav-link {
      background-color: transparent !important; }
    @media only screen and (max-width: 767px) {
      .product-tab-list a {
        margin: 0 11px 5px; } }
    .product-tab-list a h4 {
      font-weight: 500;
      font-size: 18px;
      color: #555;
      margin: 0;
      transition: all .3s ease 0s; }
      .product-tab-list a h4:hover {
        color: #000; }
    .product-tab-list a.active h4 {
      color: #000; }
  .product-tab-list.product-tab-fruits a h4 {
    color: #000000;
    border-bottom: 2px solid transparent; }
    .product-tab-list.product-tab-fruits a h4:hover {
      color: #6eab49; }
  .product-tab-list.product-tab-fruits a.active h4 {
    color: #6eab49;
    border-bottom: 2px solid #6eab49; }
  .product-tab-list.product-tab-pink a h4 {
    color: #000000;
    border-bottom: 2px solid transparent; }
    .product-tab-list.product-tab-pink a h4:hover {
      color: #e90042; }
  .product-tab-list.product-tab-pink a.active h4 {
    color: #e90042;
    border-bottom: 2px solid #e90042; }
  .product-tab-list.product-tab-pink2 a h4 {
    color: #000000;
    border-bottom: 2px solid transparent; }
    .product-tab-list.product-tab-pink2 a h4:hover {
      color: darkturquoise; }
  .product-tab-list.product-tab-pink2 a.active h4 {
    color: darkturquoise;
    border-bottom: 2px solid ed59a0e90042; }

.product-wrap, .product-list-image-wrap {
  position: relative;
  margin-bottom: 10px; }
  .product-wrap .product-img, .product-list-image-wrap .product-img {
    position: relative;
    overflow: hidden; }
    .product-wrap .product-img img, .product-list-image-wrap .product-img img {
      width: 100%; }
    .product-wrap .product-img-badges, .product-list-image-wrap .product-img-badges {
      position: absolute;
      top: 12px;
      right: 12px; }
      .product-wrap .product-img-badges span, .product-list-image-wrap .product-img-badges span {
        font-size: 13px;
        color: #fff;
        display: block;
        line-height: 1;
        padding: 3px 11px;
        border-radius: 3px;
        font-weight: 500;
        margin-bottom: 10px; }
        .product-wrap .product-img-badges span.pink, .product-list-image-wrap .product-img-badges span.pink {
          background-color: #fa6bff; }
        .product-wrap .product-img-badges span.purple, .product-list-image-wrap .product-img-badges span.purple {
          background-color: darkturquoise; }
        .product-wrap .product-img-badges span:last-child, .product-list-image-wrap .product-img-badges span:last-child {
          margin-bottom: 0; }
    .product-wrap .product-img .hover-img, .product-list-image-wrap .product-img .hover-img {
      position: absolute;
      top: 0;
      left: 50%;
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, 20px);
      transition-duration: .7s; }
    .product-wrap .product-img .product-action, .product-list-image-wrap .product-img .product-action {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100%;
      z-index: 9;
      transform: translateX(-50%);
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .product-wrap .product-img .product-action > div, .product-list-image-wrap .product-img .product-action > div {
        opacity: 0;
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        transform: translateY(20px);
        background-color: darkturquoise;
        transition: all 0.5s ease; }
      .product-wrap .product-img .product-action .pro-wishlist, .product-list-image-wrap .product-img .product-action .pro-wishlist {
        width: 48px;
        border-right: 1px solid rgba(255, 255, 255, 0.2); }
      .product-wrap .product-img .product-action .pro-cart, .product-list-image-wrap .product-img .product-action .pro-cart {
        font-size: 14px;
        width: calc(100% - 48px - 48px);
        transition-delay: .1s; }
      .product-wrap .product-img .product-action .pro-quickview, .product-list-image-wrap .product-img .product-action .pro-quickview {
        width: 328px;
        border-left: 1px solid rgba(21, 21, 21, 0.2);
        margin: 0;
        transition-delay: .2s; }
      .product-wrap .product-img .product-action .pro-same-action a, .product-wrap .product-img .product-action .pro-same-action button, .product-list-image-wrap .product-img .product-action .pro-same-action a, .product-list-image-wrap .product-img .product-action .pro-same-action button {
        color: #fff;
        font-size: 16px;
        height: 48px;
        line-height: 48px;
        display: block;
        width: 100%;
        text-align: center;
        text-transform: capitalize;
        background: none;
        border: none; }
        .product-wrap .product-img .product-action .pro-same-action a:hover, .product-wrap .product-img .product-action .pro-same-action a.active, .product-wrap .product-img .product-action .pro-same-action button:hover, .product-wrap .product-img .product-action .pro-same-action button.active, .product-list-image-wrap .product-img .product-action .pro-same-action a:hover, .product-list-image-wrap .product-img .product-action .pro-same-action a.active, .product-list-image-wrap .product-img .product-action .pro-same-action button:hover, .product-list-image-wrap .product-img .product-action .pro-same-action button.active {
          background-color: black; }
      .product-wrap .product-img .product-action .pro-same-action.pro-wishlist a, .product-wrap .product-img .product-action .pro-same-action.pro-quickview a, .product-list-image-wrap .product-img .product-action .pro-same-action.pro-wishlist a, .product-list-image-wrap .product-img .product-action .pro-same-action.pro-quickview a {
        margin-top: 2px; }
  .product-wrap .product-content, .product-list-image-wrap .product-content {
    margin: 20px 0 0; }
    .product-wrap .product-content h3, .product-wrap .product-content a, .product-list-image-wrap .product-content h3, .product-list-image-wrap .product-content a {
      font-size: 16px;
      margin: 0;
      color: #000; }
    .product-wrap .product-content .product-rating, .product-list-image-wrap .product-content .product-rating {
      margin: 3px 0 3px; }
      .product-wrap .product-content .product-rating i, .product-list-image-wrap .product-content .product-rating i {
        font-size: 17px;
        color: #5f5d5d;
        margin: 0 3px; }
        .product-wrap .product-content .product-rating i.yellow, .product-list-image-wrap .product-content .product-rating i.yellow {
          color: #ffa900; }
    .product-wrap .product-content .product-price span, .product-list-image-wrap .product-content .product-price span {
      font-size: 16px;
      color: #000;
      font-weight: 500;
      margin: 0 9px;
      position: relative; }
      .product-wrap .product-content .product-price span::before, .product-list-image-wrap .product-content .product-price span::before {
        position: absolute;
        content: "";
        right: -13px;
        top: 10px;
        width: 7px;
        height: 2px;
        background-color: #000; }
      .product-wrap .product-content .product-price span:last-child:before, .product-list-image-wrap .product-content .product-price span:last-child:before {
        display: none; }
      .product-wrap .product-content .product-price span.old, .product-list-image-wrap .product-content .product-price span.old {
        color: #8e8e8e;
        text-decoration: line-through; }
  .product-wrap:hover .hover-img, .product-list-image-wrap:hover .hover-img {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0); }
  .product-wrap:hover .info-observ, .product-list-image-wrap:hover .info-observ {
    visibility: visible; }
  .product-wrap:hover .product-action > div, .product-list-image-wrap:hover .product-action > div {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }

.modal-dialog {
  margin: 8% auto;
  max-width: 960px;
  width: 960px;
  padding: 35px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .modal-dialog {
      width: 720px;
      max-width: 720px; } }
  @media only screen and (max-width: 767px) {
    .modal-dialog {
      width: 100%;
      max-width: 100%;
      padding: 35px 0; } }
  .modal-dialog .modal-header .close {
    color: #333;
    cursor: pointer;
    opacity: 1; }
    .modal-dialog .modal-header .close:hover {
      color: darkturquoise; }
  .modal-dialog .modal-body {
    padding: 35px 15px; }
  .modal-dialog .quickview-big-img img {
    width: 100%; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.ml-70 {
    margin-left: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.ml-70 {
    margin-left: 0; } }

@media only screen and (max-width: 767px) {
  .product-details-content.ml-70 {
    margin-left: 0;
    margin-top: 30px; } }

@media only screen and (max-width: 767px) {
  .product-details-content.quickview-content {
    margin-top: 30px; } }

.product-details-content h2 {
  color: #010101;
  font-size: 24px;
  margin: 0 0 0px;
  line-height: 1; }

.product-details-content .product-details-price {
  display: flex;
  align-items: center;
  margin: 15px 0 26px; }
  .product-details-content .product-details-price span {
    font-size: 24px;
    color: black; }
    .product-details-content .product-details-price span.old {
      color: #333;
      font-size: 18px;
      text-decoration: line-through;
      margin-left: 20px; }

.product-details-content .pro-details-rating-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 17px; }
  .product-details-content .pro-details-rating-wrap .pro-details-rating {
    position: relative;
    margin-right: 39px; }
    .product-details-content .pro-details-rating-wrap .pro-details-rating:before {
      position: absolute;
      right: -19px;
      top: 4px;
      content: "";
      height: 15px;
      width: 2px;
      background-color: #d1d1d1;
      display: none; }
    .product-details-content .pro-details-rating-wrap .pro-details-rating i {
      color: #5f5d5d;
      font-size: 17px;
      margin-right: 5px; }
      .product-details-content .pro-details-rating-wrap .pro-details-rating i.yellow {
        color: #ffa900; }
  .product-details-content .pro-details-rating-wrap span a {
    font-size: 15px;
    color: darkturquoise; }
    .product-details-content .pro-details-rating-wrap span a:hover {
      color: #000; }

.product-details-content p {
  font-size: 15px;
  line-height: 28px;
  color: #333;
  margin: 0; }

.product-details-content .pro-details-list {
  margin: 20px 0 25px;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 27px; }
  .product-details-content .pro-details-list ul li {
    color: #333;
    margin: 0 0 5px; }
    .product-details-content .pro-details-list ul li:last-child {
      margin: 0; }

.product-details-content .pro-details-size-color {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .product-details-content .pro-details-size-color {
      display: block; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .product-details-content .pro-details-size-color {
      display: flex; } }
  .product-details-content .pro-details-size-color .pro-details-color-wrap {
    margin-right: 20px; }
    @media only screen and (max-width: 767px) {
      .product-details-content .pro-details-size-color .pro-details-color-wrap {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .product-details-content .pro-details-size-color .pro-details-color-wrap {
        margin-bottom: 0px; } }
    .product-details-content .pro-details-size-color .pro-details-color-wrap span {
      display: block;
      font-weight: 500;
      margin: 0 0 15px;
      font-size: 15px; }
    .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li {
      border-radius: 50px;
      cursor: pointer;
      display: block;
      float: left;
      height: 14px;
      margin-right: 15px;
      position: relative;
      transition: all 0.4s ease 0s;
      width: 14px; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li:last-child {
        margin-right: 0px; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.blue {
        background-color: #4798f3;
        border: 1px solid #4798f3; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.maroon {
        background-color: #736751;
        border: 1px solid #736751; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.gray {
        background-color: #c0c0c0;
        border: 1px solid #c0c0c0; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.green {
        background-color: #139c57;
        border: 1px solid #139c57; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.yellow {
        background-color: #e28b37;
        border: 1px solid #e28b37; }
    .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single {
      position: relative;
      border-radius: 50px;
      cursor: pointer;
      display: inline-block;
      height: 14px;
      width: 14px;
      margin-right: 15px;
      transition: all 0.4s ease 0s; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single:last-child {
        margin-right: 0; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 100%; }
        .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single input:checked ~ .checkmark:after {
          visibility: visible;
          opacity: 1; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single.blue {
        background-color: #090909;
        border: 1px solid #4798f3; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single.maroon {
        background-color: #736751;
        border: 1px solid #736751; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single.gray {
        background-color: #c0c0c0;
        border: 1px solid #c0c0c0; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single.green {
        background-color: #139c57;
        border: 1px solid #139c57; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single.yellow {
        background-color: #e28b37;
        border: 1px solid #e28b37; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single.white {
        background-color: #fff;
        border: 1px solid #333; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single.black {
        background-color: #333;
        border: 1px solid #333; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single.brown {
        background-color: brown;
        border: 1px solid brown; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single .checkmark {
        margin: 0;
        height: 14px;
        width: 14px;
        position: relative;
        display: inline-block; }
        .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single .checkmark:after {
          position: absolute;
          top: -7px;
          left: -4px;
          width: 20px;
          height: 20px;
          border: 2px solid #333;
          border-radius: 50%;
          content: '';
          visibility: hidden;
          opacity: 0;
          transition: 0.3s; }
      .product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single:hover input ~ .checkmark:after {
        visibility: visible;
        opacity: 1; }
  .product-details-content .pro-details-size-color .pro-details-size span {
    display: block;
    font-weight: 500;
    margin: 0 0 10px;
    font-size: 23px; }
  .product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content ul li {
    display: inline-block;
    list-style: outside none none;
    margin: 0 2px 0 0; }
    .product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content ul li a {
      background-color: #f1f2f6;
      color: #000;
      display: inline-block;
      font-size: 23px;
      line-height: 1;
      padding: 6px 9px 7px;
      text-transform: uppercase; }
      .product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content ul li a:hover {
        background-color: black;
        color: #fff; }
  .product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single {
    margin: 0 5px 0 0;
    background-color: #f1f2f6;
    color: #000;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    position: relative; }
    .product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single:hover {
      background-color: #000;
      color: #fff; }
    .product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      cursor: pointer;
      height: 100%; }
      .product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input:checked ~ .size-name {
        background-color: darkturquoise;
        color: #ffffff; }
    .product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single .size-name {
      margin-bottom: 0;
      width: 100%;
      padding: 8px;
      font-size: 12px;
      font-weight: 400; }

.product-details-content .pro-details-quality {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 5px; }
  .product-details-content .pro-details-quality .cart-plus-minus {
    border: 1px solid #e8e8e8;
    display: inline-block;
    height: 60px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 80px; }
    .product-details-content .pro-details-quality .cart-plus-minus .qtybutton {
      color: #8f8f8f;
      cursor: pointer;
      float: inherit;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
      position: absolute;
      text-align: center;
      transition: all 0.3s ease 0s;
      width: 24px;
      background: none;
      border: none; }
    .product-details-content .pro-details-quality .cart-plus-minus .dec.qtybutton {
      height: 60px;
      left: 0;
      top: 0; }
    .product-details-content .pro-details-quality .cart-plus-minus .inc.qtybutton {
      height: 60px;
      right: 0;
      top: 0; }
    .product-details-content .pro-details-quality .cart-plus-minus input.cart-plus-minus-box {
      background: transparent none repeat scroll 0 0;
      border: medium none;
      color: #8f8f8f;
      float: left;
      font-size: 14px;
      height: 60px;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 80px; }
  .product-details-content .pro-details-quality .pro-details-cart {
    margin: 0 25px 0 10px; }
    @media only screen and (max-width: 767px) {
      .product-details-content .pro-details-quality .pro-details-cart {
        margin: 0 10px 0 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .product-details-content .pro-details-quality .pro-details-cart {
        margin: 0 17px 0 10px; } }
    .product-details-content .pro-details-quality .pro-details-cart a, .product-details-content .pro-details-quality .pro-details-cart button {
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      background: none;
      border: none;
      background-color: #343538;
      display: inline-block;
      line-height: 1;
      padding: 23px 38px 23px;
      z-index: 99; }
      @media only screen and (max-width: 767px) {
        .product-details-content .pro-details-quality .pro-details-cart a, .product-details-content .pro-details-quality .pro-details-cart button {
          padding: 23px 12px 23px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .product-details-content .pro-details-quality .pro-details-cart a, .product-details-content .pro-details-quality .pro-details-cart button {
          padding: 22px 22px 22px; } }
      .product-details-content .pro-details-quality .pro-details-cart a:hover, .product-details-content .pro-details-quality .pro-details-cart button:hover {
        border: none; }
      .product-details-content .pro-details-quality .pro-details-cart a:disabled, .product-details-content .pro-details-quality .pro-details-cart button:disabled {
        cursor: not-allowed; }
        .product-details-content .pro-details-quality .pro-details-cart a:disabled:before, .product-details-content .pro-details-quality .pro-details-cart a:disabled:after, .product-details-content .pro-details-quality .pro-details-cart button:disabled:before, .product-details-content .pro-details-quality .pro-details-cart button:disabled:after {
          display: none; }
  .product-details-content .pro-details-quality .pro-details-wishlist a, .product-details-content .pro-details-quality .pro-details-wishlist button {
    font-size: 18px;
    color: #000;
    background: none;
    border: none; }
    .product-details-content .pro-details-quality .pro-details-wishlist a:hover, .product-details-content .pro-details-quality .pro-details-wishlist a.active, .product-details-content .pro-details-quality .pro-details-wishlist button:hover, .product-details-content .pro-details-quality .pro-details-wishlist button.active {
      color: #000; }
  .product-details-content .pro-details-quality .pro-details-compare a, .product-details-content .pro-details-quality .pro-details-compare button {
    font-size: 18px;
    color: #000;
    margin-left: 25px;
    background: none;
    border: none; }
    @media only screen and (max-width: 767px) {
      .product-details-content .pro-details-quality .pro-details-compare a, .product-details-content .pro-details-quality .pro-details-compare button {
        margin-left: 10px; } }
    .product-details-content .pro-details-quality .pro-details-compare a:hover, .product-details-content .pro-details-quality .pro-details-compare a.active, .product-details-content .pro-details-quality .pro-details-compare button:hover, .product-details-content .pro-details-quality .pro-details-compare button.active {
      color: #000; }

.product-details-content .pro-details-meta {
  display: flex;
  margin: 0 0 10px; }
  .product-details-content .pro-details-meta span {
    font-size: 15px;
    color: #676767;
    margin-right: 5px; }
  .product-details-content .pro-details-meta ul li {
    display: inline-block;
    margin: 0 10px 0 0; }
    .product-details-content .pro-details-meta ul li a {
      color: #676767;
      font-size: 15px; }
      @media only screen and (max-width: 767px) {
        .product-details-content .pro-details-meta ul li a {
          font-size: 14px; } }
      .product-details-content .pro-details-meta ul li a:hover {
        color: #000; }

.product-details-content .pro-details-social {
  margin: 24px 0 0; }
  .product-details-content .pro-details-social ul {
    display: flex; }
    .product-details-content .pro-details-social ul li {
      margin-right: 40px; }
      .product-details-content .pro-details-social ul li:last-child {
        margin-right: 0px; }
      .product-details-content .pro-details-social ul li a {
        font-size: 16px;
        color: #343538; }
        .product-details-content .pro-details-social ul li a:hover {
          color: #000; }

@media only screen and (max-width: 767px) {
  .product-details-content.pro-details-slider-content.mt-50 {
    margin-top: 30px; } }

.product-details-content.pro-details-slider-content .product-details-price {
  justify-content: center; }

.product-details-content.pro-details-slider-content p {
  width: 59%;
  margin: 0 auto; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .product-details-content.pro-details-slider-content p {
      width: 80%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .product-details-content.pro-details-slider-content p {
      width: 100%; } }
  @media only screen and (max-width: 767px) {
    .product-details-content.pro-details-slider-content p {
      width: 100%; } }

.product-details-content.pro-details-slider-content .pro-details-rating-wrap {
  justify-content: center; }

.product-details-content.pro-details-slider-content .pro-details-size-color {
  justify-content: center;
  text-align: left; }
  @media only screen and (max-width: 767px) {
    .product-details-content.pro-details-slider-content .pro-details-size-color {
      text-align: center; }
      .product-details-content.pro-details-slider-content .pro-details-size-color .pro-details-color-content {
        justify-content: center;
        display: flex; } }

.product-details-content.pro-details-slider-content .pro-details-quality {
  justify-content: center; }

.product-details-content.pro-details-slider-content .pro-details-meta {
  justify-content: center; }

.product-details-content.pro-details-slider-content .pro-details-social ul {
  justify-content: center; }

.quickview-wrap .nav-style-1.owl-carousel > .owl-nav div {
  font-size: 20px;
  color: #333;
  left: 0px; }
  .quickview-wrap .nav-style-1.owl-carousel > .owl-nav div:hover {
    color: #000; }
  .quickview-wrap .nav-style-1.owl-carousel > .owl-nav div.owl-next {
    right: 0px;
    left: auto; }

.quickview-wrap:hover .nav-style-1.owl-carousel > .owl-nav div {
  opacity: 1;
  visibility: visible; }

/*  prodduct 2     */
.tab-filter-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .tab-filter-wrap {
      display: block; }
      .tab-filter-wrap.mb-60 {
        margin-bottom: 30px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .tab-filter-wrap {
      display: flex; }
      .tab-filter-wrap.mb-60 {
        margin-bottom: 30px; } }

@media only screen and (max-width: 767px) {
  .product-tab-list-2 {
    margin: 0 0 10px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-2 {
    margin: 0 0 0px; } }

.product-tab-list-2 .nav-item:first-child .nav-link {
  padding-left: 0; }

.product-tab-list-2 a {
  display: inline-block;
  margin-right: 23px; }
  .product-tab-list-2 a.nav-link {
    background-color: transparent !important; }
  @media only screen and (max-width: 767px) {
    .product-tab-list-2 a {
      margin: 0 23px 10px 0; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .product-tab-list-2 a {
      margin: 0 23px 0px 0; } }
  .product-tab-list-2 a:last-child {
    margin-right: 0; }
  .product-tab-list-2 a h4 {
    color: #555;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    border-bottom: 2px solid transparent;
    padding-bottom: 5px;
    transition: all .3s ease 0s; }
    .product-tab-list-2 a h4:hover {
      color: #000000; }
  .product-tab-list-2 a.active h4 {
    color: #000000;
    border-bottom: 2px solid #000000; }

.filter-active {
  position: relative; }
  .filter-active a, .filter-active button {
    font-weight: 500;
    font-size: 18px;
    color: #010101;
    background: none;
    border: none; }
    .filter-active a:hover, .filter-active a.active, .filter-active button:hover, .filter-active button.active {
      color: darkturquoise; }
    .filter-active a i, .filter-active button i {
      font-size: 14px; }

.product-filter-wrapper {
  background-color: #fff;
  overflow: hidden;
  height: 0;
  transition: height 0.4s ease-out; }
  .product-filter-wrapper .product-filter h5 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px;
    padding-bottom: 7px;
    position: relative;
    text-transform: capitalize; }
    .product-filter-wrapper .product-filter h5::before {
      background-color: #000;
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      width: 20px; }
  .product-filter-wrapper .product-filter ul.sort-by li {
    margin-bottom: 3px; }
    .product-filter-wrapper .product-filter ul.sort-by li:last-child {
      margin-bottom: 0; }
  .product-filter-wrapper .product-filter ul.color-filter li {
    margin-bottom: 6px; }
    .product-filter-wrapper .product-filter ul.color-filter li:last-child {
      margin-bottom: 0px; }
    .product-filter-wrapper .product-filter ul.color-filter li input {
      width: 20px;
      height: 20px;
      display: inline-block;
      float: left;
      margin: 3px 8px 0 0; }
    .product-filter-wrapper .product-filter ul.color-filter li a {
      text-transform: capitalize; }
  .product-filter-wrapper .product-filter .product-tags ul li {
    margin-bottom: 4px; }
    .product-filter-wrapper .product-filter .product-tags ul li a {
      text-transform: capitalize; }
  .product-filter-wrapper .product-filter ul li button {
    background: none;
    border: none;
    text-transform: capitalize; }
    .product-filter-wrapper .product-filter ul li button:hover, .product-filter-wrapper .product-filter ul li button.active {
      color: darkturquoise; }
  .product-filter-wrapper .product-filter--tag ul li {
    display: inline-block; }
    .product-filter-wrapper .product-filter--tag ul li button {
      text-transform: lowercase;
      border: 1px solid #e6e6e6;
      margin-right: 10px;
      margin-bottom: 10px; }
      .product-filter-wrapper .product-filter--tag ul li button:hover {
        border-color: darkturquoise; }
  .product-filter-wrapper__inner {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;
    padding: 45px 45px 7px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .product-filter-wrapper__inner {
        padding: 35px 25px 7px; } }
    @media only screen and (max-width: 767px) {
      .product-filter-wrapper__inner {
        padding: 35px 25px 7px; } }

.price-filter .price-slider-amount input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 16px;
  font-weight: 500; }

.price-filter #slider-range {
  background: #dbdbdb none repeat scroll 0 0;
  border: medium none;
  border-radius: 50px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: auto; }
  .price-filter #slider-range span {
    transition: all .0s ease 0s; }
  .price-filter #slider-range .ui-slider-range {
    position: absolute;
    display: block;
    border: 0;
    background: #fa6bff none repeat scroll 0 0;
    border: none;
    transition: all .0s ease 0s; }
  .price-filter #slider-range .ui-slider-handle {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    border-radius: 50%;
    height: 15px;
    margin-left: 0;
    width: 15px;
    border: 4px solid #fa6bff; }

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden; }
  .tab-content.jump .tab-pane.active {
    display: block;
    height: auto;
    opacity: 1;
    overflow: visible; }

.product-wrap-2 {
  position: relative;
  overflow: hidden; }
  .product-wrap-2 .product-img {
    position: relative; }
    .product-wrap-2 .product-img img {
      width: 100%; }
    .product-wrap-2 .product-img span {
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      top: 20px;
      right: 20px; }
      .product-wrap-2 .product-img span.pink {
        color: darkturquoise; }
      .product-wrap-2 .product-img span.red {
        color: #ff3d2a; }
      .product-wrap-2 .product-img span.purple {
        color: darkturquoise; }
    .product-wrap-2 .product-img .default-img {
      transition: all 0.5s ease-in-out; }
    .product-wrap-2 .product-img .hover-img {
      position: absolute;
      top: 0px;
      visibility: hidden;
      left: 0px;
      width: 100%;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      transform: translateX(100%); }
    .product-wrap-2 .product-img .product-action-2 {
      position: absolute;
      top: 90%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      width: 100%; }
      .product-wrap-2 .product-img .product-action-2 a, .product-wrap-2 .product-img .product-action-2 button {
        display: inline-block;
        line-height: 1;
        width: 42px;
        height: 42px;
        line-height: 42px;
        font-size: 16px;
        background: none;
        border: none;
        background-color: darkturquoise;
        color: #fff;
        text-align: center;
        border-radius: 50px;
        margin: 0 3px;
        transition: all 0.4s ease-in-out;
        transform: scaleX(0); }
        .product-wrap-2 .product-img .product-action-2 a:hover, .product-wrap-2 .product-img .product-action-2 a.active, .product-wrap-2 .product-img .product-action-2 button:hover, .product-wrap-2 .product-img .product-action-2 button.active {
          background-color: black; }
      .product-wrap-2 .product-img .product-action-2.product-action-2-red a {
        background-color: #df262b; }
        .product-wrap-2 .product-img .product-action-2.product-action-2-red a:hover {
          background-color: darkturquoise; }
    .product-wrap-2 .product-img-badges {
      position: absolute;
      top: 12px;
      right: 12px; }
      .product-wrap-2 .product-img-badges > span {
        font-size: 13px;
        display: block;
        line-height: 1;
        padding: 3px 11px;
        border-radius: 3px;
        font-weight: 500;
        margin-bottom: 10px;
        position: static; }
        .product-wrap-2 .product-img-badges > span.pink {
          color: #fa6bff; }
        .product-wrap-2 .product-img-badges > span.purple {
          color: darkturquoise; }
        .product-wrap-2 .product-img-badges > span:last-child {
          margin-bottom: 0; }
  .product-wrap-2 .product-content-2 {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0; }
    .product-wrap-2 .product-content-2 .title-price-wrap-2 h3 {
      margin: 0 0 6px;
      line-height: 1; }
      .product-wrap-2 .product-content-2 .title-price-wrap-2 h3 a {
        font-size: 16px;
        color: #000000; }
        .product-wrap-2 .product-content-2 .title-price-wrap-2 h3 a:hover {
          color: darkturquoise; }
    .product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span {
      font-size: 15px;
      color: #000000;
      margin-right: 17px;
      position: relative; }
      .product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span.old {
        color: #fa6bff;
        text-decoration: line-through; }
        .product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span.old.red {
          color: #ff3d2a; }
      .product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span::before {
        position: absolute;
        content: "";
        right: -13px;
        top: 10px;
        width: 6px;
        height: 2px;
        background-color: #a5a5a5; }
      .product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span:last-child:before {
        display: none; }
    .product-wrap-2 .product-content-2 .pro-wishlist-2 a, .product-wrap-2 .product-content-2 .pro-wishlist-2 button {
      font-size: 16px;
      color: #929292;
      background: none;
      border: none;
      margin: 4px 0 0 10px;
      display: inline-block; }
      .product-wrap-2 .product-content-2 .pro-wishlist-2 a:hover, .product-wrap-2 .product-content-2 .pro-wishlist-2 a.active, .product-wrap-2 .product-content-2 .pro-wishlist-2 button:hover, .product-wrap-2 .product-content-2 .pro-wishlist-2 button.active {
        color: darkturquoise; }
  .product-wrap-2:hover .hover-img {
    visibility: visible;
    opacity: 1;
    transform: translateX(0); }
  .product-wrap-2:hover .default-img {
    transform: scale(2); }
  .product-wrap-2 .default-img {
    transition: transform .2s; }
  .product-wrap-2:hover .product-action-2 a {
    transform: scaleX(1); }
  .product-wrap-2:hover .product-action-2 button {
    transform: scaleX(1); }
  .product-wrap-2.pro-glaucous-color .product-img span.glaucous {
    color: #3d6882; }
  .product-wrap-2.pro-glaucous-color .product-img .product-action-2 a, .product-wrap-2.pro-glaucous-color .product-img .product-action-2 button {
    background-color: #3d6882; }
    .product-wrap-2.pro-glaucous-color .product-img .product-action-2 a:hover, .product-wrap-2.pro-glaucous-color .product-img .product-action-2 a.active, .product-wrap-2.pro-glaucous-color .product-img .product-action-2 button:hover, .product-wrap-2.pro-glaucous-color .product-img .product-action-2 button.active {
      background-color: #fff;
      color: #000; }
  .product-wrap-2.pro-glaucous-color .product-content-2 .pro-wishlist-2 a:hover {
    color: #3d6882; }
  .product-wrap-2.pro-glaucous-color .product-content-2 .title-price-wrap-2 h3 a:hover {
    color: #3d6882; }
  .product-wrap-2.pro-glaucous-color .product-content-2 .title-price-wrap-2 .price-2 span.old {
    color: #3d6882; }

/* product hm3 */
.section-padding-1 .container-fluid, .section-padding-1 .container-sm, .section-padding-1 .container-md, .section-padding-1 .container-lg, .section-padding-1 .container-xl {
  padding: 0 70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .section-padding-1 .container-fluid, .section-padding-1 .container-sm, .section-padding-1 .container-md, .section-padding-1 .container-lg, .section-padding-1 .container-xl {
      padding: 0 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padding-1 .container-fluid, .section-padding-1 .container-sm, .section-padding-1 .container-md, .section-padding-1 .container-lg, .section-padding-1 .container-xl {
      padding: 0 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding-1 .container-fluid, .section-padding-1 .container-sm, .section-padding-1 .container-md, .section-padding-1 .container-lg, .section-padding-1 .container-xl {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .section-padding-1 .container-fluid, .section-padding-1 .container-sm, .section-padding-1 .container-md, .section-padding-1 .container-lg, .section-padding-1 .container-xl {
      padding: 0 15px; } }

/* product hm4 */
.hm4-section-padding .container-fluid, .hm4-section-padding .container-sm, .hm4-section-padding .container-md, .hm4-section-padding .container-lg, .hm4-section-padding .container-xl {
  padding: 0 70px; }
  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .hm4-section-padding .container-fluid, .hm4-section-padding .container-sm, .hm4-section-padding .container-md, .hm4-section-padding .container-lg, .hm4-section-padding .container-xl {
      padding: 0 60px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .hm4-section-padding .container-fluid, .hm4-section-padding .container-sm, .hm4-section-padding .container-md, .hm4-section-padding .container-lg, .hm4-section-padding .container-xl {
      padding: 0 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hm4-section-padding .container-fluid, .hm4-section-padding .container-sm, .hm4-section-padding .container-md, .hm4-section-padding .container-lg, .hm4-section-padding .container-xl {
      padding: 0 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hm4-section-padding .container-fluid, .hm4-section-padding .container-sm, .hm4-section-padding .container-md, .hm4-section-padding .container-lg, .hm4-section-padding .container-xl {
      padding: 0 30px; } }
  @media only screen and (max-width: 767px) {
    .hm4-section-padding .container-fluid, .hm4-section-padding .container-sm, .hm4-section-padding .container-md, .hm4-section-padding .container-lg, .hm4-section-padding .container-xl {
      padding: 0 15px; } }

.toggle-item-active,
.toggle-item-active2,
.toggle-item-active3,
.toggle-item-active4,
.toggle-item-active5,
.toggle-item-active6 {
  display: none; }

.view-more {
  display: block;
  text-align: center;
  width: 100%; }
  .view-more a {
    color: #555;
    font-weight: 500;
    display: inline-block;
    border-bottom: 1px solid #cccccc;
    line-height: 1;
    padding-bottom: 2px;
    text-align: center; }
    .view-more a:hover {
      color: darkturquoise;
      border-bottom: 1px solid darkturquoise; }
  @media only screen and (max-width: 767px) {
    .view-more.mt-20 {
      margin-top: 0; } }
  .view-more.round-btn a {
    font-size: 16px;
    color: #fff;
    display: inline-block;
    text-transform: capitalize;
    line-height: 1;
    background-color: #c61a32;
    border-radius: 34px 34px 34px 34px;
    padding: 16px 40px 16px 40px; }
    .view-more.round-btn a:hover {
      background-color: darkturquoise; }

/* product hm5 */
.hm5-section-padding .container-fluid, .hm5-section-padding .container-sm, .hm5-section-padding .container-md, .hm5-section-padding .container-lg, .hm5-section-padding .container-xl {
  padding-right: 0px;
  padding-left: 0px; }
  @media only screen and (max-width: 767px) {
    .hm5-section-padding .container-fluid, .hm5-section-padding .container-sm, .hm5-section-padding .container-md, .hm5-section-padding .container-lg, .hm5-section-padding .container-xl {
      padding-right: 15px;
      padding-left: 15px; } }

.hm5-section-padding .row {
  margin-right: -10px;
  margin-left: -10px; }
  @media only screen and (max-width: 767px) {
    .hm5-section-padding .row {
      margin-right: -15px;
      margin-left: -15px; } }

.hm5-section-padding div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px; }
  @media only screen and (max-width: 767px) {
    .hm5-section-padding div[class^="col-"] {
      padding-left: 15px;
      padding-right: 15px; } }

.product-wrap-3 {
  position: relative; }
  .product-wrap-3 .product-img {
    position: relative; }
    .product-wrap-3 .product-img a img {
      width: 100%; }
    .product-wrap-3 .product-img-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 9; }
      .product-wrap-3 .product-img-badges span {
        font-size: 13px;
        color: #fff;
        display: block;
        line-height: 1;
        padding: 3px 11px;
        border-radius: 3px;
        font-weight: 500;
        margin-bottom: 10px; }
        .product-wrap-3 .product-img-badges span.purple {
          background-color: darkturquoise; }
        .product-wrap-3 .product-img-badges span.pink {
          background-color: #fa6bff; }
        .product-wrap-3 .product-img-badges span:last-child {
          margin-bottom: 0; }
  .product-wrap-3 .product-content-3-wrap {
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 90%;
    height: 90%;
    left: 5%;
    right: 5%;
    bottom: 5%;
    top: 5%;
    transition: all 0.4s ease 0s;
    z-index: 8;
    transform: scale(0.85); }
    .product-wrap-3 .product-content-3-wrap .product-content-3 {
      left: 50%;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      transition: all 0.4s ease 0s; }
      .product-wrap-3 .product-content-3-wrap .product-content-3 .product-title h3 {
        font-size: 18px;
        margin: 0;
        line-height: 1; }
      .product-wrap-3 .product-content-3-wrap .product-content-3 .price-3 {
        margin: 10px 0 20px; }
        .product-wrap-3 .product-content-3-wrap .product-content-3 .price-3 span {
          font-size: 16px;
          color: #000;
          font-weight: 500;
          margin: 0 9px;
          position: relative; }
          .product-wrap-3 .product-content-3-wrap .product-content-3 .price-3 span::before {
            position: absolute;
            content: "";
            right: -13px;
            top: 10px;
            width: 7px;
            height: 2px;
            background-color: #000; }
          .product-wrap-3 .product-content-3-wrap .product-content-3 .price-3 span:last-child::before {
            display: none; }
          .product-wrap-3 .product-content-3-wrap .product-content-3 .price-3 span.old {
            color: #8e8e8e;
            text-decoration: line-through; }
      .product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 .pro-same-action {
        display: inline-block; }
      .product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 a, .product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 button {
        display: inline-block;
        width: 42px;
        height: 42px;
        line-height: 42px;
        font-size: 16px;
        background: none;
        border: none;
        background-color: #a749ff;
        color: #fff;
        text-align: center;
        border-radius: 50px;
        margin: 0 3px;
        transition: all 0.4s ease-in-out; }
        .product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 a:hover, .product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 a.active, .product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 button:hover, .product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 button.active {
          background-color: #fa6bff; }
        .product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 a:disabled, .product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 button:disabled {
          cursor: not-allowed; }
  .product-wrap-3:hover .product-content-3-wrap {
    opacity: 1;
    visibility: visible;
    transform: scale(1); }

/* product hm6 */
.hm6-section-padding .container-fluid, .hm6-section-padding .container-sm, .hm6-section-padding .container-md, .hm6-section-padding .container-lg, .hm6-section-padding .container-xl {
  padding-right: 70px;
  padding-left: 70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .hm6-section-padding .container-fluid, .hm6-section-padding .container-sm, .hm6-section-padding .container-md, .hm6-section-padding .container-lg, .hm6-section-padding .container-xl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hm6-section-padding .container-fluid, .hm6-section-padding .container-sm, .hm6-section-padding .container-md, .hm6-section-padding .container-lg, .hm6-section-padding .container-xl {
      padding-right: 30px;
      padding-left: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hm6-section-padding .container-fluid, .hm6-section-padding .container-sm, .hm6-section-padding .container-md, .hm6-section-padding .container-lg, .hm6-section-padding .container-xl {
      padding-right: 40px;
      padding-left: 40px; } }
  @media only screen and (max-width: 767px) {
    .hm6-section-padding .container-fluid, .hm6-section-padding .container-sm, .hm6-section-padding .container-md, .hm6-section-padding .container-lg, .hm6-section-padding .container-xl {
      padding-right: 15px;
      padding-left: 15px; } }

.hm6-section-padding .row {
  margin-right: -10px;
  margin-left: -10px; }
  @media only screen and (max-width: 767px) {
    .hm6-section-padding .row {
      margin-right: -15px;
      margin-left: -15px; } }

.hm6-section-padding div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px; }
  @media only screen and (max-width: 767px) {
    .hm6-section-padding div[class^="col-"] {
      padding-left: 15px;
      padding-right: 15px; } }

.product-wrap-4 {
  position: relative;
  overflow: hidden; }
  .product-wrap-4 a img {
    width: 100%;
    transition: all .5s ease 0s;
    transform: scale(1); }
  .product-wrap-4 .position-1 {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 75px; }
    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      .product-wrap-4 .position-1 {
        bottom: 40px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .product-wrap-4 .position-1 {
        bottom: 40px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .product-wrap-4 .position-1 {
        bottom: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .product-wrap-4 .position-1 {
        bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .product-wrap-4 .position-1 {
        bottom: 30px; } }
  .product-wrap-4 .position-2 {
    position: absolute;
    left: 60px;
    bottom: 55px; }
    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      .product-wrap-4 .position-2 {
        bottom: 40px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .product-wrap-4 .position-2 {
        bottom: 40px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .product-wrap-4 .position-2 {
        bottom: 30px;
        left: 40px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .product-wrap-4 .position-2 {
        bottom: 30px;
        left: 20px; } }
    @media only screen and (max-width: 767px) {
      .product-wrap-4 .position-2 {
        bottom: 30px;
        left: 20px; } }
  .product-wrap-4 .position-3 {
    position: absolute;
    top: 51px;
    left: 0;
    right: 0;
    text-align: center; }
    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      .product-wrap-4 .position-3 {
        top: 32px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .product-wrap-4 .position-3 {
        top: 32px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .product-wrap-4 .position-3 {
        top: 22px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .product-wrap-4 .position-3 {
        top: 22px; } }
    @media only screen and (max-width: 767px) {
      .product-wrap-4 .position-3 {
        top: 22px; } }
  .product-wrap-4 .product-content-4 h4 {
    font-size: 30px;
    margin: 0 0 12px;
    line-height: 38px; }
    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      .product-wrap-4 .product-content-4 h4 {
        font-size: 25px;
        line-height: 30px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .product-wrap-4 .product-content-4 h4 {
        font-size: 25px;
        line-height: 30px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .product-wrap-4 .product-content-4 h4 {
        font-size: 22px;
        line-height: 27px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .product-wrap-4 .product-content-4 h4 {
        font-size: 20px;
        line-height: 23px; } }
    @media only screen and (max-width: 767px) {
      .product-wrap-4 .product-content-4 h4 {
        font-size: 20px;
        line-height: 23px; } }
  .product-wrap-4 .product-content-4 .price-4 span {
    font-size: 18px;
    color: #000000;
    position: relative;
    margin: 0 24px 0 0; }
    .product-wrap-4 .product-content-4 .price-4 span:before {
      position: absolute;
      content: "";
      right: -17px;
      top: 12px;
      width: 7px;
      height: 2px;
      background-color: #6b6264; }
    .product-wrap-4 .product-content-4 .price-4 span:last-child:before {
      display: none; }
    .product-wrap-4 .product-content-4 .price-4 span.old {
      color: #fa6bff;
      text-decoration: line-through; }
  .product-wrap-4 .product-content-4 .price-4.price-4-center span {
    margin: 0 12px; }
  .product-wrap-4:hover a img {
    transform: scale(1.1); }

.width-50-percent {
  flex: 0 0 50%;
  max-width: 50%; }

.width-30-percent {
  flex: 0 0 30%;
  max-width: 30%; }

/* product home 8*/
.collection-product .collection-img {
  overflow: hidden; }
  .collection-product .collection-img a img {
    width: 100%;
    transition: all .5s ease 0s;
    transform: scale(1); }

.collection-product .collection-content {
  margin: 23px 0 0; }
  .collection-product .collection-content span {
    font-weight: 500; }
  .collection-product .collection-content h4 {
    margin: 4px 0 0;
    line-height: 1; }
    .collection-product .collection-content h4 a {
      font-size: 16px;
      color: #000000;
      font-weight: 500; }
      .collection-product .collection-content h4 a:hover {
        color: darkturquoise; }
  .collection-product .collection-content .collection-btn {
    color: #666;
    font-size: 13px;
    display: inline-block;
    border: 1px solid #010101;
    text-transform: uppercase;
    line-height: 1;
    padding: 8px 11px;
    background-color: transparent;
    font-weight: 400;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    margin-top: 15px; }
    .collection-product .collection-content .collection-btn:before, .collection-product .collection-content .collection-btn:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: auto;
      width: 0;
      height: 100%;
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
      z-index: -1; }
    .collection-product .collection-content .collection-btn:hover {
      background-color: transparent;
      color: #ffffff;
      border-color: darkturquoise; }
      .collection-product .collection-content .collection-btn:hover:after {
        background-color: darkturquoise;
        width: 100%;
        left: 0;
        right: auto; }

.collection-product:hover a img {
  transform: scale(1.1); }

.product-wrap-5 {
  position: relative; }
  .product-wrap-5 .product-img {
    position: relative; }
    .product-wrap-5 .product-img a img {
      width: 100%; }
    .product-wrap-5 .product-img-badges {
      position: absolute;
      top: 12px;
      right: 12px; }
    .product-wrap-5 .product-img span {
      font-size: 13px;
      color: #fff;
      display: block;
      line-height: 1;
      padding: 3px 11px;
      border-radius: 3px;
      font-weight: 500;
      margin-bottom: 10px; }
      .product-wrap-5 .product-img span.purple {
        background-color: darkturquoise; }
      .product-wrap-5 .product-img span.pink {
        background-color: #fa6bff; }
      .product-wrap-5 .product-img span:last-child {
        margin-bottom: 0; }
  .product-wrap-5 .product-action-4 {
    background: none repeat scroll 0 0 #a749ff;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 1;
    padding: 10px;
    position: absolute;
    text-align: center;
    transform: scaleY(0);
    transition: all 0.5s ease 0s;
    width: 100%;
    z-index: 999; }
    .product-wrap-5 .product-action-4 .pro-same-action {
      margin: 0 2px; }
      .product-wrap-5 .product-action-4 .pro-same-action a, .product-wrap-5 .product-action-4 .pro-same-action button {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 36px;
        font-size: 16px;
        background: none;
        border: none;
        background-color: #fff;
        color: #000;
        text-align: center;
        border-radius: 50px;
        margin: 0 3px; }
        .product-wrap-5 .product-action-4 .pro-same-action a:hover, .product-wrap-5 .product-action-4 .pro-same-action a.active, .product-wrap-5 .product-action-4 .pro-same-action button:hover, .product-wrap-5 .product-action-4 .pro-same-action button.active {
          background-color: #fa6bff;
          color: #fff; }
  .product-wrap-5 .product-content-5 {
    margin-top: 28px; }
    .product-wrap-5 .product-content-5 h3 {
      font-size: 16px;
      margin: 0 0 5px;
      line-height: 17px; }
      .product-wrap-5 .product-content-5 h3 a {
        color: #000; }
        .product-wrap-5 .product-content-5 h3 a:hover {
          color: darkturquoise; }
    .product-wrap-5 .product-content-5 .price-5 span {
      font-size: 15px;
      color: #000000;
      margin: 0 8px;
      position: relative; }
      .product-wrap-5 .product-content-5 .price-5 span:before {
        position: absolute;
        content: "";
        right: -13px;
        top: 10px;
        width: 6px;
        height: 2px;
        background-color: #a5a5a5; }
      .product-wrap-5 .product-content-5 .price-5 span:last-child:before {
        display: none; }
      .product-wrap-5 .product-content-5 .price-5 span.old {
        color: #fa6bff;
        text-decoration: line-through; }
  .product-wrap-5:hover .product-action-4 {
    opacity: 1;
    transform: scaleY(1); }

/* product home 9*/
.collection-wrap-2 {
  margin-top: -175px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .collection-wrap-2 {
      margin-top: -97px; } }
  @media only screen and (max-width: 767px) {
    .collection-wrap-2 {
      margin-top: -80px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .collection-wrap-2 {
      margin-top: -70px; } }

.collection-product-2 {
  position: relative;
  overflow: hidden; }
  .collection-product-2 a img {
    width: 100%;
    transform: scale(1);
    transition: all .5s ease 0s; }
  .collection-product-2 .collection-content-2 {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 30px; }
    .collection-product-2 .collection-content-2 span {
      font-size: 14px;
      font-weight: 500; }
    .collection-product-2 .collection-content-2 h4 {
      font-size: 16px;
      font-weight: 500;
      margin: 5px 0 0;
      line-height: 21px; }
  .collection-product-2:hover a img {
    transform: scale(1.1); }

/* product hm9 */
.hm9-section-padding .container-fluid, .hm9-section-padding .container-sm, .hm9-section-padding .container-md, .hm9-section-padding .container-lg, .hm9-section-padding .container-xl {
  padding-right: 70px;
  padding-left: 70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .hm9-section-padding .container-fluid, .hm9-section-padding .container-sm, .hm9-section-padding .container-md, .hm9-section-padding .container-lg, .hm9-section-padding .container-xl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hm9-section-padding .container-fluid, .hm9-section-padding .container-sm, .hm9-section-padding .container-md, .hm9-section-padding .container-lg, .hm9-section-padding .container-xl {
      padding-right: 30px;
      padding-left: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hm9-section-padding .container-fluid, .hm9-section-padding .container-sm, .hm9-section-padding .container-md, .hm9-section-padding .container-lg, .hm9-section-padding .container-xl {
      padding-right: 40px;
      padding-left: 40px; } }
  @media only screen and (max-width: 767px) {
    .hm9-section-padding .container-fluid, .hm9-section-padding .container-sm, .hm9-section-padding .container-md, .hm9-section-padding .container-lg, .hm9-section-padding .container-xl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .hm9-section-padding .container-fluid, .hm9-section-padding .container-sm, .hm9-section-padding .container-md, .hm9-section-padding .container-lg, .hm9-section-padding .container-xl {
      padding-right: 30px;
      padding-left: 30px; } }

.hm9-section-padding .custom-row-4 {
  margin-right: -10px;
  margin-left: -10px; }
  @media only screen and (max-width: 767px) {
    .hm9-section-padding .custom-row-4 {
      margin-right: -15px;
      margin-left: -15px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .hm9-section-padding .custom-row-4 {
      margin-right: -10px;
      margin-left: -10px; } }

.hm9-section-padding div[class^="custom2-"] {
  padding-left: 10px;
  padding-right: 10px; }
  @media only screen and (max-width: 767px) {
    .hm9-section-padding div[class^="custom2-"] {
      padding-left: 15px;
      padding-right: 15px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .hm9-section-padding div[class^="custom2-"] {
      padding-left: 10px;
      padding-right: 10px; } }

.custom-row-4 {
  display: flex;
  flex-wrap: wrap; }

.custom2-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  min-height: 1px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .custom2-col-5 {
      flex: 0 0 25%;
      max-width: 25%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .custom2-col-5 {
      flex: 0 0 33.333%;
      max-width: 33.333%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .custom2-col-5 {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media only screen and (max-width: 767px) {
    .custom2-col-5 {
      flex: 0 0 100%;
      max-width: 100%; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .custom2-col-5 {
      flex: 0 0 50%;
      max-width: 50%; } }

/*------- home 14 ----------*/
.product-top-bar {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .product-top-bar {
      display: block;
      justify-content: inherit;
      text-align: center; } }

.product-tab-list-3 {
  margin: 4px 0 0;
  position: relative;
  z-index: 99; }
  @media only screen and (max-width: 767px) {
    .product-tab-list-3 {
      justify-content: center;
      margin: 15px 0 0; } }
  .product-tab-list-3 a {
    margin-left: 24px; }
    .product-tab-list-3 a.nav-link {
      background-color: transparent !important;
      padding: 0; }
    .product-tab-list-3 a.active h4 {
      color: #242424; }
    .product-tab-list-3 a:first-child {
      margin-left: 45px; }
      @media only screen and (max-width: 767px) {
        .product-tab-list-3 a:first-child {
          margin-left: 20px; } }
    .product-tab-list-3 a h4 {
      font-size: 14px;
      color: #878787;
      margin: 0;
      line-height: 1; }

.product-slider-active .owl-nav {
  position: absolute;
  right: 0;
  top: -85px; }
  @media only screen and (max-width: 767px) {
    .product-slider-active .owl-nav {
      right: 0;
      left: 0;
      top: -45px;
      text-align: center; } }
  .product-slider-active .owl-nav div {
    display: inline-block;
    width: 35px;
    height: 35px;
    font-size: 18px;
    line-height: 31px;
    text-align: center;
    border-radius: 5px;
    color: #c6c6c6;
    border: 1px solid #d2d2d2;
    background-color: #f9f9f9;
    transition: all .3s ease 0s; }
    @media only screen and (max-width: 767px) {
      .product-slider-active .owl-nav div {
        width: 25px;
        height: 25px;
        font-size: 15px;
        line-height: 23px; } }
    .product-slider-active .owl-nav div:hover {
      color: #fff;
      border: 1px solid #ff3d2a;
      background-color: #ff3d2a; }
    .product-slider-active .owl-nav div.owl-next {
      margin-left: 3px; }

.pro-view-more {
  margin-top: 30px; }
  @media only screen and (max-width: 767px) {
    .pro-view-more {
      margin-top: 0px; } }
  .pro-view-more a {
    display: inline-block;
    font-size: 16px;
    color: #828282;
    text-transform: uppercase;
    line-height: 1;
    padding: 20px 35px 19px;
    border: 1px solid #d2d2d2; }
    .pro-view-more a i {
      margin-right: 10px; }
    .pro-view-more a:hover {
      background-color: #df262b;
      color: #fff;
      border: 1px solid #df262b; }

.bg-png-1 {
  position: absolute;
  top: 0;
  left: 78px; }
  @media only screen and (max-width: 767px) {
    .bg-png-1 {
      display: none; } }

.funfact-area {
  position: relative; }

.bg-png-2 {
  position: absolute;
  top: 0;
  right: 550px; }
  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .bg-png-2 {
      right: 300px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .bg-png-2 {
      right: 250px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bg-png-2 {
      right: 150px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bg-png-2 {
      right: 120px; } }
  @media only screen and (max-width: 767px) {
    .bg-png-2 {
      display: none; } }

.product-quickview-modal-wrapper .modal-dialog {
  padding: 0;
  margin: 5% auto; }

.button-add-cart {
  background-color: darkturquoise;
  border-radius: 5px;
  border: 2px solid darkturquoise;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 0;
  padding: 18px 10px 17px;
  text-align: center;
  text-transform: uppercase; }
  .button-add-cart:hover {
    background-color: darkturquoise;
    color: #333; }

.content-observacion {
  font-size: 13px !important;
  margin-top: 15px;
  border-color: darkturquoise !important;
  outline: none !important; }

.button-manual {
  background-color: white;
  border: 1px solid white;
  color: darkturquoise;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  padding: 0;
  text-align: start; }
  .button-manual:hover {
    color: darkcyan; }

.content-button-add {
  margin-top: 8px; }

.content-button {
  padding-left: 2px !important; }

.button-manual-cancelar {
  background-color: white;
  border: 1px solid white;
  color: #333;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  padding: 0;
  text-align: start; }
  .button-manual-cancelar:hover {
    color: darkturquoise; }

.cantidad-ingresar {
  width: 100px !important;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.450rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-color: darkturquoise !important;
  background-clip: padding-box;
  border: 1px solid darkturquoise;
  border-radius: 0.25rem;
  -webkit-appearance: textfield !important;
  margin: 0;
  -moz-appearance: textfield !important; }

.btn-outline-info {
  border-color: darkturquoise !important;
  outline: none !important; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.blog-wrap .blog-img {
  position: relative;
  overflow: hidden; }
  .blog-wrap .blog-img img {
    width: 100%;
    transform: scale(1);
    transition: all 0.5s ease 0s; }
  .blog-wrap .blog-img .blog-category-names {
    position: absolute;
    top: 10px;
    left: 10px; }
    .blog-wrap .blog-img .blog-category-names span {
      display: inline-block;
      line-height: 1;
      color: #fff;
      font-size: 13px;
      font-weight: 500;
      padding: 2px 10px 5px;
      margin-right: 10px; }
      .blog-wrap .blog-img .blog-category-names span.pink {
        background-color: #fa6bff; }
      .blog-wrap .blog-img .blog-category-names span.purple {
        background-color: #a749ff; }
      .blog-wrap .blog-img .blog-category-names span.red {
        background-color: #c61a32; }
      .blog-wrap .blog-img .blog-category-names span::last-child {
        margin-right: 0; }
    .blog-wrap .blog-img .blog-category-names--style2 {
      right: 10px;
      left: auto; }

.blog-wrap .blog-content-wrap {
  text-align: center; }
  .blog-wrap .blog-content-wrap .blog-content {
    display: inline-block;
    background-color: #fff;
    padding: 14px 44px;
    margin: -64px 0 0;
    z-index: 99;
    position: relative;
    width: 320px;
    max-width: 100%; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .blog-wrap .blog-content-wrap .blog-content {
        padding: 14px 30px;
        margin: -50px 0 0; } }
    @media only screen and (max-width: 767px) {
      .blog-wrap .blog-content-wrap .blog-content {
        padding: 14px 30px;
        margin: -50px 0 0; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .blog-wrap .blog-content-wrap .blog-content {
        padding: 14px 15px; } }
    .blog-wrap .blog-content-wrap .blog-content h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin: 0 0 5px; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .blog-wrap .blog-content-wrap .blog-content h3 {
          font-size: 18px;
          line-height: 27px; } }
    .blog-wrap .blog-content-wrap .blog-content span {
      color: #666;
      font-style: italic; }
      .blog-wrap .blog-content-wrap .blog-content span a {
        color: #666; }
        .blog-wrap .blog-content-wrap .blog-content span a:hover {
          color: #a749ff; }
    .blog-wrap .blog-content-wrap .blog-content--style2 {
      background: #f6f3f3;
      width: 100%;
      min-height: 150px;
      padding: 21px 15px 30px 15px;
      margin-top: 10px; }

.blog-wrap:hover .blog-img img {
  transform: scale(1.2); }

/* blog page */
.blog-wrap-2 {
  box-shadow: 0 3px 5px rgba(85, 85, 85, 0.2); }
  .blog-wrap-2 .blog-img-2 {
    overflow: hidden;
    position: relative; }
    .blog-wrap-2 .blog-img-2 a img {
      width: 100%;
      transform: scale(1);
      transition: all 0.5s ease 0s; }
    .blog-wrap-2 .blog-img-2 .video-icon {
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%;
      transform: translateY(-50%); }
      .blog-wrap-2 .blog-img-2 .video-icon a {
        background-color: #ff0000;
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 25px;
        height: 60px;
        line-height: 61px;
        width: 60px; }
        .blog-wrap-2 .blog-img-2 .video-icon a:hover {
          background-color: #a749ff; }
  .blog-wrap-2 .blog-content-2 {
    padding: 25px 30px 34px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .blog-wrap-2 .blog-content-2 {
        padding: 25px 20px 34px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .blog-wrap-2 .blog-content-2 {
        padding: 25px 20px 34px; } }
    @media only screen and (max-width: 767px) {
      .blog-wrap-2 .blog-content-2 {
        padding: 20px 15px 29px; } }
    .blog-wrap-2 .blog-content-2 .blog-meta-2 {
      display: flex; }
      .blog-wrap-2 .blog-content-2 .blog-meta-2 ul li {
        color: #333;
        display: inline-block;
        font-size: 14px;
        letter-spacing: 1px;
        list-style: outside none none;
        margin: 0 17px 0 0;
        position: relative;
        text-transform: uppercase;
        display: inline-block;
        line-height: 1; }
        .blog-wrap-2 .blog-content-2 .blog-meta-2 ul li::before {
          background-color: #555;
          content: "";
          height: 2px;
          position: absolute;
          right: -13px;
          top: 6px;
          transition: all 0.4s ease 0s;
          width: 5px; }
        .blog-wrap-2 .blog-content-2 .blog-meta-2 ul li:last-child::before {
          display: none; }
        .blog-wrap-2 .blog-content-2 .blog-meta-2 ul li a {
          color: #555; }
          .blog-wrap-2 .blog-content-2 .blog-meta-2 ul li a:hover {
            color: #a749ff; }
    .blog-wrap-2 .blog-content-2 h4 {
      font-size: 22px;
      font-weight: 500;
      margin: 10px 0 20px;
      color: #333; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .blog-wrap-2 .blog-content-2 h4 {
          font-size: 19px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .blog-wrap-2 .blog-content-2 h4 {
          font-size: 19px; } }
      @media only screen and (max-width: 767px) {
        .blog-wrap-2 .blog-content-2 h4 {
          font-size: 17px;
          margin: 10px 0 12px; } }
      .blog-wrap-2 .blog-content-2 h4 a {
        color: #333; }
        .blog-wrap-2 .blog-content-2 h4 a:hover {
          color: #a749ff; }
    .blog-wrap-2 .blog-content-2 p {
      margin: 0;
      color: #666; }
    .blog-wrap-2 .blog-content-2 .blog-share-comment {
      display: flex;
      justify-content: space-between;
      margin: 22px 0 0; }
      @media only screen and (max-width: 767px) {
        .blog-wrap-2 .blog-content-2 .blog-share-comment {
          margin: 14px 0 0; } }
      .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-btn-2 a {
        color: #333;
        display: inline-block;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.2px;
        margin-top: 4px;
        text-transform: capitalize; }
        .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-btn-2 a:hover {
          color: #a749ff; }
      .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share span {
        color: #333;
        display: inline-block;
        float: left;
        font-size: 15px;
        margin-right: 12px;
        margin-top: 3px;
        text-transform: capitalize; }
      .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social {
        display: inline-block;
        float: left; }
        @media only screen and (max-width: 767px) {
          .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social {
            margin-top: 4px; } }
        .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li {
          display: inline-block;
          margin-right: 5px; }
          .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li:last-child {
            margin-right: 0; }
          .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li a {
            border-radius: 50px;
            color: #fff;
            display: inline-block;
            font-size: 14px;
            height: 30px;
            line-height: 28px;
            text-align: center;
            width: 30px; }
            @media only screen and (max-width: 767px) {
              .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li a {
                font-size: 12px;
                height: 25px;
                line-height: 23px;
                width: 25px; } }
            .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li a.facebook {
              background-color: #3b5998;
              border: 1px solid #3b5998; }
              .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li a.facebook:hover {
                background-color: transparent;
                color: #3b5998; }
            .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li a.twitter {
              background-color: #55acee;
              border: 1px solid #55acee; }
              .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li a.twitter:hover {
                background-color: transparent;
                color: #55acee; }
            .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li a.instagram {
              background-color: #c32aa3;
              border: 1px solid #c32aa3; }
              .blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social ul li a.instagram:hover {
                background-color: transparent;
                color: #c32aa3; }
  .blog-wrap-2:hover .blog-img-2 img {
    transform: scale(1.1); }

.nav-style-4.owl-carousel .owl-nav div {
  color: #fff;
  display: inline-block;
  font-size: 20px;
  left: 0px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background-color: #a749ff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .nav-style-4.owl-carousel .owl-nav div {
      font-size: 30px; } }
  @media only screen and (max-width: 767px) {
    .nav-style-4.owl-carousel .owl-nav div {
      font-size: 30px; } }
  .nav-style-4.owl-carousel .owl-nav div:hover {
    color: #fff;
    background-color: #333; }
  .nav-style-4.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: 0px; }

.blog-wrap-2:hover .nav-style-4.owl-carousel .owl-nav div {
  opacity: 1;
  visibility: visible; }

/*-------- 7. Blog style  ----------*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Blog-area .ml-20 {
    margin-left: 0; }
  .Blog-area .mr-20 {
    margin-right: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .Blog-area .ml-20 {
    margin-left: 0; }
  .Blog-area .mr-20 {
    margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .Blog-area .ml-20 {
    margin-left: 0; }
  .Blog-area .mr-20 {
    margin-right: 0; }
  .Blog-area .pro-pagination-style.mt-20 {
    margin-top: 0; } }

@media only screen and (max-width: 767px) {
  .Blog-area.pb-100 {
    padding-bottom: 45px; } }

.Blog-area.blog-no-sidebar .blog-content-2 h4 {
  font-size: 20px; }
  @media only screen and (max-width: 767px) {
    .Blog-area.blog-no-sidebar .blog-content-2 h4 {
      font-size: 17px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .Blog-area.blog-no-sidebar .blog-content-2 h4 {
      font-size: 20px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Blog-area.blog-no-sidebar .blog-content-2 .blog-share-comment .blog-share .share-social {
    margin-top: 4px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Blog-area.blog-no-sidebar .blog-content-2 .blog-share-comment .blog-share .share-social ul li {
    margin-right: 3px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Blog-area.blog-no-sidebar .blog-content-2 .blog-share-comment .blog-share .share-social ul li a {
    font-size: 12px;
    height: 25px;
    line-height: 23px;
    width: 25px; } }

/*-------- 8. Footer style  -----*/
.copyright .footer-logo {
  margin-bottom: 12px;
  margin-top: 41px; }
  @media only screen and (max-width: 767px) {
    .copyright .footer-logo {
      margin-top: 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .copyright .footer-logo {
      margin-top: 0px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .copyright .footer-logo {
      margin-top: 28px; } }

.copyright p {
  color: #313131;
  letter-spacing: 0.9px; }
  .copyright p a {
    color: #313131; }
    .copyright p a:hover {
      color: #a749ff; }

.footer-widget .footer-title h3 {
  font-size: 16px;
  font-weight: 500;
  color: #313131;
  margin: 0 0 16px;
  line-height: 1; }

.footer-widget .footer-list ul li {
  margin: 0 0 11px; }
  .footer-widget .footer-list ul li:last-child {
    margin: 0 0 0px; }
  .footer-widget .footer-list ul li a {
    color: #5d5d5d;
    letter-spacing: 0.3px; }
    .footer-widget .footer-list ul li a:hover {
      color: #a749ff; }

.footer-widget .subscribe-style p {
  color: #7a7a7a;
  margin: 0 0 20px; }

.footer-widget .subscribe-style .subscribe-form input {
  background: transparent;
  font-size: 13px;
  color: #333;
  border: none;
  border-bottom: 2px solid #ebebeb;
  padding: 2px 10px 2px 0; }

.footer-widget .subscribe-style .subscribe-form .mc-news {
  display: none; }

.footer-widget .subscribe-style .subscribe-form .clear button {
  width: auto;
  height: auto;
  color: #313131;
  font-size: 14px;
  padding: 0 0 1px;
  line-height: 1;
  background: none;
  border: none;
  border-bottom: 2px solid #9b9b9c;
  text-transform: uppercase;
  margin: 13px 0 0; }
  .footer-widget .subscribe-style .subscribe-form .clear button:hover {
    color: #a749ff;
    border-bottom: 2px solid #a749ff; }

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-widget.ml-95 {
    margin-left: 20px; }
  .footer-widget.ml-145 {
    margin-left: 50px; } }

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget.ml-95 {
    margin-left: 0px; }
  .footer-widget.ml-145 {
    margin-left: 20px; }
  .footer-widget.ml-30 {
    margin-left: 0px; }
  .footer-widget.ml-ntv5 {
    margin-left: -50px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.ml-95 {
    margin-left: 0px; }
  .footer-widget.ml-145 {
    margin-left: 0px; }
  .footer-widget.ml-30 {
    margin-left: 0px; }
  .footer-widget.ml-ntv5 {
    margin-left: 0px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.ml-95 {
    margin-left: 0px; }
  .footer-widget.ml-145 {
    margin-left: 0px; }
  .footer-widget.ml-30 {
    margin-left: 0px; }
  .footer-widget.ml-ntv5 {
    margin-left: 0px; } }

@media only screen and (max-width: 767px) {
  .footer-widget.ml-95 {
    margin-left: 0px; }
  .footer-widget.ml-145 {
    margin-left: 0px; }
  .footer-widget.ml-30 {
    margin-left: 0px; }
  .footer-widget.ml-ntv5 {
    margin-left: 0px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.ml-30,
  .footer-widget.ml-50,
  .footer-widget.ml-75 {
    margin-left: 0; } }

@media only screen and (max-width: 767px) {
  .footer-widget.ml-30,
  .footer-widget.ml-50,
  .footer-widget.ml-75 {
    margin-left: 0; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.ml-30,
  .footer-widget.ml-50,
  .footer-widget.ml-75 {
    margin-left: 0px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.ml-70 {
    margin-left: -130px; } }

@media only screen and (max-width: 767px) {
  .footer-widget.ml-70 {
    margin-left: 0px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget.ml-70 {
    margin-left: -90px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.ml-70 {
    margin-left: 0px; } }

/* home 4 */
.hm4-footer-padding .container-fluid, .hm4-footer-padding .container-sm, .hm4-footer-padding .container-md, .hm4-footer-padding .container-lg, .hm4-footer-padding .container-xl {
  padding: 0 60px; }
  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .hm4-footer-padding .container-fluid, .hm4-footer-padding .container-sm, .hm4-footer-padding .container-md, .hm4-footer-padding .container-lg, .hm4-footer-padding .container-xl {
      padding: 0 60px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .hm4-footer-padding .container-fluid, .hm4-footer-padding .container-sm, .hm4-footer-padding .container-md, .hm4-footer-padding .container-lg, .hm4-footer-padding .container-xl {
      padding: 0 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hm4-footer-padding .container-fluid, .hm4-footer-padding .container-sm, .hm4-footer-padding .container-md, .hm4-footer-padding .container-lg, .hm4-footer-padding .container-xl {
      padding: 0 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hm4-footer-padding .container-fluid, .hm4-footer-padding .container-sm, .hm4-footer-padding .container-md, .hm4-footer-padding .container-lg, .hm4-footer-padding .container-xl {
      padding: 0 30px; } }
  @media only screen and (max-width: 767px) {
    .hm4-footer-padding .container-fluid, .hm4-footer-padding .container-sm, .hm4-footer-padding .container-md, .hm4-footer-padding .container-lg, .hm4-footer-padding .container-xl {
      padding: 0 15px; } }

.footer-area {
  position: relative; }
  .footer-area.ml-10 {
    margin-left: 0; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .footer-area.ml-70 {
      margin-left: 15px; }
    .footer-area.mr-70 {
      margin-right: 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-area.ml-70 {
      margin-left: 30px; }
    .footer-area.mr-70 {
      margin-right: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-area.ml-70 {
      margin-left: 40px; }
    .footer-area.mr-70 {
      margin-right: 40px; } }
  @media only screen and (max-width: 767px) {
    .footer-area.ml-70 {
      margin-left: 15px; }
    .footer-area.mr-70 {
      margin-right: 15px; } }

/* home 5 */
.footer-top {
  position: relative;
  z-index: 9; }
  .footer-top p {
    color: #fffefe;
    line-height: 28px;
    margin: 31px auto 33px;
    width: 55%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .footer-top p {
        width: 85%; } }
    @media only screen and (max-width: 767px) {
      .footer-top p {
        width: 100%; } }
  .footer-top .footer-social ul {
    display: flex;
    justify-content: center; }
    .footer-top .footer-social ul li {
      margin: 0 23px;
      position: relative;
      line-height: 1; }
      .footer-top .footer-social ul li:before {
        position: absolute;
        content: "";
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #7c7b7b;
        width: 16px;
        height: 2px; }
      .footer-top .footer-social ul li:last-child:before {
        display: none; }
      .footer-top .footer-social ul li a {
        color: #e5e5e5;
        font-size: 16px; }
        .footer-top .footer-social ul li a:hover {
          color: #a749ff; }
  .footer-top .footer-social.footer-social-white ul li:before {
    background-color: #fff; }
  @media only screen and (max-width: 767px) {
    .footer-top.pb-60 {
      padding-bottom: 60px; } }

.footer-bottom {
  position: relative;
  z-index: 9; }

.copyright-2 {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 60px;
  border-top: 1px solid #2b2b2b; }
  .copyright-2 p {
    color: #fff;
    line-height: 1; }
    .copyright-2 p a {
      color: #fff; }
      .copyright-2 p a:hover {
        color: #a749ff; }
  .copyright-2.copyright-white-border {
    border-top: 1px solid #cccccc; }
  .copyright-2.copyright-gray p {
    color: #848484; }
    .copyright-2.copyright-gray p a {
      color: #848484;
      letter-spacing: 0.9px; }
      .copyright-2.copyright-gray p a:hover {
        color: #a749ff; }

.footer-white .footer-top p {
  color: #000; }

.footer-white .footer-top .footer-social ul li a {
  color: #000; }
  .footer-white .footer-top .footer-social ul li a:hover {
    color: #a749ff; }

.footer-white .copyright-2 {
  padding-top: 20px;
  padding-bottom: 23px;
  margin-top: 0;
  border-top: none; }

.footer-border {
  border-top: 1px solid #e2e2e2; }

/*----- 9. Banner style  -------*/
.single-banner {
  position: relative;
  overflow: hidden; }
  .single-banner a img {
    width: 100%;
    transform: scale(1);
    transition: all 0.5s ease 0s; }
  .single-banner:hover img {
    transform: scale(1.2); }
  .single-banner .banner-content {
    position: absolute;
    content: "";
    left: 36px;
    top: 40px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-banner .banner-content {
        left: 20px;
        top: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-content {
        left: 15px;
        top: 10px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-content {
        left: 15px;
        top: 50%;
        transform: translateY(-50%); } }
    .single-banner .banner-content h3 {
      font-size: 36px;
      color: #915342;
      font-weight: 600;
      margin: 0;
      font-family: "Cormorant Garamond"; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner .banner-content h3 {
          font-size: 27px; } }
      @media only screen and (max-width: 767px) {
        .single-banner .banner-content h3 {
          font-size: 30px; } }
    .single-banner .banner-content h4 {
      font-size: 18px;
      color: #010101;
      margin: 3px 0 58px;
      font-weight: 600;
      font-family: "Cormorant Garamond"; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-banner .banner-content h4 {
          margin: 3px 0 25px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-banner .banner-content h4 {
          margin: 3px 0 15px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner .banner-content h4 {
          margin: 3px 0 8px; } }
      @media only screen and (max-width: 767px) {
        .single-banner .banner-content h4 {
          margin: 3px 0 20px; } }
      .single-banner .banner-content h4 span {
        color: #935644;
        font-size: 20px;
        margin: 0 0px 0 2px;
        display: inline-block;
        line-height: 1;
        position: relative;
        top: 2px; }
    .single-banner .banner-content a {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      border: 2px solid #97584a;
      color: #97584a;
      font-size: 14px;
      display: inline-block;
      text-align: center; }
      .single-banner .banner-content a i {
        line-height: 25px; }
      .single-banner .banner-content a:hover {
        color: #a749ff;
        border: 2px solid #a749ff; }
    .single-banner .banner-content.banner-pink a {
      border: 2px solid #ed59a0;
      color: #ed59a0; }
      .single-banner .banner-content.banner-pink a:hover {
        color: #a749ff;
        border: 2px solid #a749ff; }
    .single-banner .banner-content--style2 h3 {
      color: #1c1c1c; }
    .single-banner .banner-content--style2 a {
      color: #c61a32;
      border-color: #c61a32; }
  .single-banner.banner-green-color .banner-content h3 {
    color: #617d05; }
  .single-banner.banner-green-color .banner-content h4 {
    color: #010101; }
    .single-banner.banner-green-color .banner-content h4 span {
      color: #617d05; }
  .single-banner.banner-green-color .banner-content a {
    border: 2px solid #617d05;
    color: #617d05; }
    .single-banner.banner-green-color .banner-content a:hover {
      color: #aabb72;
      border: 2px solid #aabb72; }
  .single-banner.banner-red-color .banner-content h3 {
    color: #df262b; }
  .single-banner.banner-red-color .banner-content h4 {
    color: #010101; }
    .single-banner.banner-red-color .banner-content h4 span {
      color: #df262b; }
  .single-banner.banner-red-color .banner-content a {
    border: 2px solid #df262b;
    color: #df262b; }
    .single-banner.banner-red-color .banner-content a:hover {
      color: #dc6266;
      border: 2px solid #dc6266; }
  .single-banner.banner-shape {
    border: 4px solid #fff; }
  .single-banner .banner-position-hm15-1 {
    position: absolute;
    right: 40px;
    top: 45px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .single-banner .banner-position-hm15-1 {
        top: 25px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-banner .banner-position-hm15-1 {
        top: 20px;
        right: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-position-hm15-1 {
        top: 20px;
        right: 20px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-position-hm15-1 {
        top: 15px;
        right: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-banner .banner-position-hm15-1 {
        top: 30px;
        right: 30px; } }
  .single-banner .banner-position-hm15-2 {
    position: absolute;
    left: 40px;
    top: 45px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .single-banner .banner-position-hm15-2 {
        top: 25px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-banner .banner-position-hm15-2 {
        top: 20px;
        left: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-position-hm15-2 {
        top: 20px;
        left: 20px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-position-hm15-2 {
        top: 15px;
        left: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-banner .banner-position-hm15-2 {
        top: 30px;
        left: 30px; } }
  .single-banner .banner-position-hm17-1 {
    position: absolute;
    right: 105px;
    bottom: 50px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-position-hm17-1 {
        right: 20px;
        bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-position-hm17-1 {
        right: 20px;
        bottom: 20px; } }
  .single-banner .banner-content-3 h3 {
    font-weight: bold;
    font-size: 30px;
    margin: 0;
    line-height: 1;
    color: #111111;
    text-transform: uppercase; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .single-banner .banner-content-3 h3 {
        font-size: 25px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-banner .banner-content-3 h3 {
        font-size: 25px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-content-3 h3 {
        font-size: 22px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-content-3 h3 {
        font-size: 20px; } }
  .single-banner .banner-content-3 p {
    font-size: 18px;
    margin: 11px 0 20px;
    color: #010101; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .single-banner .banner-content-3 p {
        margin: 5px 0 12px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-banner .banner-content-3 p {
        margin: 5px 0 12px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-content-3 p {
        margin: 5px 0 10px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-content-3 p {
        margin: 5px 0 10px; } }
    .single-banner .banner-content-3 p span {
      color: #6eab49; }
  .single-banner .banner-content-3 > a {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 2px solid #6eab49;
    color: #6eab49;
    font-size: 14px;
    display: inline-block;
    text-align: center; }
    .single-banner .banner-content-3 > a:hover {
      border: 2px solid #a749ff;
      color: #a749ff; }
  .single-banner .banner-content-3.pink-banner p {
    color: #010101; }
    .single-banner .banner-content-3.pink-banner p span {
      color: #ed59a0; }
  .single-banner .banner-content-3.pink-banner > a {
    border: 2px solid #ed59a0;
    color: #ed59a0; }
    .single-banner .banner-content-3.pink-banner > a:hover {
      border: 2px solid #a749ff;
      color: #a749ff; }
  .single-banner .banner-content-4 span {
    font-size: 18px;
    color: #6eab49; }
  .single-banner .banner-content-4 h2 {
    font-size: 55px;
    color: #f3993f;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    margin: 15px 0 15px; }
    @media only screen and (min-width: 1200px) and (max-width: 1365px) {
      .single-banner .banner-content-4 h2 {
        font-size: 40px;
        margin: 7px 0 10px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-banner .banner-content-4 h2 {
        font-size: 35px;
        margin: 7px 0 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-content-4 h2 {
        font-size: 45px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-content-4 h2 {
        font-size: 30px;
        margin: 7px 0 10px; } }
  .single-banner .banner-content-4 h5 {
    font-size: 24px;
    color: #000000;
    line-height: 1;
    margin: 0 0 36px;
    letter-spacing: 4.3px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-banner .banner-content-4 h5 {
        font-size: 20px;
        margin: 0 0 20px;
        letter-spacing: 2.3px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-content-4 h5 {
        font-size: 22px;
        letter-spacing: 3.3px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-content-4 h5 {
        font-size: 20px;
        margin: 0 0 20px;
        letter-spacing: 2.3px; } }
  .single-banner .banner-content-4 > a {
    font-size: 14px;
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
    line-height: 1;
    padding: 13px 33px 13px;
    background-color: #6eab49; }
    .single-banner .banner-content-4 > a:hover {
      background-color: #a749ff; }
  .single-banner .banner-content-4.pink-banner span {
    color: #ed59a0; }
  .single-banner .banner-content-4.pink-banner h2 {
    color: #ed59a0; }
  .single-banner .banner-content-4.pink-banner > a {
    background-color: #ed59a0; }
    .single-banner .banner-content-4.pink-banner > a:hover {
      background-color: #a749ff; }
  .single-banner .banner-content-5 {
    position: absolute;
    left: 36px;
    top: 32px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-banner .banner-content-5 {
        left: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-content-5 {
        left: 15px;
        top: 15px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-content-5 {
        left: 15px;
        top: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-banner .banner-content-5 {
        left: 50px;
        top: 50px; } }
    .single-banner .banner-content-5 span {
      font-size: 24px;
      color: #e90042;
      display: block;
      line-height: 1; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner .banner-content-5 span {
          font-size: 20px; } }
    .single-banner .banner-content-5 h3 {
      font-family: "Cormorant Garamond";
      font-size: 36px;
      margin: 9px 0 62px;
      font-weight: 600; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-banner .banner-content-5 h3 {
          margin: 9px 0 25px;
          font-size: 30px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner .banner-content-5 h3 {
          margin: 2px 0 12px;
          font-size: 25px; } }
      @media only screen and (max-width: 767px) {
        .single-banner .banner-content-5 h3 {
          margin: 9px 0 25px;
          font-size: 30px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .single-banner .banner-content-5 h3 {
          font-size: 36px;
          margin: 9px 0 40px; } }
    .single-banner .banner-content-5 a {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      border: 2px solid #e90042;
      color: #e90042;
      font-size: 14px;
      display: inline-block;
      text-align: center; }
      .single-banner .banner-content-5 a:hover {
        border: 2px solid #a749ff;
        color: #a749ff; }
  .single-banner .banner-content-6 {
    position: absolute;
    left: 30px;
    bottom: 30px; }
    .single-banner .banner-content-6 a {
      font-size: 14px;
      color: #fff;
      display: inline-block;
      text-transform: uppercase;
      line-height: 1;
      padding: 13px 33px 13px;
      background-color: #e90042; }
      .single-banner .banner-content-6 a:hover {
        background-color: #a749ff; }
  .single-banner .banner-content-7 {
    position: absolute;
    left: 45px;
    bottom: 30px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-content-7 {
        left: 20px;
        bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-content-7 {
        left: 20px;
        bottom: 20px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-banner .banner-content-7 {
        left: 40px;
        bottom: 40px; } }
    .single-banner .banner-content-7 span {
      font-size: 24px;
      font-weight: 600;
      color: #e90042;
      display: block;
      line-height: 1; }
      @media only screen and (max-width: 767px) {
        .single-banner .banner-content-7 span {
          font-size: 20px; } }
    .single-banner .banner-content-7 h2 {
      font-size: 72px;
      font-weight: 700;
      color: #c3c1c0;
      margin: 9px 0 33px;
      line-height: 1; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-banner .banner-content-7 h2 {
          font-size: 55px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner .banner-content-7 h2 {
          font-size: 40px;
          margin: 9px 0 20px; } }
      @media only screen and (max-width: 767px) {
        .single-banner .banner-content-7 h2 {
          font-size: 35px;
          margin: 9px 0 20px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .single-banner .banner-content-7 h2 {
          font-size: 42px;
          margin: 9px 0 35px; } }
    .single-banner .banner-content-7 > a {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      border: 2px solid #e90042;
      color: #e90042;
      font-size: 14px;
      display: inline-block;
      text-align: center; }
      .single-banner .banner-content-7 > a:hover {
        border: 2px solid #a749ff;
        color: #a749ff; }
  .single-banner .banner-content-8 {
    position: absolute;
    left: 45px;
    bottom: 40px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-banner .banner-content-8 {
        left: 20px;
        bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .single-banner .banner-content-8 {
        left: 20px;
        bottom: 20px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-banner .banner-content-8 {
        left: 30px;
        bottom: 30px; } }
    .single-banner .banner-content-8 span {
      font-size: 24px;
      color: #e90042;
      display: block;
      line-height: 1;
      text-transform: uppercase; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner .banner-content-8 span {
          font-size: 17px; } }
      @media only screen and (max-width: 767px) {
        .single-banner .banner-content-8 span {
          font-size: 17px; } }
    .single-banner .banner-content-8 h2 {
      font-size: 64px;
      color: #000000;
      margin: 10px 0 4px;
      line-height: 50px;
      text-transform: uppercase;
      font-family: "Abril Fatface", cursive; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-banner .banner-content-8 h2 {
          font-size: 55px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner .banner-content-8 h2 {
          font-size: 38px;
          line-height: 30px;
          margin: 5px 0 4px; } }
      @media only screen and (max-width: 767px) {
        .single-banner .banner-content-8 h2 {
          font-size: 32px;
          line-height: 30px;
          margin: 5px 0 4px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .single-banner .banner-content-8 h2 {
          margin: 15px 0 5px; } }
    .single-banner .banner-content-8 p {
      font-size: 20px;
      color: #000000;
      margin: 0px 0 48px;
      line-height: 1;
      text-transform: uppercase;
      font-family: "Abril Fatface", cursive;
      letter-spacing: 6px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner .banner-content-8 p {
          letter-spacing: 5px;
          font-size: 20px;
          margin: 0px 0 27px; } }
      @media only screen and (max-width: 767px) {
        .single-banner .banner-content-8 p {
          letter-spacing: 5px;
          font-size: 17px;
          margin: 0px 0 15px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .single-banner .banner-content-8 p {
          margin: 0px 0 35px; } }
    .single-banner .banner-content-8 > a {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      border: 2px solid #e90042;
      color: #e90042;
      font-size: 14px;
      display: inline-block;
      text-align: center; }
      .single-banner .banner-content-8 > a:hover {
        border: 2px solid #a749ff;
        color: #a749ff; }

/* banner hm4 */
.banner-area-2 .container-fluid, .banner-area-2 .container-sm, .banner-area-2 .container-md, .banner-area-2 .container-lg, .banner-area-2 .container-xl {
  padding-right: 10px;
  padding-left: 10px;
  padding-right: 10px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area-2 .container-fluid, .banner-area-2 .container-sm, .banner-area-2 .container-md, .banner-area-2 .container-lg, .banner-area-2 .container-xl {
      padding-right: 30px;
      padding-left: 30px; } }
  @media only screen and (max-width: 767px) {
    .banner-area-2 .container-fluid, .banner-area-2 .container-sm, .banner-area-2 .container-md, .banner-area-2 .container-lg, .banner-area-2 .container-xl {
      padding-right: 15px;
      padding-left: 15px; } }

@media only screen and (max-width: 767px) {
  .banner-area-2.pb-85 {
    padding-bottom: 42px; } }

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-area-2.banner-area-2 .single-banner .banner-content {
    top: 15px;
    left: 15px; }
    .banner-area-2.banner-area-2 .single-banner .banner-content h3 {
      font-size: 25px; }
    .banner-area-2.banner-area-2 .single-banner .banner-content h4 {
      margin: 3px 0 10px; } }

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-area-2.banner-area-2 .single-banner .banner-content {
    top: 15px;
    left: 15px; }
    .banner-area-2.banner-area-2 .single-banner .banner-content h3 {
      font-size: 25px; }
    .banner-area-2.banner-area-2 .single-banner .banner-content h4 {
      margin: 3px 0 10px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area-2.banner-area-2 .single-banner .banner-content {
    top: 25px; }
    .banner-area-2.banner-area-2 .single-banner .banner-content h3 {
      font-size: 30px; }
    .banner-area-2.banner-area-2 .single-banner .banner-content h4 {
      margin: 3px 0 40px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area-2.banner-area-2.pt-10 {
    padding-top: 30px; } }

/* banner hm9 */
.single-banner-2 {
  position: relative;
  overflow: hidden; }
  .single-banner-2 a img {
    width: 100%;
    transform: scale(1);
    transition: all 0.5s ease 0s; }
  .single-banner-2 .banner-content-2 {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%); }
    @media only screen and (max-width: 767px) {
      .single-banner-2 .banner-content-2 {
        left: 20px; } }
    .single-banner-2 .banner-content-2 h3 {
      font-size: 48px;
      color: #915342;
      margin: 0;
      font-family: "Cormorant Garamond"; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner-2 .banner-content-2 h3 {
          font-size: 35px; } }
      @media only screen and (max-width: 767px) {
        .single-banner-2 .banner-content-2 h3 {
          font-size: 33px; } }
    .single-banner-2 .banner-content-2 h4 {
      font-size: 24px;
      color: #010101;
      font-weight: 500;
      font-family: "Cormorant Garamond";
      margin: 22px 0 94px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-banner-2 .banner-content-2 h4 {
          margin: 22px 0 34px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner-2 .banner-content-2 h4 {
          margin: 15px 0 25px; } }
      @media only screen and (max-width: 767px) {
        .single-banner-2 .banner-content-2 h4 {
          margin: 10px 0 20px; } }
      .single-banner-2 .banner-content-2 h4 span {
        color: #935644; }
    .single-banner-2 .banner-content-2 a {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      border: 2px solid #97584a;
      color: #97584a;
      font-size: 14px;
      display: inline-block;
      text-align: center; }
      .single-banner-2 .banner-content-2 a:hover {
        color: #000;
        border: 2px solid #000; }
    .single-banner-2 .banner-content-2.jewellery-banner h3 {
      font-weight: 600; }
    .single-banner-2 .banner-content-2--style2 h3 {
      color: #1c1c1c;
      font-size: 36px;
      font-weight: 500; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-banner-2 .banner-content-2--style2 h3 {
          font-size: 30px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner-2 .banner-content-2--style2 h3 {
          font-size: 30px; } }
      @media only screen and (min-width: 320px) and (max-width: 479px) {
        .single-banner-2 .banner-content-2--style2 h3 {
          font-size: 30px; } }
    .single-banner-2 .banner-content-2--style2 h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 70px; }
      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
        .single-banner-2 .banner-content-2--style2 h4 {
          margin-bottom: 40px; } }
      @media only screen and (max-width: 767px) {
        .single-banner-2 .banner-content-2--style2 h4 {
          margin-bottom: 40px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-banner-2 .banner-content-2--style2 h4 {
          margin: 10px 0; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-banner-2 .banner-content-2--style2 h4 {
          margin: 10px 0; } }
      @media only screen and (min-width: 320px) and (max-width: 479px) {
        .single-banner-2 .banner-content-2--style2 h4 {
          margin: 0; } }
    .single-banner-2 .banner-content-2--style2 a {
      color: #1c1c1c; }
    .single-banner-2 .banner-content-2--style2--violet a {
      color: #ab87cd;
      border-color: #ab87cd; }
    .single-banner-2 .banner-content-2--style2--pink a {
      color: #ed59a0;
      border-color: #ed59a0; }
    .single-banner-2 .banner-content-2--style3 h3 {
      color: #1c1c1c;
      font-size: 36px;
      font-weight: 600; }
    .single-banner-2 .banner-content-2--style3 h4 {
      font-size: 18px;
      font-weight: 600;
      margin: 3px 0 58px; }
    .single-banner-2 .banner-content-2--style3 a {
      color: #c61a32;
      border-color: #c61a32; }
  .single-banner-2:hover a img {
    transform: scale(1.1); }
  .single-banner-2.align_right .banner-content-2 {
    position: absolute;
    text-align: right;
    right: 40px;
    left: auto;
    top: 50%;
    transform: translateY(-50%); }

@media only screen and (max-width: 767px) {
  .banner-area.pt-80 {
    padding-top: 60px; }
  .save-money-area .pt-100 {
    padding-top: 50px; }
  .save-money-area .pb-100 {
    padding-bottom: 50px; } }

.save-money-content {
  display: flex;
  align-items: center;
  margin: 0 0 0 50px; }
  @media only screen and (max-width: 767px) {
    .save-money-content {
      display: block;
      margin: 0 20px 0 20px;
      text-align: center; } }
  .save-money-content h2 {
    font-size: 36px;
    color: #010101;
    font-weight: bold;
    margin: 0 50px 0 0;
    line-height: 48px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .save-money-content h2 {
        font-size: 30px;
        margin: 0 30px 0 0; } }
    @media only screen and (max-width: 767px) {
      .save-money-content h2 {
        font-size: 20px;
        margin: 0 0px 20px 0;
        line-height: 30px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .save-money-content h2 {
        font-size: 25px;
        margin: 0 0px 20px 0;
        line-height: 30px; } }
  .save-money-content .save-money-btn a {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    background-color: #ed59a0;
    line-height: 1;
    padding: 13px 40px 15px; }
    .save-money-content .save-money-btn a:hover {
      background-color: #a749ff; }

.react-player {
  height: 400px; }

/*------ 10. Suscribe style  ------*/
.subscribe-style-2 h2 {
  font-size: 48px;
  color: #010101;
  margin: 0 0 23px; }
  @media only screen and (max-width: 767px) {
    .subscribe-style-2 h2 {
      font-size: 30px;
      margin: 0 0 12px; } }

.subscribe-style-2 p {
  font-size: 16px;
  color: #696969;
  margin: 0 0 23px; }

.subscribe-style-2 .subscribe-form-2 input {
  background: transparent;
  border: none;
  height: 45px;
  padding: 2px 50px 2px 0;
  font-size: 14px;
  color: #333;
  border-bottom: 2px solid #e2e2e2; }

.subscribe-style-2 .subscribe-form-2 .mc-news {
  display: none; }

.subscribe-style-2 .subscribe-form-2 .mc-form {
  position: relative; }

.subscribe-style-2 .subscribe-form-2 .clear-2 {
  bottom: 0;
  display: inline-block;
  position: absolute;
  right: 0; }
  .subscribe-style-2 .subscribe-form-2 .clear-2 button {
    background: none;
    border: none;
    height: 45px;
    padding: 0;
    font-size: 30px;
    color: #cacaca;
    transition: 0.3s; }
    @media only screen and (max-width: 767px) {
      .subscribe-style-2 .subscribe-form-2 .clear-2 button {
        font-size: 20px; } }
    .subscribe-style-2 .subscribe-form-2 .clear-2 button:hover {
      color: #333; }

@media only screen and (max-width: 767px) {
  .subscribe-area-3.pb-100 {
    padding-bottom: 60px; } }

.subscribe-style-3 h2 {
  font-size: 48px;
  color: #010101;
  margin: 0 0 23px; }
  @media only screen and (max-width: 767px) {
    .subscribe-style-3 h2 {
      font-size: 35px;
      margin: 0 0 12px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .subscribe-style-3 h2 {
      font-size: 40px; } }

.subscribe-style-3 p {
  font-size: 16px;
  color: #696969;
  margin: 0;
  line-height: 1.5; }

.subscribe-style-3 .subscribe-form-3 input {
  background: transparent;
  border: none;
  border-bottom: 2px solid #e2e2e2;
  text-align: center;
  color: #333;
  height: 46px; }

.subscribe-style-3 .subscribe-form-3 .mc-news {
  display: none; }

.subscribe-style-3 .subscribe-form-3 .clear-3 {
  margin-top: 40px; }
  .subscribe-style-3 .subscribe-form-3 .clear-3 input,
  .subscribe-style-3 .subscribe-form-3 .clear-3 button {
    width: auto;
    height: auto;
    color: #fff;
    background: none;
    border: none;
    background-color: #070508;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 17px 55px;
    border-radius: 5px; }
    .subscribe-style-3 .subscribe-form-3 .clear-3 input:hover,
    .subscribe-style-3 .subscribe-form-3 .clear-3 button:hover {
      background-color: #a749ff; }
  .subscribe-style-3 .subscribe-form-3 .clear-3.red-subscribe input,
  .subscribe-style-3 .subscribe-form-3 .clear-3.red-subscribe button {
    background-color: #df262b; }
    .subscribe-style-3 .subscribe-form-3 .clear-3.red-subscribe input:hover,
    .subscribe-style-3 .subscribe-form-3 .clear-3.red-subscribe button:hover {
      background-color: #a749ff; }
  .subscribe-style-3 .subscribe-form-3 .clear-3.green-subscribe input,
  .subscribe-style-3 .subscribe-form-3 .clear-3.green-subscribe button {
    background-color: #6eab49; }
    .subscribe-style-3 .subscribe-form-3 .clear-3.green-subscribe input:hover,
    .subscribe-style-3 .subscribe-form-3 .clear-3.green-subscribe button:hover {
      background-color: #a749ff; }
  .subscribe-style-3 .subscribe-form-3 .clear-3.dark-red-subscribe input,
  .subscribe-style-3 .subscribe-form-3 .clear-3.dark-red-subscribe button {
    background-color: #c61a32;
    border-radius: 50px; }
    .subscribe-style-3 .subscribe-form-3 .clear-3.dark-red-subscribe input:hover,
    .subscribe-style-3 .subscribe-form-3 .clear-3.dark-red-subscribe button:hover {
      background-color: #a749ff; }
  .subscribe-style-3 .subscribe-form-3 .clear-3.red-2-subscribe input,
  .subscribe-style-3 .subscribe-form-3 .clear-3.red-2-subscribe button {
    background-color: #e90042; }
    .subscribe-style-3 .subscribe-form-3 .clear-3.red-2-subscribe input:hover,
    .subscribe-style-3 .subscribe-form-3 .clear-3.red-2-subscribe button:hover {
      background-color: #a749ff; }
  .subscribe-style-3 .subscribe-form-3 .clear-3.pink-subscribe input,
  .subscribe-style-3 .subscribe-form-3 .clear-3.pink-subscribe button {
    background-color: #ed59a0; }
    .subscribe-style-3 .subscribe-form-3 .clear-3.pink-subscribe input:hover,
    .subscribe-style-3 .subscribe-form-3 .clear-3.pink-subscribe button:hover {
      background-color: #a749ff; }

/*------- 11. About style ------ */
.welcome-content h5 {
  font-weight: 500;
  font-size: 16px;
  color: #666;
  margin: 0;
  letter-spacing: 0.3px; }

.welcome-content h1 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin: 8px 0 30px;
  color: #433f3f;
  position: relative;
  display: inline-block;
  padding: 0 0 23px;
  line-height: 1; }
  .welcome-content h1:before {
    position: absolute;
    background-color: #070508;
    height: 3px;
    width: 70px;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto; }

.welcome-content p {
  font-size: 16px;
  color: #4b4b4b;
  line-height: 30px;
  width: 66%;
  margin: 0 auto; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .welcome-content p {
      width: 80%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .welcome-content p {
      width: 95%; } }
  @media only screen and (max-width: 767px) {
    .welcome-content p {
      width: 100%;
      font-size: 15px; } }

/* about page */
.single-count .count-icon i {
  font-size: 50px;
  line-height: 1;
  display: inline-block; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-count .count-icon i {
      font-size: 45px; } }
  @media only screen and (max-width: 767px) {
    .single-count .count-icon i {
      font-size: 45px; } }

.single-count h2 > span {
  color: #a749ff;
  font-size: 40px;
  font-weight: bold;
  margin: 25px 0 12px;
  display: inline-block; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-count h2 > span {
      margin: 10px 0 8px;
      font-size: 35px; } }
  @media only screen and (max-width: 767px) {
    .single-count h2 > span {
      margin: 6px 0 8px;
      font-size: 35px; } }

.single-count span {
  color: #333;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500; }

.team-wrapper {
  background-color: #f7f7f7; }
  .team-wrapper .team-img {
    position: relative; }
    .team-wrapper .team-img img {
      width: 100%; }
    .team-wrapper .team-img .team-action {
      background: #fff none repeat scroll 0 0;
      left: 0;
      opacity: 0;
      padding: 10px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 70%;
      transform: translateY(-50%);
      transition: all 0.6s ease 0s;
      width: 100%;
      z-index: 5; }
      .team-wrapper .team-img .team-action a {
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        width: 30px;
        margin: 0 3px; }
        .team-wrapper .team-img .team-action a.facebook {
          border: 1px solid #3b5998;
          background-color: #3b5998; }
          .team-wrapper .team-img .team-action a.facebook:hover {
            background-color: transparent;
            color: #3b5998; }
        .team-wrapper .team-img .team-action a.twitter {
          border: 1px solid #55acee;
          background-color: #55acee; }
          .team-wrapper .team-img .team-action a.twitter:hover {
            background-color: transparent;
            color: #55acee; }
        .team-wrapper .team-img .team-action a.instagram {
          border: 1px solid #c32aa3;
          background-color: #c32aa3; }
          .team-wrapper .team-img .team-action a.instagram:hover {
            background-color: transparent;
            color: #c32aa3; }
  .team-wrapper .team-content {
    padding: 20px 10px 23px; }
    .team-wrapper .team-content h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 3px;
      text-transform: capitalize; }
    .team-wrapper .team-content span {
      font-size: 15px;
      font-style: italic; }
  .team-wrapper:hover .team-action {
    top: 50%;
    opacity: 1; }

.single-mission h3 {
  font-size: 25px;
  font-weight: 500;
  margin: 0 0 20px;
  text-transform: capitalize;
  line-height: 22px;
  color: #333; }
  @media only screen and (max-width: 767px) {
    .single-mission h3 {
      margin: 0 0 10px; } }

.single-mission p {
  margin: 0; }

@media only screen and (max-width: 767px) {
  .about-brand-logo.pb-100 {
    padding-bottom: 60px; } }

/*----- 12. image slider style  -----*/
.single-image {
  overflow: hidden; }
  .single-image a img {
    width: 100%;
    transition: all 0.5s ease 0s;
    transform: scale(1); }
  .single-image:hover a img {
    transform: scale(1.1); }

/*----- 13. Testimonial style  ------*/
.single-testimonial img {
  border-radius: 50%; }

.single-testimonial p {
  line-height: 32px;
  color: #575757;
  font-size: 16px;
  font-weight: 500;
  margin: 32px 0 0px;
  font-style: italic; }
  @media only screen and (max-width: 767px) {
    .single-testimonial p {
      font-size: 15px; } }

.single-testimonial .client-info {
  margin: 20px 0 0; }
  .single-testimonial .client-info i {
    font-size: 26px;
    color: #666666; }
  .single-testimonial .client-info h5 {
    font-size: 14px;
    color: #010101;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 22px 0 3px; }
  .single-testimonial .client-info span {
    font-size: 14px;
    color: #010101;
    letter-spacing: 1.5px; }

.testimonial-active .swiper-button-next {
  right: 0 !important; }

.testimonial-active .swiper-button-prev {
  left: 0 !important; }

.testimonial-active:hover .ht-swiper-button-nav {
  visibility: visible;
  opacity: 1; }

.testimonial-area:hover .nav-style-1.owl-carousel > .owl-nav div {
  opacity: 1;
  visibility: visible; }

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-area.ml-70 {
    margin-left: 15px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-area.ml-70 {
    margin-left: 30px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-area.ml-70 {
    margin-left: 40px; } }

@media only screen and (max-width: 767px) {
  .testimonial-area.ml-70 {
    margin-left: 15px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-area.ml-70 {
    margin-left: 30px; } }

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-area.mr-70 {
    margin-right: 15px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-area.mr-70 {
    margin-right: 30px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-area.mr-70 {
    margin-right: 40px; } }

@media only screen and (max-width: 767px) {
  .testimonial-area.mr-70 {
    margin-right: 15px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-area.mr-70 {
    margin-right: 30px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-area.mt-195 {
    margin-top: 125px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-area.mt-195 {
    margin-top: 0px;
    padding-top: 35px; } }

@media only screen and (max-width: 767px) {
  .testimonial-area.mt-195 {
    margin-top: 0px;
    padding-top: 40px; } }

.testimonial-img-2 {
  margin-top: -197px;
  overflow: hidden; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-img-2 {
      margin-top: -130px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-img-2 {
      margin-top: 0px; } }
  @media only screen and (max-width: 767px) {
    .testimonial-img-2 {
      margin-top: 30px; } }
  .testimonial-img-2 img {
    width: 100%; }

.single-testimonial-2 p {
  color: #fff;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  font-style: italic; }

.single-testimonial-2 .client-info {
  margin: 18px 0 0; }
  .single-testimonial-2 .client-info i {
    color: #fff;
    font-size: 26px; }
  .single-testimonial-2 .client-info h5 {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 20px 0 6px;
    color: #fff;
    line-height: 1; }
  .single-testimonial-2 .client-info span {
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 1.5px;
    margin: 0;
    color: #fff;
    line-height: 1; }

.single-testimonial-2.testi-paragraph-mrg p {
  margin: 32px 0 0; }

.testimonial-active-2 .owl-dots {
  text-align: center;
  margin: 15px 0 0; }
  .testimonial-active-2 .owl-dots .owl-dot {
    display: inline-block;
    margin: 0 5px; }
    .testimonial-active-2 .owl-dots .owl-dot span {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #fff;
      border: 2px solid transparent;
      display: inline-block; }
    .testimonial-active-2 .owl-dots .owl-dot.active span {
      background-color: transparent;
      border: 2px solid #fff; }

/*------- 14. Brand logo style  -------*/
.brand-logo-active.owl-carousel .owl-item img,
.brand-logo-active-2.owl-carousel .owl-item img {
  display: inline-block;
  width: auto; }

.brand-logo-active .single-brand-logo,
.brand-logo-active .single-brand-logo2,
.brand-logo-active-2 .single-brand-logo,
.brand-logo-active-2 .single-brand-logo2 {
  text-align: center; }
  .brand-logo-active .single-brand-logo img,
  .brand-logo-active .single-brand-logo2 img,
  .brand-logo-active-2 .single-brand-logo img,
  .brand-logo-active-2 .single-brand-logo2 img {
    filter: grayscale(100%);
    transition: all 0.5s ease 0s; }
  .brand-logo-active .single-brand-logo:hover img,
  .brand-logo-active .single-brand-logo2:hover img,
  .brand-logo-active-2 .single-brand-logo:hover img,
  .brand-logo-active-2 .single-brand-logo2:hover img {
    filter: grayscale(0%); }

.brand-logo-wrap {
  padding: 100px 90px; }
  @media only screen and (max-width: 767px) {
    .brand-logo-wrap {
      padding: 50px 20px; } }

/*------ 15. Timer style  -----*/
.funfact-area.bg-img {
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .funfact-area.bg-img {
      background-position: 15%; }
      .funfact-area.bg-img:before {
        content: "";
        background-color: #fff;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        opacity: 0.7; } }
  @media only screen and (max-width: 767px) {
    .funfact-area.bg-img {
      background-position: 15%; }
      .funfact-area.bg-img:before {
        content: "";
        background-color: #fff;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        opacity: 0.7; } }

.funfact-content {
  position: relative;
  z-index: 9; }
  .funfact-content h2 {
    font-size: 48px;
    color: #010101;
    margin: 0; }
    @media only screen and (max-width: 767px) {
      .funfact-content h2 {
        font-size: 32px; } }
  .funfact-content .timer {
    text-align: center;
    margin: 65px 0 0; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .funfact-content .timer {
        margin: 44px 0 0; } }
    @media only screen and (max-width: 767px) {
      .funfact-content .timer {
        margin: 24px 0 0; } }
    .funfact-content .timer span {
      display: inline-block;
      font-size: 48px;
      color: #555;
      font-weight: 300;
      margin: 0 32px;
      min-width: 75px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .funfact-content .timer span {
          margin: 0 15px;
          font-size: 40px; } }
      @media only screen and (max-width: 767px) {
        .funfact-content .timer span {
          margin: 0 5px;
          font-size: 22px;
          min-width: 55px; } }
      .funfact-content .timer span p {
        font-size: 18px;
        font-weight: 500;
        color: #555;
        text-transform: uppercase;
        margin: 34px 0 0; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .funfact-content .timer span p {
            margin: 24px 0 0; } }
        @media only screen and (max-width: 767px) {
          .funfact-content .timer span p {
            margin: 12px 0 0;
            font-size: 14px; } }
  .funfact-content .funfact-btn {
    margin-top: 43px; }
    @media only screen and (max-width: 767px) {
      .funfact-content .funfact-btn {
        margin-top: 23px; } }
    .funfact-content .funfact-btn a {
      display: inline-block;
      background-color: #000;
      color: #fff;
      line-height: 1;
      padding: 16px 56px;
      border: 1px solid transparent;
      z-index: 1; }
      .funfact-content .funfact-btn a:hover {
        border: 1px solid #a749ff; }
    .funfact-content .funfact-btn.funfact-btn-red a {
      background-color: #c61a32; }
      .funfact-content .funfact-btn.funfact-btn-red a:hover {
        border: 1px solid #a749ff; }
    .funfact-content .funfact-btn.funfact-btn-violet a {
      background-color: #ab87cd; }
      .funfact-content .funfact-btn.funfact-btn-violet a:hover {
        border: 1px solid #a749ff; }
    .funfact-content .funfact-btn.funfact-btn-green a {
      background-color: #6eab49; }
      .funfact-content .funfact-btn.funfact-btn-green a:hover {
        border: 1px solid #a749ff; }
    .funfact-content .funfact-btn--round-shape a {
      border-radius: 30px;
      overflow: hidden; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .funfact-content.mt-90 {
      margin-top: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .funfact-content.funfact-res h2 {
      font-size: 35px; }
    .funfact-content.funfact-res .timer {
      margin: 30px 0 0; }
      .funfact-content.funfact-res .timer span {
        font-size: 30px;
        margin: 0 9px; }
        .funfact-content.funfact-res .timer span p {
          font-size: 15px;
          margin: 14px 0 0; }
    .funfact-content.mt-90 {
      margin-top: 0; } }
  @media only screen and (max-width: 767px) {
    .funfact-content.mt-90 {
      margin-top: 30px; } }

.gift-img {
  margin: 0 10px; }
  .gift-img a {
    display: block; }
    .gift-img a img {
      width: 100%; }

.fruits-deal-img {
  margin: 0 90px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .fruits-deal-img {
      margin: 0px 50px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .fruits-deal-img {
      margin: 0px 0px 0; } }
  @media only screen and (max-width: 767px) {
    .fruits-deal-img {
      margin: 30px 0px 0; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .fruits-deal-img {
      margin: 30px 30px 0; } }
  .fruits-deal-img a {
    display: block; }
    .fruits-deal-img a img {
      width: 100%; }

@media only screen and (max-width: 767px) {
  .funfact-image {
    margin: 30px 0 0; } }

/*------ 16. Breadcrumb style  --------*/
.breadcrumb-content ul li {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: #5b5858;
  margin-right: 18px;
  text-transform: uppercase;
  position: relative; }
  .breadcrumb-content ul li::before {
    position: absolute;
    width: 18px;
    height: 1px;
    background-color: #5b5858;
    content: "";
    right: -21px;
    top: 12px;
    z-index: 99;
    transform: rotate(115deg); }
  .breadcrumb-content ul li:last-child::before {
    display: none; }
  .breadcrumb-content ul li a {
    color: #5b5858; }
    .breadcrumb-content ul li a:hover {
      color: darkturquoise; }

.breadcrumb-content span > span {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase; }
  .breadcrumb-content span > span > a {
    font-weight: 500;
    color: #5b5858; }
    .breadcrumb-content span > span > a:hover {
      color: darkturquoise; }
  .breadcrumb-content span > span > span {
    margin-right: 0;
    margin: 0 15px; }

/*---- 17. Sidebar style  ------*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-style.mr-30 {
    margin-right: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-style {
    margin-top: 30px; }
    .sidebar-style.mr-30 {
      margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .sidebar-style {
    margin-top: 30px; }
    .sidebar-style.mr-30 {
      margin-right: 0; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-style.ml-30 {
    margin-left: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-style {
    margin-top: 30px; }
    .sidebar-style.ml-30 {
      margin-left: 0; } }

@media only screen and (max-width: 767px) {
  .sidebar-style {
    margin-top: 30px; }
    .sidebar-style.ml-30 {
      margin-left: 0; } }

.sidebar-widget h4.pro-sidebar-title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 0; }

.sidebar-widget .sidebar-widget-list ul li {
  align-items: center;
  position: relative;
  padding: 0px 0 15px; }
  .sidebar-widget .sidebar-widget-list ul li:last-child {
    padding: 0px 0 0px; }
  .sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 999;
    width: auto; }
    .sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark {
      background-color: #fa6bff;
      border: 2px solid #fa6bff; }
    .sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark::after {
      display: block; }
  .sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark {
    position: absolute;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 2px solid #888888;
    border-radius: 3px; }
    .sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
      content: "";
      position: absolute;
      display: none; }
    .sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
      left: 3px;
      top: 0px;
      width: 5px;
      height: 8px;
      border: solid white;
      border-top-width: medium;
      border-right-width: medium;
      border-bottom-width: medium;
      border-left-width: medium;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg); }
  .sidebar-widget .sidebar-widget-list ul li a,
  .sidebar-widget .sidebar-widget-list ul li button {
    display: block;
    background: none;
    padding: 0;
    padding-left: 30px;
    border: none;
    width: 100%;
    text-align: left;
    position: relative;
    text-transform: capitalize; }
    .sidebar-widget .sidebar-widget-list ul li a span,
    .sidebar-widget .sidebar-widget-list ul li button span {
      color: #9a9a9a;
      font-weight: 500;
      background-color: #eeeeee;
      width: 30px;
      height: 20px;
      display: inline-block;
      text-align: center;
      line-height: 20px;
      border-radius: 50px;
      transition: all 0.3s ease 0s;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0; }
    .sidebar-widget .sidebar-widget-list ul li a:hover,
    .sidebar-widget .sidebar-widget-list ul li button:hover {
      color: darkturquoise; }
    .sidebar-widget .sidebar-widget-list ul li a.active span,
    .sidebar-widget .sidebar-widget-list ul li button.active span {
      color: #fff;
      background-color: black; }
    .sidebar-widget .sidebar-widget-list ul li a.active > .checkmark,
    .sidebar-widget .sidebar-widget-list ul li button.active > .checkmark {
      background-color: black;
      border-color: black; }
      .sidebar-widget .sidebar-widget-list ul li a.active > .checkmark:after,
      .sidebar-widget .sidebar-widget-list ul li button.active > .checkmark:after {
        display: block; }

.sidebar-widget .sidebar-widget-list--blog ul li .sidebar-widget-list-left .checkmark {
  top: 5px; }

.sidebar-widget .sidebar-widget-tag ul li {
  display: inline-block;
  margin: 0 10px 15px 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar-widget .sidebar-widget-tag ul li {
      margin: 0 5px 15px 0; } }
  .sidebar-widget .sidebar-widget-tag ul li a,
  .sidebar-widget .sidebar-widget-tag ul li button {
    color: #424242;
    display: inline-block;
    background: none;
    border: none;
    background-color: #f6f6f6;
    padding: 9px 17px 12px;
    text-transform: capitalize;
    line-height: 1;
    border-radius: 50px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .sidebar-widget .sidebar-widget-tag ul li a,
      .sidebar-widget .sidebar-widget-tag ul li button {
        padding: 9px 13px 12px; } }
    .sidebar-widget .sidebar-widget-tag ul li a:hover, .sidebar-widget .sidebar-widget-tag ul li a.active,
    .sidebar-widget .sidebar-widget-tag ul li button:hover,
    .sidebar-widget .sidebar-widget-tag ul li button.active {
      background-color: #a749ff;
      color: #fff; }

.pro-sidebar-search .pro-sidebar-search-form {
  position: relative; }
  .pro-sidebar-search .pro-sidebar-search-form input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #e6e6e6;
    color: #000;
    font-size: 14px;
    height: 43px;
    padding: 2px 55px 2px 18px; }
  .pro-sidebar-search .pro-sidebar-search-form button {
    background: transparent none repeat scroll 0 0;
    border-color: #a1a5aa;
    border-image: none;
    border-style: none none none solid;
    border-width: medium medium medium 1px;
    padding: 0 15px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #000;
    transition: all 0.3s ease 0s;
    font-size: 20px; }
    .pro-sidebar-search .pro-sidebar-search-form button:hover {
      color: #a749ff; }

.single-sidebar-blog {
  border-bottom: 1px solid #dee0e4;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px;
  padding: 0 0 20px;
  align-items: center; }
  .single-sidebar-blog:last-child {
    border-bottom: none; }
  .single-sidebar-blog .sidebar-blog-img {
    flex: 0 0 70px;
    margin: 0 20px 0 0; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-sidebar-blog .sidebar-blog-img {
        margin: 0 8px 0 0; } }
    .single-sidebar-blog .sidebar-blog-img img {
      width: 100%; }
  .single-sidebar-blog .sidebar-blog-content span {
    color: #333;
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1; }
  .single-sidebar-blog .sidebar-blog-content h4 {
    font-size: 14px;
    margin: 5px 0 0; }

/*---- 18. Shop page  -------*/
.shop-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .shop-top-bar {
      display: block; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .shop-top-bar {
      display: flex; } }
  .shop-top-bar .select-shoing-wrap {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .shop-top-bar .select-shoing-wrap {
        display: block; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .shop-top-bar .select-shoing-wrap {
        display: flex; } }
    .shop-top-bar .select-shoing-wrap .shop-select {
      margin-right: 50px; }
      .shop-top-bar .select-shoing-wrap .shop-select select {
        border: 1px solid #e6e6e6;
        padding: 5px;
        font-size: 14px;
        color: #606060;
        min-width: 143px; }
    .shop-top-bar .select-shoing-wrap p {
      color: #606060;
      margin: 0; }
      @media only screen and (max-width: 767px) {
        .shop-top-bar .select-shoing-wrap p {
          margin: 5px 0 10px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .shop-top-bar .select-shoing-wrap p {
          margin: 0px; } }
  .shop-top-bar .shop-tab a,
  .shop-top-bar .shop-tab button {
    color: #606060;
    font-size: 18px;
    margin-left: 20px;
    background: none;
    border: none;
    padding: 0; }
    .shop-top-bar .shop-tab a:first-child,
    .shop-top-bar .shop-tab button:first-child {
      margin-left: 0; }
    .shop-top-bar .shop-tab a.active,
    .shop-top-bar .shop-tab button.active {
      color: #fa6bff; }

.shop-list-wrap .product-wrap:hover .hover-img {
  opacity: inherit;
  visibility: inherit;
  transform: none; }

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 20px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 0px; } }

.shop-list-wrap .shop-list-content h3 {
  font-size: 24px;
  color: #010101;
  margin: 0; }
  @media only screen and (max-width: 767px) {
    .shop-list-wrap .shop-list-content h3 {
      font-size: 20px;
      line-height: 20px; } }
  .shop-list-wrap .shop-list-content h3 a {
    color: #010101; }
    .shop-list-wrap .shop-list-content h3 a:hover {
      color: darkturquoise; }

.shop-list-wrap .shop-list-content .product-list-price {
  margin: 13px 0 22px; }
  @media only screen and (max-width: 767px) {
    .shop-list-wrap .shop-list-content .product-list-price {
      margin: 13px 0 13px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop-list-wrap .shop-list-content .product-list-price {
      margin: 13px 0 19px; } }
  .shop-list-wrap .shop-list-content .product-list-price span {
    color: #fe5252;
    font-size: 24px;
    display: inline-block;
    line-height: 1; }
    .shop-list-wrap .shop-list-content .product-list-price span.old {
      text-decoration: line-through;
      font-size: 18px;
      color: #cfcfcf;
      margin-left: 15px; }

.shop-list-wrap .shop-list-content .rating-review {
  display: flex;
  align-items: center; }
  .shop-list-wrap .shop-list-content .rating-review .product-list-rating i {
    font-size: 17px;
    color: #5f5d5d;
    margin: 0 3px 0; }
    .shop-list-wrap .shop-list-content .rating-review .product-list-rating i.yellow {
      color: #ffa900; }
  .shop-list-wrap .shop-list-content .rating-review a {
    margin-left: 40px;
    position: relative;
    font-size: 15px;
    color: #ffcf75;
    line-height: 1; }
    .shop-list-wrap .shop-list-content .rating-review a:before {
      position: absolute;
      content: "";
      left: -21px;
      top: 0;
      background-color: #d1d1d1;
      width: 1px;
      height: 15px; }

.shop-list-wrap .shop-list-content p {
  margin: 15px 0 40px;
  line-height: 28px;
  font-size: 15px;
  color: #8f8f8f; }
  @media only screen and (max-width: 767px) {
    .shop-list-wrap .shop-list-content p {
      margin: 12px 0 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop-list-wrap .shop-list-content p {
      margin: 12px 0 20px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shop-list-wrap .shop-list-content p {
      margin: 15px 0 20px; } }

.shop-list-wrap .shop-list-content .shop-list-btn a,
.shop-list-wrap .shop-list-content .shop-list-btn button {
  font-size: 14px;
  color: #343538;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
  background-color: #343538;
  color: #fff;
  padding: 14px 32px 15px;
  border: 1px solid transparent;
  z-index: 1; }
  @media only screen and (max-width: 767px) {
    .shop-list-wrap .shop-list-content .shop-list-btn a,
    .shop-list-wrap .shop-list-content .shop-list-btn button {
      padding: 10px 20px 11px;
      font-size: 13px; } }
  .shop-list-wrap .shop-list-content .shop-list-btn a:hover,
  .shop-list-wrap .shop-list-content .shop-list-btn button:hover {
    border: 1px solid darkturquoise; }

.shop-list-wrap:hover .product-wrap .hover-img {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 0); }

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-30 {
    margin-top: 10px; } }

.pro-pagination-style ul li {
  display: inline-block;
  margin: 0 4px; }
  .pro-pagination-style ul li a,
  .pro-pagination-style ul li button {
    display: inline-block;
    width: 43px;
    height: 43px;
    text-align: center;
    line-height: 43px;
    font-size: 16px;
    background: none;
    border: none;
    border-radius: 100% !important;
    padding: 0;
    color: black;
    box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1); }
    .pro-pagination-style ul li a:hover,
    .pro-pagination-style ul li button:hover {
      background-color: darkturquoise;
      color: #fff; }
  .pro-pagination-style ul li a.active {
    background-color: darkturquoise;
    color: #fff;
    box-shadow: none; }
    .pro-pagination-style ul li a.active:hover {
      background-color: #333; }
  .pro-pagination-style ul li a.prev,
  .pro-pagination-style ul li a.next {
    background-color: #f6f6f6;
    color: darkturquoise;
    font-size: 17px;
    box-shadow: none; }
    .pro-pagination-style ul li a.prev:hover,
    .pro-pagination-style ul li a.next:hover {
      background-color: darkturquoise;
      color: #fff; }
  .pro-pagination-style ul li.page-item.active .page-link {
    background-color: darkturquoise;
    border-color: darkturquoise;
    box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important; }
    .pro-pagination-style ul li.page-item.active .page-link:focus {
      box-shadow: none; }
  .pro-pagination-style ul li.page-item .page-link {
    box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important; }
    .pro-pagination-style ul li.page-item .page-link:focus {
      box-shadow: none; }

@media only screen and (max-width: 767px) {
  .shop-area.pb-100 {
    padding-bottom: 45px; } }

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .list2-col-style .shop-list-wrap .shop-list-content h3 {
    font-size: 20px;
    line-height: 20px; }
  .list2-col-style .shop-list-wrap .shop-list-content .product-list-price {
    margin: 13px 0 10px; }
  .list2-col-style .shop-list-wrap .shop-list-content p {
    margin: 6px 0 20px; }
  .list2-col-style .shop-list-wrap .shop-list-content .shop-list-btn a {
    padding: 10px 24px 11px;
    font-size: 13px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .list2-col-style .shop-list-wrap .shop-list-content h3 {
    font-size: 20px;
    line-height: 20px; }
  .list2-col-style .shop-list-wrap .shop-list-content .product-list-price {
    margin: 13px 0 10px; }
  .list2-col-style .shop-list-wrap .shop-list-content p {
    margin: 6px 0 20px; }
  .list2-col-style .shop-list-wrap .shop-list-content .shop-list-btn a {
    padding: 10px 20px 11px;
    font-size: 13px; } }

.grid-sizer {
  width: 25%; }

.shop-bottom-area .col-xl-4 {
  transition: 0.5s; }

.shop-bottom-area .grid .shop-list-wrap {
  display: none; }

.shop-bottom-area .grid.two-column .col-xl-4 {
  flex: 1 0 50%;
  max-width: 50%;
  transition: 0.5s; }

.shop-bottom-area .list .product-wrap {
  display: none; }

.shop-bottom-area .list.two-column > .col-xl-4 {
  flex: 1 0 50%;
  max-width: 50%;
  transition: 0.5s; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shop-bottom-area .list.two-column > .col-xl-4 {
      flex: 1 0 100%;
      max-width: 100%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop-bottom-area .list.two-column > .col-xl-4 {
      flex: 1 0 100%;
      max-width: 100%; } }
  @media only screen and (max-width: 767px) {
    .shop-bottom-area .list.two-column > .col-xl-4 {
      flex: 1 0 100%;
      max-width: 100%; } }

.shop-bottom-area .list > .col-xl-4 {
  flex: 1 0 100%;
  max-width: 100%;
  transition: 0.5s; }

.shop-list-wishlist button,
.shop-list-compare button {
  background: none;
  border: none;
  font-size: 20px;
  padding: 0; }
  .shop-list-wishlist button:hover, .shop-list-wishlist button.active,
  .shop-list-compare button:hover,
  .shop-list-compare button.active {
    color: darkturquoise; }

.shop-area .container-fluid, .shop-area .container-sm, .shop-area .container-md, .shop-area .container-lg, .shop-area .container-xl {
  padding: 0 70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .shop-area .container-fluid, .shop-area .container-sm, .shop-area .container-md, .shop-area .container-lg, .shop-area .container-xl {
      padding: 0 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shop-area .container-fluid, .shop-area .container-sm, .shop-area .container-md, .shop-area .container-lg, .shop-area .container-xl {
      padding: 0 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop-area .container-fluid, .shop-area .container-sm, .shop-area .container-md, .shop-area .container-lg, .shop-area .container-xl {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .shop-area .container-fluid, .shop-area .container-sm, .shop-area .container-md, .shop-area .container-lg, .shop-area .container-xl {
      padding: 0 15px; } }

/*----- 19. Product details page  ---*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-img.mr-20 {
    margin-right: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img.mr-20 {
    margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .product-details-img.mr-20 {
    margin-right: 0; } }

.product-details-img .large-img-style {
  position: relative; }
  .product-details-img .large-img-style img {
    width: 100%; }
  .product-details-img .large-img-style span {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 13px;
    color: #fff;
    display: inline-block;
    line-height: 1;
    padding: 3px 11px;
    border-radius: 3px;
    font-weight: 500;
    background-color: #fa6bff; }
  .product-details-img .large-img-style .img-popup-wrap {
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 30px;
    color: #000000; }
    .product-details-img .large-img-style .img-popup-wrap:hover {
      color: #a749ff; }

.product-details-img .shop-details-tab {
  display: flex;
  justify-content: center;
  margin-top: -72px;
  z-index: 9;
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .product-details-img .shop-details-tab {
      margin-top: -50px; } }
  @media only screen and (max-width: 767px) {
    .product-details-img .shop-details-tab {
      margin-top: -45px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .product-details-img .shop-details-tab {
      margin-top: -60px; } }
  .product-details-img .shop-details-tab a.shop-details-overly {
    position: relative; }
    .product-details-img .shop-details-tab a.shop-details-overly img {
      width: 144px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .product-details-img .shop-details-tab a.shop-details-overly img {
          width: 90px; } }
      @media only screen and (max-width: 767px) {
        .product-details-img .shop-details-tab a.shop-details-overly img {
          width: 85px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .product-details-img .shop-details-tab a.shop-details-overly img {
          width: 110px; } }
    .product-details-img .shop-details-tab a.shop-details-overly.active:before {
      opacity: 0.6;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      background-color: #fff;
      content: ""; }

.description-review-topbar {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d7d7d7; }
  .description-review-topbar .nav-item a {
    background-color: transparent;
    border-bottom: 3px solid transparent;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    margin: 0 15px 0;
    padding: 0px 0px 13px;
    color: #666; }
    .description-review-topbar .nav-item a.active {
      color: #000000; }
    @media only screen and (max-width: 767px) {
      .description-review-topbar .nav-item a {
        margin: 0 4px 15px;
        font-size: 15px;
        padding: 0px 0px 8px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .description-review-topbar .nav-item a {
        margin: 0 8px 0px 0;
        font-size: 18px;
        padding: 0px 5px 0px; } }
    .description-review-topbar .nav-item a.active {
      border-bottom: 3px solid #000000;
      background-color: transparent; }
    .description-review-topbar .nav-item a.nav-link {
      border-radius: 0; }

.description-review-bottom {
  padding: 33px 0 0; }
  .description-review-bottom .product-description-wrapper p {
    margin: 0 0 8px;
    font-size: 15px;
    line-height: 28px;
    color: #333;
    width: 84%; }
    @media only screen and (max-width: 767px) {
      .description-review-bottom .product-description-wrapper p {
        width: 100%; } }
    .description-review-bottom .product-description-wrapper p:last-child {
      margin: 0 0 0px; }
  .description-review-bottom .product-anotherinfo-wrapper ul li {
    color: #333;
    font-size: 14px;
    list-style: outside none none;
    margin: 0 0 13px; }
    .description-review-bottom .product-anotherinfo-wrapper ul li span {
      color: #000;
      display: inline-block;
      font-weight: 500;
      margin: 0 26px 0 0;
      min-width: 85px; }

.single-review {
  display: flex;
  margin: 0 0 50px; }
  @media only screen and (max-width: 767px) {
    .single-review {
      display: block; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-review {
      display: flex; } }
  .single-review .review-img {
    flex: 0 0 90px;
    margin: 0 15px 0 0; }
    @media only screen and (max-width: 767px) {
      .single-review .review-img {
        margin: 0 0px 20px 0; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-review .review-img {
        margin: 0 15px 0px 0; } }
  .single-review .review-top-wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
    align-items: center; }
    .single-review .review-top-wrap .review-left {
      display: flex; }
      .single-review .review-top-wrap .review-left .review-name {
        margin-right: 25px; }
        .single-review .review-top-wrap .review-left .review-name h4 {
          font-size: 16px;
          margin: 0;
          line-height: 1;
          font-weight: 500; }
      .single-review .review-top-wrap .review-left .review-rating {
        line-height: 1; }
        .single-review .review-top-wrap .review-left .review-rating i {
          font-size: 12px;
          margin-right: 1px;
          color: #ffa900; }
      .single-review .review-top-wrap .review-left > button {
        background: none;
        border: none; }
        .single-review .review-top-wrap .review-left > button:hover {
          color: #a749ff; }
    .single-review .review-top-wrap .review-btn a {
      font-weight: 500;
      line-height: 1; }
  .single-review .review-bottom p {
    margin: 0;
    width: 93%; }

.single-review.child-review {
  margin-left: 70px;
  margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .single-review.child-review {
      margin-left: 0px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ratting-form-wrapper.pl-50 {
    padding-left: 0;
    margin-top: 50px; } }

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper.pl-50 {
    padding-left: 0;
    margin-top: 50px; } }

.ratting-form-wrapper h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1; }

.ratting-form-wrapper .ratting-form form .star-box {
  display: flex;
  margin: 6px 0 20px; }
  .ratting-form-wrapper .ratting-form form .star-box span {
    margin: 0 15px 0 0; }
  .ratting-form-wrapper .ratting-form form .star-box .ratting-star {
    font-size: 12px;
    color: #ffa900;
    margin: 2px 0 0; }

.ratting-form-wrapper .ratting-form form .rating-form-style input,
.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  padding: 2px 10px 2px 20px;
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #333; }

.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  height: 180px;
  padding: 20px 10px 2px 20px;
  margin-bottom: 20px; }

.ratting-form-wrapper .ratting-form form .form-submit input {
  width: auto;
  padding: 12px 50px;
  font-weight: 500;
  text-transform: uppercase;
  height: auto;
  background-color: #a749ff;
  color: #fff;
  border: 1px solid #a749ff; }
  .ratting-form-wrapper .ratting-form form .form-submit input:hover {
    background-color: #333;
    border: 1px solid #333; }

/* product details 2 */
.product-dec-slider-2 {
  display: inline-block;
  float: left;
  padding: 0;
  width: 20%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .product-dec-slider-2 {
      width: 19%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .product-dec-slider-2 {
      margin-bottom: 40px; } }
  @media only screen and (max-width: 767px) {
    .product-dec-slider-2 {
      margin-bottom: 30px;
      width: 30%; } }
  .product-dec-slider-2 .product-dec-icon {
    color: #666;
    cursor: pointer;
    display: inline-block;
    font-size: 25px;
    left: 50%;
    line-height: 1;
    padding: 0 10px;
    position: absolute;
    transform: translateX(-50%);
    z-index: 99;
    opacity: 0;
    visibility: hidden; }
    .product-dec-slider-2 .product-dec-icon.product-dec-prev {
      top: -25px; }
    .product-dec-slider-2 .product-dec-icon.product-dec-next {
      bottom: -14px; }
  .product-dec-slider-2.slick-vertical .slick-slide {
    display: block;
    height: auto;
    margin: 2px 0 10px;
    text-align: center; }
    .product-dec-slider-2.slick-vertical .slick-slide img {
      display: inline-block;
      width: 100%; }
  .product-dec-slider-2:hover .product-dec-icon {
    opacity: 1;
    visibility: visible; }

.zoompro-wrap.zoompro-2 {
  display: inline-block;
  position: relative;
  width: 80%;
  float: left; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .zoompro-wrap.zoompro-2 {
      width: 79%; } }
  @media only screen and (max-width: 767px) {
    .zoompro-wrap.zoompro-2 {
      margin-bottom: 30px;
      width: 70%; } }

.zoompro-wrap .zoompro-span {
  position: relative; }
  .zoompro-wrap .zoompro-span span {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 13px;
    color: #fff;
    display: inline-block;
    line-height: 1;
    padding: 3px 11px;
    border-radius: 3px;
    font-weight: 500;
    background-color: #fa6bff;
    z-index: 99; }
  .zoompro-wrap .zoompro-span .product-video {
    text-align: center;
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 99; }
    .zoompro-wrap .zoompro-span .product-video a {
      background-color: #fa6bff;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      padding: 5px 15px 5px; }
      @media only screen and (max-width: 767px) {
        .zoompro-wrap .zoompro-span .product-video a {
          padding: 3px 10px 3px; } }
  .zoompro-wrap .zoompro-span img {
    width: 100%; }

.dec-img-wrap {
  position: relative; }
  .dec-img-wrap img {
    width: 100%; }
  .dec-img-wrap span {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 13px;
    color: #fff;
    display: inline-block;
    line-height: 1;
    padding: 3px 11px;
    border-radius: 3px;
    font-weight: 500;
    background-color: #fa6bff;
    z-index: 99; }

@media only screen and (max-width: 767px) {
  .sidebar-active .product-details-content {
    margin-top: 0; } }

.sidebar-active.col-lg-6 {
  padding-left: 15px;
  padding-right: 15px; }
  .sidebar-active.col-lg-6.is-affixed {
    padding-left: 0px;
    padding-right: 0px; }
    .sidebar-active.col-lg-6.is-affixed .inner-wrapper-sticky {
      padding-left: 15px;
      padding-right: 15px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-gallery.mr-20 {
    margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .product-details-gallery.mr-20 {
    margin-right: 0; } }

/* affiliate */
.pro-details-affiliate {
  margin: 30px 0; }
  .pro-details-affiliate a {
    color: #fff;
    display: inline-block;
    font-weight: 500;
    background-color: #000;
    line-height: 1;
    padding: 13px 30px 16px;
    z-index: 9;
    border: 1px solid transparent; }

/* product-details-slider */
.product-details-slider-active.nav-style-1.owl-carousel .owl-nav div {
  color: #333;
  font-size: 20px;
  left: 20px; }
  .product-details-slider-active.nav-style-1.owl-carousel .owl-nav div:hover {
    color: #a749ff; }
  .product-details-slider-active.nav-style-1.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: 20px; }

.product-details-slider-active:hover.nav-style-1.owl-carousel > .owl-nav div {
  opacity: 1;
  visibility: visible; }

.product-large-image-wrapper {
  position: relative; }
  .product-large-image-wrapper .product-img-badges {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 3; }
    .product-large-image-wrapper .product-img-badges span {
      font-size: 13px;
      color: #fff;
      display: block;
      line-height: 1;
      padding: 3px 11px;
      border-radius: 3px;
      font-weight: 500;
      margin-bottom: 10px; }
      .product-large-image-wrapper .product-img-badges span.pink {
        background-color: #fa6bff; }
      .product-large-image-wrapper .product-img-badges span.purple {
        background-color: #a749ff; }
  .product-large-image-wrapper .react_lightgallery_item {
    position: absolute;
    top: 20px;
    right: 20px; }
    .product-large-image-wrapper .react_lightgallery_item button {
      background: none;
      border: none;
      font-size: 30px;
      padding: 0; }
  .product-large-image-wrapper--slider .ht-swiper-button-nav {
    background: none;
    border: none;
    font-size: 50px;
    padding: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    color: #333; }
  .product-large-image-wrapper--slider:hover .ht-swiper-button-nav {
    visibility: visible;
    opacity: 1; }
  .product-large-image-wrapper .single-image img {
    width: 100%; }

.shop-area .sticky {
  top: 70px !important; }

.product-small-image-wrapper .ht-swiper-button-nav {
  background: none;
  border: none;
  visibility: hidden;
  opacity: 0;
  color: #333;
  size: 0vh; }
  .product-small-image-wrapper .ht-swiper-button-nav i {
    font-size: 40px; }

.product-small-image-wrapper .single-image {
  cursor: pointer; }
  .product-small-image-wrapper .single-image img {
    width: 100%; }

.product-small-image-wrapper:hover .ht-swiper-button-nav {
  visibility: visible;
  opacity: 1; }

.product-small-image-wrapper .single-image {
  height: 100%; }
  .product-small-image-wrapper .single-image img {
    height: 100%;
    object-fit: cover;
    object-position: center; }

.product-small-image-wrapper .swiper-container-vertical {
  height: 100%; }

.product-small-image-wrapper--side-thumb {
  height: auto;
  overflow: hidden; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .product-small-image-wrapper--side-thumb {
      height: auto;
      margin-top: 15px; } }

/*----- 20. Cart page  -----*/
@media only screen and (max-width: 767px) {
  .cart-main-area.pb-100 {
    padding-bottom: 60px; } }

h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px; }

.cart-table-content table {
  border: 1px solid #ebebeb; }
  .cart-table-content table thead > tr {
    background-color: #f9f9f9;
    border: 1px solid #ebebeb; }
    .cart-table-content table thead > tr th {
      border-top: medium none;
      color: #333;
      font-size: 14px;
      font-weight: 500;
      padding: 21px 45px 22px;
      text-align: center;
      text-transform: uppercase;
      vertical-align: middle;
      white-space: nowrap; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .cart-table-content table thead > tr th {
          padding: 21px 35px 22px; } }
      @media only screen and (max-width: 767px) {
        .cart-table-content table thead > tr th {
          padding: 21px 20px 22px; } }
  .cart-table-content table tbody > tr {
    border-bottom: 1px solid #ebebeb; }
    .cart-table-content table tbody > tr td.product-thumbnail {
      width: 150px; }
    .cart-table-content table tbody > tr td.product-name {
      width: 435px;
      text-align: left; }
      .cart-table-content table tbody > tr td.product-name a {
        color: #333;
        font-size: 15px;
        font-weight: 500; }
        .cart-table-content table tbody > tr td.product-name a:hover {
          color: #a749ff; }
    .cart-table-content table tbody > tr td.product-price-cart {
      width: 435px; }
      .cart-table-content table tbody > tr td.product-price-cart span {
        font-weight: 500;
        color: #333; }
        .cart-table-content table tbody > tr td.product-price-cart span.old {
          text-decoration: line-through;
          margin-right: 10px;
          color: #8e8e8e; }
    .cart-table-content table tbody > tr td.product-subtotal {
      font-weight: 500;
      color: #333; }
    .cart-table-content table tbody > tr td.product-quantity {
      width: 435px; }
      .cart-table-content table tbody > tr td.product-quantity .cart-plus-minus {
        display: inline-block;
        height: 40px;
        padding: 0;
        position: relative;
        width: 110px; }
        .cart-table-content table tbody > tr td.product-quantity .cart-plus-minus .qtybutton {
          color: #333;
          cursor: pointer;
          float: inherit;
          background: none;
          border: none;
          font-size: 16px;
          margin: 0;
          position: absolute;
          transition: all 0.3s ease 0s;
          width: 20px;
          text-align: center; }
        .cart-table-content table tbody > tr td.product-quantity .cart-plus-minus .dec.qtybutton {
          border-right: 1px solid #e5e5e5;
          height: 40px;
          left: 0;
          top: 0; }
        .cart-table-content table tbody > tr td.product-quantity .cart-plus-minus .inc.qtybutton {
          border-left: 1px solid #e5e5e5;
          height: 40px;
          right: 0;
          top: 0; }
        .cart-table-content table tbody > tr td.product-quantity .cart-plus-minus input.cart-plus-minus-box {
          color: #333;
          float: left;
          font-size: 14px;
          height: 40px;
          margin: 0;
          width: 110px;
          background: transparent none repeat scroll 0 0;
          border: 1px solid #e1e1e1;
          padding: 0;
          text-align: center; }
    .cart-table-content table tbody > tr td.product-remove {
      width: 100px; }
      .cart-table-content table tbody > tr td.product-remove a,
      .cart-table-content table tbody > tr td.product-remove button {
        color: #666;
        background: none;
        border: none;
        font-size: 17px;
        margin: 0 13px; }
        .cart-table-content table tbody > tr td.product-remove a:hover,
        .cart-table-content table tbody > tr td.product-remove button:hover {
          color: #a749ff; }
    .cart-table-content table tbody > tr td.product-wishlist-cart > a,
    .cart-table-content table tbody > tr td.product-wishlist-cart > button {
      background: none;
      border: none;
      background-color: #a749ff;
      border-radius: 50px;
      color: #fff;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.2;
      padding: 10px 15px;
      text-transform: uppercase;
      display: block;
      margin: 0 auto; }
      .cart-table-content table tbody > tr td.product-wishlist-cart > a:hover, .cart-table-content table tbody > tr td.product-wishlist-cart > a.active,
      .cart-table-content table tbody > tr td.product-wishlist-cart > button:hover,
      .cart-table-content table tbody > tr td.product-wishlist-cart > button.active {
        background-color: #333; }
      .cart-table-content table tbody > tr td.product-wishlist-cart > a:disabled,
      .cart-table-content table tbody > tr td.product-wishlist-cart > button:disabled {
        cursor: not-allowed; }
    .cart-table-content table tbody > tr td {
      color: #333;
      font-size: 15px;
      padding: 30px 0 30px 30px;
      text-align: center; }

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 55px; }
  @media only screen and (max-width: 767px) {
    .cart-shiping-update-wrapper {
      display: block;
      padding: 30px 0px 15px; } }
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    background-color: #f2f2f2;
    border-radius: 100px;
    border: 2px solid darkturquoise;
    color: #363f4d;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 18px 63px 17px;
    text-transform: uppercase; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .cart-shiping-update-wrapper .cart-shiping-update > a,
      .cart-shiping-update-wrapper .cart-clear > button,
      .cart-shiping-update-wrapper .cart-clear > a {
        padding: 18px 25px 17px;
        font-size: 13px; } }
    @media only screen and (max-width: 767px) {
      .cart-shiping-update-wrapper .cart-shiping-update > a,
      .cart-shiping-update-wrapper .cart-clear > button,
      .cart-shiping-update-wrapper .cart-clear > a {
        padding: 18px 40px 17px;
        margin: 0 0 15px; } }
    .cart-shiping-update-wrapper .cart-shiping-update > a:hover,
    .cart-shiping-update-wrapper .cart-clear > button:hover,
    .cart-shiping-update-wrapper .cart-clear > a:hover {
      background-color: darkturquoise;
      color: #fff; }
  .cart-shiping-update-wrapper .cart-clear > button {
    border: 2px solid darkturquoise;
    cursor: pointer;
    margin-right: 27px;
    transition: all 0.3s ease 0s; }
    .cart-shiping-update-wrapper .cart-clear > button:last-child {
      margin-right: 0; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .cart-shiping-update-wrapper .cart-clear > button {
        margin-right: 15px; } }

.cart-tax,
.discount-code-wrapper {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 45px 30px 50px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cart-tax,
    .discount-code-wrapper {
      padding: 45px 18px 50px; } }
  @media only screen and (max-width: 767px) {
    .cart-tax,
    .discount-code-wrapper {
      padding: 45px 18px 50px; } }
  .cart-tax .title-wrap,
  .discount-code-wrapper .title-wrap {
    position: relative; }
    .cart-tax .title-wrap::before,
    .discount-code-wrapper .title-wrap::before {
      background-color: #e3e1e1;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 10px;
      transition: all 0.4s ease 0s;
      width: 100%;
      z-index: 1; }
    .cart-tax .title-wrap h4.cart-bottom-title,
    .discount-code-wrapper .title-wrap h4.cart-bottom-title {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      padding-right: 18px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .cart-tax .title-wrap h4.cart-bottom-title,
        .discount-code-wrapper .title-wrap h4.cart-bottom-title {
          font-size: 16px; } }
    .cart-tax .title-wrap .section-bg-gray,
    .discount-code-wrapper .title-wrap .section-bg-gray {
      background-color: #f8f9f9;
      position: relative;
      z-index: 99; }
  .cart-tax .tax-wrapper,
  .discount-code-wrapper .tax-wrapper {
    margin-top: 22px; }
    .cart-tax .tax-wrapper p,
    .discount-code-wrapper .tax-wrapper p {
      margin: 0; }
    .cart-tax .tax-wrapper .tax-select-wrapper,
    .discount-code-wrapper .tax-wrapper .tax-select-wrapper {
      margin: 5px 0 0; }
      .cart-tax .tax-wrapper .tax-select-wrapper .tax-select,
      .discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select {
        margin: 0 0 26px; }
        .cart-tax .tax-wrapper .tax-select-wrapper .tax-select label,
        .discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select label {
          color: #242424;
          font-size: 14px;
          margin: 0 0 5px; }
        .cart-tax .tax-wrapper .tax-select-wrapper .tax-select select,
        .discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select select {
          -moz-appearance: none;
          -webkit-appearance: none;
          border: 1px solid #ebebeb;
          box-shadow: none;
          color: #242424;
          font-size: 12px;
          height: 40px;
          padding: 0 50px 0 15px;
          width: 100%;
          cursor: pointer; }
        .cart-tax .tax-wrapper .tax-select-wrapper .tax-select input,
        .discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select input {
          background: #fff none repeat scroll 0 0;
          border: 1px solid #ebebeb;
          height: 40px; }
      .cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2,
      .discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2 {
        background-color: #a749ff;
        border: medium none;
        border-radius: 50px;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        padding: 13px 42px 12px;
        text-transform: uppercase;
        transition: all 0.3s ease 0s; }
        .cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover,
        .discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover {
          background-color: #333; }
  .cart-tax .discount-code,
  .discount-code-wrapper .discount-code {
    margin: 21px 0 0; }
    .cart-tax .discount-code p,
    .discount-code-wrapper .discount-code p {
      margin: 0 0 15px; }
    .cart-tax .discount-code form input,
    .discount-code-wrapper .discount-code form input {
      background: #fff;
      border: 1px solid #ebebeb;
      height: 40px;
      margin-bottom: 30px;
      padding-left: 10px; }
    .cart-tax .discount-code form button.cart-btn-2,
    .discount-code-wrapper .discount-code form button.cart-btn-2 {
      background-color: #a749ff;
      border: medium none;
      border-radius: 50px;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      padding: 13px 42px 12px;
      text-transform: uppercase;
      transition: all 0.3s ease 0s; }
      .cart-tax .discount-code form button.cart-btn-2:hover,
      .discount-code-wrapper .discount-code form button.cart-btn-2:hover {
        background-color: #333; }

.grand-totall {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 45px 30px 50px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .grand-totall {
      padding: 45px 18px 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .grand-totall {
      margin-top: 30px; } }
  .grand-totall .title-wrap {
    position: relative; }
    .grand-totall .title-wrap::before {
      background-color: #e3e1e1;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 10px;
      transition: all 0.4s ease 0s;
      width: 100%;
      z-index: 1; }
    .grand-totall .title-wrap h4.cart-bottom-title {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      padding-right: 18px; }
    .grand-totall .title-wrap .section-bg-gary-cart {
      background-color: #f9f9f9;
      position: relative;
      z-index: 9; }
  .grand-totall h5 {
    font-size: 14px;
    margin: 36px 0 27px; }
    .grand-totall h5 span {
      float: right;
      font-size: 18px;
      font-weight: 500; }
  .grand-totall .total-shipping {
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    margin: 0 0 27px;
    padding: 28px 0; }
    .grand-totall .total-shipping h5 {
      font-size: 14px;
      margin: 0; }
    .grand-totall .total-shipping ul {
      padding: 19px 0 0 0px; }
      .grand-totall .total-shipping ul li {
        color: #242424;
        list-style: outside none none;
        margin: 0 0 6px; }
        .grand-totall .total-shipping ul li:last-child {
          margin: 0 0 0px; }
        .grand-totall .total-shipping ul li input {
          background: #e9e9e9 none repeat scroll 0 0;
          border: 1px solid #d7d7d7;
          border-radius: 5px !important;
          color: #626262;
          cursor: pointer;
          height: 13px;
          margin-right: 10px;
          padding: 0;
          position: relative;
          top: 2px;
          width: 13px; }
        .grand-totall .total-shipping ul li span {
          float: right; }
  .grand-totall h4.grand-totall-title {
    color: black;
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 25px; }
    .grand-totall h4.grand-totall-title span {
      float: right; }
  .grand-totall a {
    background-color: darkturquoise;
    border-radius: 50px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 18px 10px 17px;
    text-align: center;
    text-transform: uppercase; }
    .grand-totall a:hover {
      background-color: #333; }

@media only screen and (max-width: 767px) {
  .discount-code-wrapper {
    margin: 30px 0; } }

.cart-item-variation {
  margin-top: 10px; }
  .cart-item-variation span {
    display: block; }

/*-------- Compare style ---------*/
.compare-table .table tbody tr th.title-column {
  min-width: 292px;
  font-size: 15px;
  color: #262626;
  margin: 0;
  background-color: #e6e6e6;
  font-weight: 500;
  padding: 10px 30px;
  border: none; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .compare-table .table tbody tr th.title-column {
      min-width: 200px; } }
  @media only screen and (max-width: 767px) {
    .compare-table .table tbody tr th.title-column {
      min-width: 150px;
      padding: 10px 20px; } }

.compare-table .table tbody tr:first-child th {
  padding-top: 53px; }

.compare-table .table tbody tr:last-child th {
  padding-bottom: 55px; }

.compare-table .table tbody tr td {
  text-align: center;
  padding: 17px 15px;
  border-color: #e7e7e7; }
  .compare-table .table tbody tr td.product-image-title {
    padding: 27px 10px 35px; }
    @media only screen and (max-width: 767px) {
      .compare-table .table tbody tr td.product-image-title {
        min-width: 220px; } }
    .compare-table .table tbody tr td.product-image-title .compare-remove a {
      font-size: 13px; }
      .compare-table .table tbody tr td.product-image-title .compare-remove a i {
        padding-right: 5px; }
      .compare-table .table tbody tr td.product-image-title .compare-remove a:hover {
        color: #a749ff; }
    .compare-table .table tbody tr td.product-image-title .compare-remove button {
      background: none;
      border: none;
      padding: 0; }
      .compare-table .table tbody tr td.product-image-title .compare-remove button i {
        font-size: 25px; }
      .compare-table .table tbody tr td.product-image-title .compare-remove button:hover {
        color: red; }
    .compare-table .table tbody tr td.product-image-title .image {
      clear: both;
      width: 100%;
      margin: 14px 0 7px;
      display: block; }
      .compare-table .table tbody tr td.product-image-title .image img {
        width: auto; }
    .compare-table .table tbody tr td.product-image-title .category {
      float: left;
      clear: both;
      color: #a749ff;
      text-transform: capitalize;
      letter-spacing: 0.5px; }
    .compare-table .table tbody tr td.product-image-title .product-title {
      display: block;
      overflow: hidden; }
      .compare-table .table tbody tr td.product-image-title .product-title > a {
        float: left;
        clear: both;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        margin-top: 5px;
        display: block;
        width: 100%; }
        .compare-table .table tbody tr td.product-image-title .product-title > a:hover {
          color: #a749ff; }
    .compare-table .table tbody tr td.product-image-title .compare-btn {
      margin: 14px 0 0; }
      .compare-table .table tbody tr td.product-image-title .compare-btn a,
      .compare-table .table tbody tr td.product-image-title .compare-btn button {
        background-color: #a749ff;
        padding: 10px 15px;
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        border: none; }
        .compare-table .table tbody tr td.product-image-title .compare-btn a:hover, .compare-table .table tbody tr td.product-image-title .compare-btn a.active,
        .compare-table .table tbody tr td.product-image-title .compare-btn button:hover,
        .compare-table .table tbody tr td.product-image-title .compare-btn button.active {
          background-color: #363f4d; }
        .compare-table .table tbody tr td.product-image-title .compare-btn a:disabled,
        .compare-table .table tbody tr td.product-image-title .compare-btn button:disabled {
          cursor: not-allowed; }
        .compare-table .table tbody tr td.product-image-title .compare-btn a.disabled,
        .compare-table .table tbody tr td.product-image-title .compare-btn button.disabled {
          opacity: 0.3; }
          .compare-table .table tbody tr td.product-image-title .compare-btn a.disabled:hover,
          .compare-table .table tbody tr td.product-image-title .compare-btn button.disabled:hover {
            background-color: #343538; }
  .compare-table .table tbody tr td.product-desc p {
    margin: 0;
    font-size: 13px;
    line-height: 1.88; }
  .compare-table .table tbody tr td.product-price {
    font-size: 14px;
    font-weight: 400;
    color: #343538; }
    .compare-table .table tbody tr td.product-price .amount.old {
      text-decoration: line-through;
      margin-right: 10px;
      color: #8e8e8e; }
  .compare-table .table tbody tr td.product-sku, .compare-table .table tbody tr td.product-stock, .compare-table .table tbody tr td.product-weight {
    font-size: 13px;
    font-weight: 400; }
  .compare-table .table tbody tr td.product-dimensions {
    font-size: 13px;
    font-weight: 400; }
  .compare-table .table tbody tr td.product-rating i {
    color: #343538;
    margin-right: 5px; }
    .compare-table .table tbody tr td.product-rating i.yellow {
      color: #ffa900; }

/*------ end Compare Page Wrapper -----*/
/*------- 21. Checkout page  -----------*/
.billing-info-wrap h3 {
  font-size: 20px;
  color: #000;
  margin: 0 0 20px;
  font-weight: 500; }

.billing-info-wrap .billing-info label,
.billing-info-wrap .billing-select label {
  color: #000;
  margin: 0 0 7px; }

.billing-info-wrap .billing-info input,
.billing-info-wrap .billing-select input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #333;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px; }

.billing-info-wrap .billing-info input.billing-address,
.billing-info-wrap .billing-select input.billing-address {
  margin-bottom: 10px; }

.billing-info-wrap .billing-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #e6e6e6;
  color: #333;
  font-size: 14px;
  height: 45px;
  padding: 2px 20px;
  cursor: pointer; }

.billing-info-wrap .checkout-account {
  align-items: center;
  display: flex; }
  .billing-info-wrap .checkout-account input {
    border: 1px solid #9fa0a2;
    display: inline-block;
    float: left;
    height: 10px;
    width: 10px; }
  .billing-info-wrap .checkout-account span {
    color: #333;
    font-weight: 400;
    margin: 0 0 0 12px; }

.billing-info-wrap .checkout-account-toggle input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #333;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px;
  margin: 0 0 20px; }

.billing-info-wrap .checkout-account-toggle button.checkout-btn {
  background-color: #a749ff;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 50px;
  z-index: 9; }
  .billing-info-wrap .checkout-account-toggle button.checkout-btn:hover {
    background-color: #333; }

.billing-info-wrap .additional-info-wrap h4 {
  font-size: 16px;
  font-weight: 500; }

.billing-info-wrap .additional-info-wrap .additional-info label {
  color: #333;
  font-size: 14px;
  margin: 0 0 7px; }

.billing-info-wrap .additional-info-wrap .additional-info textarea {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #333;
  font-size: 14px;
  height: 138px;
  padding: 17px 20px; }

.billing-info-wrap .different-address {
  display: none; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px; } }

@media only screen and (max-width: 767px) {
  .your-order-area {
    margin-top: 30px; } }

.your-order-area h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 20px;
  color: #000; }

.your-order-area .your-order-wrap {
  padding: 38px 45px 44px;
  background: #f6f6f6; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .your-order-area .your-order-wrap {
      padding: 30px 20px 36px; } }
  @media only screen and (max-width: 767px) {
    .your-order-area .your-order-wrap {
      padding: 30px 20px 36px; } }
  .your-order-area .your-order-wrap .your-order-product-info .your-order-top ul {
    display: flex;
    justify-content: space-between; }
    .your-order-area .your-order-wrap .your-order-product-info .your-order-top ul li {
      font-size: 16px;
      font-weight: 500;
      list-style: outside none none; }
  .your-order-area .your-order-wrap .your-order-product-info .your-order-middle {
    border-bottom: 1px solid #dee0e4;
    border-top: 1px solid #dee0e4;
    margin: 29px 0;
    padding: 19px 0 18px; }
    .your-order-area .your-order-wrap .your-order-product-info .your-order-middle ul li {
      display: flex;
      justify-content: space-between;
      margin: 0 0 10px; }
  .your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul {
    align-items: center;
    display: flex;
    justify-content: space-between; }
    .your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul li {
      font-size: 14px;
      font-weight: 400;
      list-style: none; }
    .your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul li.your-order-shipping {
      font-size: 16px;
      color: #212121;
      font-weight: 400; }
  .your-order-area .your-order-wrap .your-order-product-info .your-order-total {
    border-bottom: 1px solid #dee0e4;
    border-top: 1px solid #dee0e4;
    margin: 18px 0 33px;
    padding: 17px 0 19px; }
    .your-order-area .your-order-wrap .your-order-product-info .your-order-total ul {
      align-items: center;
      display: flex;
      justify-content: space-between; }
      .your-order-area .your-order-wrap .your-order-product-info .your-order-total ul li.order-total {
        font-weight: 500;
        color: #212121;
        font-size: 18px; }
      .your-order-area .your-order-wrap .your-order-product-info .your-order-total ul li {
        font-weight: 600;
        color: black;
        font-size: 16px;
        list-style: outside none none; }

.your-order-area .payment-accordion {
  margin: 0 0 16px; }
  .your-order-area .payment-accordion:last-child {
    margin: 0 0 0px; }
  .your-order-area .payment-accordion h4 {
    color: #212121;
    font-size: 16px;
    margin: 0; }
    .your-order-area .payment-accordion h4 a {
      color: #212121;
      position: relative;
      display: block; }
      .your-order-area .payment-accordion h4 a:hover {
        color: darkturquoise; }
  .your-order-area .payment-accordion .panel-body {
    padding: 5px 0 0 0px; }
    .your-order-area .payment-accordion .panel-body p {
      padding: 0 0 0 10px;
      font-size: 14px;
      color: #333; }

.your-order-area .place-order > a,
.your-order-area .place-order > button {
  background: none;
  border: none;
  background-color: darkturquoise;
  color: #fff;
  display: block;
  width: 100%;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  padding: 18px 20px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50px;
  z-index: 9; }
  .your-order-area .place-order > a:hover,
  .your-order-area .place-order > button:hover {
    background-color: #333; }

@media only screen and (max-width: 767px) {
  .checkout-area.pb-100 {
    padding-bottom: 60px; } }

.select-vendedor {
  color: #000 !important; }
  .select-vendedor :active {
    border-color: #000 !important; }
  .select-vendedor :hover {
    color: black !important; }

/*------ 22. contact Page  ------*/
.contact-map {
  height: 560px;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .contact-map {
      height: 400px; } }

.contact-info-wrap {
  background-color: #f3f3f3;
  padding: 120px 70px 116px 90px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-info-wrap {
      padding: 120px 20px 116px 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-info-wrap {
      padding: 120px 20px 116px 30px; } }
  @media only screen and (max-width: 767px) {
    .contact-info-wrap {
      padding: 50px 20px 45px 30px;
      margin-bottom: 30px;
      margin-top: 20px; } }
  .contact-info-wrap .single-contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }
    .contact-info-wrap .single-contact-info .contact-icon {
      margin-right: 20px; }
      .contact-info-wrap .single-contact-info .contact-icon i {
        font-size: 20px;
        color: #252525;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #252525;
        text-align: center;
        border-radius: 100%;
        transition: all 0.3s ease 0s; }
    .contact-info-wrap .single-contact-info .contact-info-dec p {
      line-height: 1;
      color: #404040;
      margin: 0 0 9px; }
      .contact-info-wrap .single-contact-info .contact-info-dec p a {
        color: #404040; }
        .contact-info-wrap .single-contact-info .contact-info-dec p a:hover {
          color: #a749ff; }
      .contact-info-wrap .single-contact-info .contact-info-dec p:last-child {
        margin: 0; }
    .contact-info-wrap .single-contact-info:hover .contact-icon i {
      background-color: #252525;
      color: #fff; }

.contact-social {
  margin-top: 58px; }
  .contact-social h3 {
    font-weight: 500;
    color: #4d4d4d;
    font-size: 24px;
    margin: 0 0 17px;
    line-height: 1; }
  .contact-social ul li {
    margin: 0 10px;
    display: inline-block; }
    .contact-social ul li a {
      font-size: 16px;
      color: #4d4d4d; }
      .contact-social ul li a:hover {
        color: #a749ff; }

.contact-form {
  background-color: #f3f3f3;
  padding: 50px 110px 50px 110px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-form {
      padding: 50px 50px 50px 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-form {
      padding: 50px 30px 50px 30px; } }
  @media only screen and (max-width: 767px) {
    .contact-form {
      padding: 50px 30px 50px 30px; } }
  .contact-form .contact-title h2 {
    font-size: 24px;
    font-weight: 500;
    color: #464646;
    line-height: 1;
    margin-bottom: 36px; }
  .contact-form .contact-form-style input,
  .contact-form .contact-form-style textarea {
    background: transparent;
    border: 1px solid #c1c1c1;
    height: 40px;
    padding: 2px 14px;
    margin-bottom: 30px;
    color: #000; }
  .contact-form .contact-form-style textarea {
    padding: 20px 14px;
    margin-bottom: 0px;
    height: 175px; }
  .contact-form .contact-form-style button {
    border: none;
    background-color: #404040;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
    padding: 15px 52px;
    margin-top: 38px; }
    .contact-form .contact-form-style button:hover {
      background-color: #a749ff; }
  .contact-form p {
    color: #333; }
    .contact-form p.success {
      margin-top: 10px; }

.contact-form-style .row div[class^="col-"] {
  padding-left: 15px;
  padding-right: 15px; }

@media only screen and (max-width: 767px) {
  .contact-area.pb-100 {
    padding-bottom: 60px; } }

.info {
  padding-bottom: 30px;
  margin-left: 20%;
  margin-right: 20%;
  text-align: center; }
  .info a {
    color: darkcyan; }
  .info a :hover {
    color: #4d4d4d; }

/*------ 23. Blog Details Page  -------*/
.blog-details-top .blog-details-img {
  position: relative; }
  .blog-details-top .blog-details-img img {
    width: 100%; }
  .blog-details-top .blog-details-img .video-icon {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%); }
    .blog-details-top .blog-details-img .video-icon a {
      background-color: #ff0000;
      border-radius: 50px;
      color: #fff;
      display: inline-block;
      font-size: 25px;
      height: 60px;
      line-height: 61px;
      width: 60px; }
      .blog-details-top .blog-details-img .video-icon a:hover {
        background-color: #a749ff; }

.blog-details-top .blog-details-content {
  margin-top: 30px; }
  .blog-details-top .blog-details-content .blog-meta-2 {
    display: flex; }
    .blog-details-top .blog-details-content .blog-meta-2 ul li {
      color: #333;
      display: inline-block;
      font-size: 14px;
      letter-spacing: 1px;
      list-style: outside none none;
      margin: 0 17px 0 0;
      position: relative;
      text-transform: uppercase;
      display: inline-block;
      line-height: 1; }
      .blog-details-top .blog-details-content .blog-meta-2 ul li::before {
        background-color: #555;
        content: "";
        height: 2px;
        position: absolute;
        right: -13px;
        top: 6px;
        transition: all 0.4s ease 0s;
        width: 5px; }
      .blog-details-top .blog-details-content .blog-meta-2 ul li:last-child::before {
        display: none; }
      .blog-details-top .blog-details-content .blog-meta-2 ul li a {
        color: #333; }
        .blog-details-top .blog-details-content .blog-meta-2 ul li a:hover {
          color: #a749ff; }
  .blog-details-top .blog-details-content h3 {
    font-size: 24px;
    font-weight: 500;
    margin: 8px 0 20px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .blog-details-top .blog-details-content h3 {
        font-size: 22px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .blog-details-top .blog-details-content h3 {
        font-size: 22px; } }
    @media only screen and (max-width: 767px) {
      .blog-details-top .blog-details-content h3 {
        font-size: 18px; } }
  .blog-details-top .blog-details-content p {
    margin: 0;
    line-height: 26px; }
  .blog-details-top .blog-details-content blockquote {
    color: #333;
    font-size: 18px;
    margin: 25px 0 26px;
    line-height: 34px;
    font-weight: 500;
    font-style: italic; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .blog-details-top .blog-details-content blockquote {
        font-size: 17px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .blog-details-top .blog-details-content blockquote {
        font-size: 17px; } }
    @media only screen and (max-width: 767px) {
      .blog-details-top .blog-details-content blockquote {
        font-size: 16px;
        line-height: 30px; } }

.dec-img-wrapper {
  margin-top: 50px; }
  @media only screen and (max-width: 767px) {
    .dec-img-wrapper {
      margin-top: 30px; } }
  .dec-img-wrapper .dec-img img {
    width: 100%; }
  @media only screen and (max-width: 767px) {
    .dec-img-wrapper .dec-img.mb-50 {
      margin-bottom: 30px; } }

.tag-share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 30px; }
  @media only screen and (max-width: 767px) {
    .tag-share {
      display: block;
      margin: 20px 0 25px; } }
  @media only screen and (max-width: 767px) {
    .tag-share .dec-tag {
      margin-bottom: 10px; } }
  .tag-share .dec-tag ul {
    display: flex; }
    .tag-share .dec-tag ul li {
      margin-right: 10px;
      position: relative; }
      .tag-share .dec-tag ul li a {
        text-transform: capitalize;
        font-size: 15px; }
  .tag-share .blog-share {
    display: flex;
    align-items: center; }
    .tag-share .blog-share span {
      font-size: 15px;
      text-transform: capitalize; }
    .tag-share .blog-share ul {
      display: flex; }
      .tag-share .blog-share ul li {
        margin-left: 10px; }
        .tag-share .blog-share ul li a {
          font-size: 16px; }

.next-previous-post {
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  padding: 18px 0 17px;
  display: flex;
  justify-content: space-between; }
  .next-previous-post a {
    font-size: 15px;
    text-transform: capitalize; }

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper.mt-55 {
    margin-top: 25px; } }

.blog-comment-wrapper .blog-dec-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase; }
  @media only screen and (max-width: 767px) {
    .blog-comment-wrapper .blog-dec-title {
      font-size: 18px; } }

.blog-comment-wrapper .single-comment-wrapper {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .blog-comment-wrapper .single-comment-wrapper {
      display: block; }
      .blog-comment-wrapper .single-comment-wrapper.mt-35 {
        margin-top: 20px; }
      .blog-comment-wrapper .single-comment-wrapper.mt-50 {
        margin-top: 30px; } }
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
    flex: 0 0 120px;
    margin-right: 28px; }
    @media only screen and (max-width: 767px) {
      .blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
        margin-right: 28px;
        margin-bottom: 20px; } }
    .blog-comment-wrapper .single-comment-wrapper .blog-comment-img img {
      width: 100%; }
      @media only screen and (max-width: 767px) {
        .blog-comment-wrapper .single-comment-wrapper .blog-comment-img img {
          width: auto; } }
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-content h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: 1; }
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-content span {
    display: block;
    font-size: 15px;
    margin: 6px 0 8px; }
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-content p {
    font-size: 14px;
    margin-bottom: 10px; }
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-content .blog-details-btn a {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize; }
  @media only screen and (max-width: 767px) {
    .blog-comment-wrapper .single-comment-wrapper.ml-120 {
      margin-left: 0; } }

@media only screen and (max-width: 767px) {
  .blog-reply-wrapper.mt-50 {
    margin-top: 30px; } }

.blog-reply-wrapper .blog-dec-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase; }
  @media only screen and (max-width: 767px) {
    .blog-reply-wrapper .blog-dec-title {
      font-size: 18px; } }

.blog-reply-wrapper .blog-form {
  margin-top: 35px; }
  @media only screen and (max-width: 767px) {
    .blog-reply-wrapper .blog-form {
      margin-top: 20px; } }
  .blog-reply-wrapper .blog-form .leave-form input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    box-shadow: none;
    color: #333;
    height: 45px;
    margin-bottom: 30px;
    padding-left: 20px; }
  .blog-reply-wrapper .blog-form .text-leave textarea {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    box-shadow: none;
    color: #333;
    height: 250px;
    padding-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px; }
  .blog-reply-wrapper .blog-form .text-leave input {
    background-color: #a749ff;
    border: medium none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    height: inherit;
    letter-spacing: 1px;
    margin-top: 10px;
    padding: 14px 30px 13px;
    width: inherit; }
    .blog-reply-wrapper .blog-form .text-leave input:hover {
      background-color: #333; }

.nav-style-4.owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible; }

/*------ 24. login register Page  --------*/
.login-register-wrapper .login-register-tab-list {
  display: flex;
  justify-content: center;
  margin-bottom: 40px; }
  .login-register-wrapper .login-register-tab-list .nav-item {
    position: relative; }
    .login-register-wrapper .login-register-tab-list .nav-item a {
      background: none;
      padding: 0; }
      .login-register-wrapper .login-register-tab-list .nav-item a h4 {
        font-size: 25px;
        font-weight: 700;
        margin: 0 20px;
        text-transform: capitalize;
        transition: all 0.3s ease 0s; }
      .login-register-wrapper .login-register-tab-list .nav-item a:last-child::before {
        display: none; }
    .login-register-wrapper .login-register-tab-list .nav-item a.active h4 {
      color: darkturquoise; }
    .login-register-wrapper .login-register-tab-list .nav-item:before {
      background-color: #454545;
      bottom: 5px;
      content: "";
      height: 18px;
      margin: 0 auto;
      position: absolute;
      right: -2px;
      transition: all 0.4s ease 0s;
      width: 1px; }
    .login-register-wrapper .login-register-tab-list .nav-item:last-child::before {
      display: none; }

.login-register-wrapper .login-form-container {
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 80px;
  text-align: left; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .login-register-wrapper .login-form-container {
      padding: 80px 50px; } }
  @media only screen and (max-width: 767px) {
    .login-register-wrapper .login-form-container {
      padding: 40px 15px; } }
  .login-register-wrapper .login-form-container .login-register-form form input {
    background-color: transparent;
    border: 1px solid #ebebeb;
    color: #333;
    font-size: 14px;
    height: 45px;
    margin-bottom: 30px;
    padding: 0 15px; }
  .login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"] {
    height: 15px;
    margin: 0;
    position: relative;
    top: 1px;
    width: 17px; }
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn {
    padding: 10px 0 19px; }
    .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
      color: #242424;
      font-size: 15px;
      font-weight: 400; }
      @media only screen and (max-width: 767px) {
        .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
          font-size: 14px; } }
    .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
      color: #242424;
      float: right;
      font-size: 15px;
      transition: all 0.3s ease 0s; }
      @media only screen and (max-width: 767px) {
        .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
          font-size: 14px; } }
      .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a:hover {
        color: darkturquoise; }
  .login-register-wrapper .login-form-container .login-register-form form .button-box button {
    background-color: #f2f2f2;
    border: medium none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 11px 30px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s; }
    .login-register-wrapper .login-form-container .login-register-form form .button-box button:hover {
      background-color: darkturquoise;
      color: #fff; }

@media only screen and (max-width: 767px) {
  .login-register-area.pb-100 {
    padding-bottom: 10px; } }

/*------ 25. My account Page  ------*/
.single-my-account {
  margin-bottom: 20px;
  border: 1px solid #ebebeb; }
  .single-my-account .panel-heading {
    padding: 0; }
    .single-my-account .panel-heading > button {
      background: none;
      border: none;
      width: 100%;
      text-align: left;
      padding: 0; }
  .single-my-account .card-body {
    border-bottom: 1px solid #ebebeb; }
  .single-my-account h3.panel-title {
    background-color: #f9f9f9;
    border-bottom: 1px solid #ebebeb;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
    color: #242424;
    display: block;
    padding: 16px 55px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .single-my-account h3.panel-title {
        line-height: 22px;
        font-size: 14px;
        padding: 16px 30px 16px 40px; } }
    .single-my-account h3.panel-title:hover {
      color: #a749ff; }
    .single-my-account h3.panel-title::before {
      color: #000;
      content: "\f107";
      display: inline-block;
      font-family: "FontAwesome";
      font-size: 16px;
      position: absolute;
      right: 10px;
      top: 19px; }
    .single-my-account h3.panel-title:hover::before {
      color: #a749ff; }
    .single-my-account h3.panel-title span {
      color: #242424;
      font-size: 15px;
      left: 20px;
      position: absolute;
      top: 16px; }
  .single-my-account .myaccount-info-wrapper {
    padding: 30px 20px;
    background-color: #fff; }
    .single-my-account .myaccount-info-wrapper .account-info-wrapper {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 28px;
      padding-bottom: 30px; }
      .single-my-account .myaccount-info-wrapper .account-info-wrapper h4 {
        font-size: 15px;
        margin: 0;
        text-transform: uppercase; }
      .single-my-account .myaccount-info-wrapper .account-info-wrapper h5 {
        font-size: 16px;
        letter-spacing: 0.2px;
        margin-top: 7px; }
    .single-my-account .myaccount-info-wrapper .billing-info {
      margin-bottom: 20px; }
      .single-my-account .myaccount-info-wrapper .billing-info label {
        color: #000;
        font-size: 14px;
        text-transform: capitalize; }
      .single-my-account .myaccount-info-wrapper .billing-info input {
        background: transparent none repeat scroll 0 0;
        border: 1px solid #ebebeb;
        color: #000;
        height: 40px;
        padding: 0 15px; }
    .single-my-account .myaccount-info-wrapper .billing-back-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 26px;
      align-items: center; }
      .single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a {
        color: #000;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase; }
        .single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a:hover {
          color: #a749ff; }
        .single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a i {
          font-size: 16px;
          color: #a749ff; }
      .single-my-account .myaccount-info-wrapper .billing-back-btn .billing-btn button {
        background-color: #f2f2f2;
        border: medium none;
        color: #000;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        padding: 16px 35px 17px;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s ease 0s; }
        .single-my-account .myaccount-info-wrapper .billing-back-btn .billing-btn button:hover {
          background: #a749ff;
          color: #fff; }
    .single-my-account .myaccount-info-wrapper .entries-wrapper {
      border: 1px solid #eaeaea;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .single-my-account .myaccount-info-wrapper .entries-wrapper {
          padding: 30px 10px; }
          .single-my-account .myaccount-info-wrapper .entries-wrapper::before {
            display: none; } }
      .single-my-account .myaccount-info-wrapper .entries-wrapper::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 1px;
        left: 50%;
        top: 0;
        background-color: #eaeaea; }
      .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info {
        padding: 30px 20px; }
        @media only screen and (max-width: 767px) {
          .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info {
            padding: 0 10px 30px; } }
        .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info p {
          color: #000;
          font-size: 15px;
          margin: 0;
          text-transform: capitalize; }
      .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a,
      .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete button {
        background: none;
        border: none;
        background-color: #000;
        color: #fff;
        display: inline-block;
        line-height: 1;
        margin: 0 2px;
        padding: 12px 15px;
        text-transform: uppercase;
        font-weight: 500; }
        .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a:hover,
        .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete button:hover {
          background-color: #a749ff; }
        .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a.edit,
        .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete button.edit {
          background-color: #df5c39; }
          .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a.edit:hover,
          .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete button.edit:hover {
            background-color: #a749ff; }

.error h1 {
  font-size: 17rem;
  font-weight: bold;
  letter-spacing: 1rem;
  line-height: 1.1;
  margin-bottom: 30px;
  color: #a749ff; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .error h1 {
      font-size: 15rem;
      margin-bottom: 4px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .error h1 {
      font-size: 12rem; } }
  @media only screen and (max-width: 767px) {
    .error h1 {
      font-size: 7rem;
      line-height: 88px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .error h1 {
      font-size: 9rem;
      line-height: 100px; } }

.error h2 {
  font-size: 25px;
  font-weight: 600; }

.error-btn {
  position: relative;
  transition: all 0.3s;
  color: #fff;
  font-size: 16px;
  min-width: 184px;
  min-height: 55px;
  line-height: 55px;
  margin-bottom: 6px;
  padding: 0 10px;
  border: none;
  border-radius: 0;
  background-color: #a749ff;
  display: inline-block; }
  .error-btn:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -6px;
    height: 3px;
    width: 100%;
    background-color: #a749ff;
    transition: all 0.3s; }
  .error-btn:hover {
    background-color: #111111;
    color: #fff; }
    .error-btn:hover:after {
      background-color: #111111; }

.searchform {
  position: relative; }
  .searchform input {
    border: 1px solid #eeeeee;
    height: 45px;
    width: 100%;
    padding-left: 15px;
    padding-right: 80px;
    background-color: transparent; }
  .searchform button {
    border: none;
    background-color: #a749ff;
    width: 70px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%; }
    .searchform button:hover {
      background-color: #000; }
  @media only screen and (max-width: 767px) {
    .searchform.mb-50 {
      margin-bottom: 20px; } }
